import { createAddGenericActivityFromElysiumUrl } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { useRouterLocale } from "src/utils/router/use-router-locale";
import { EnumActivityType } from "./generic-activity.api";

interface IRequest {
    activityTypeId: EnumActivityType;
    parameter?: string | null;
    referrer?: string | null;
}

export const useGenericActivityFromElysiumMutation = () => {
    const { language, country } = useRouterLocale();
    const { mutatePromise } = useMutation<void, void, IRequest>(createAddGenericActivityFromElysiumUrl(language, country));

    return mutatePromise;
};
