import { useEffect } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { EnumQueryErrorTypes } from "./error-enums";

/**
 * This hook fires on query errors, display an message error toast.
 */
export const useToastQueryErrors = () => {
    const router = useRouter();
    const toast = useWfToast();
    const { t } = useTranslation("common");

    useEffect(() => {
        const errorParam = String(router?.query?.error).toLowerCase();

        if (errorParam === EnumQueryErrorTypes.FacebookAccessDenied) {
            toast({ status: EnumToastStatus.Error, duration: 10000, title: t("social-login-cookie-accept-error") });
        }

        if (errorParam === EnumQueryErrorTypes.AppleAccessDenied) {
            toast({ status: EnumToastStatus.Error, duration: 10000, title: t("social-login-cookie-accept-error") });
        }
    }, [router?.query?.error, t, toast]);
};
