import { Checkbox, Box, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Stack, Skeleton } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useRegisterMutation } from "src/api/client/register.api";
import { Button } from "src/components/base/button";
import { ButtonLink } from "src/components/base/button";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { getGlobals } from "src/consts/globals";
import { useFormValidator } from "src/hooks/form-validator/use-form-validator";
import { useForm } from "src/hooks/use-form";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import { EMAIL_REGISTER_FORM_TEST_ID, OTHER_REGISTER_OPTIONS_TEST_ID } from "./email-register-form.test-ids";

interface IFormValues {
    email: string;
    password: string;
    agbs: boolean;
    newsletter: boolean;
    sendPromotion: boolean;
}

export interface IEmailRegisterFormProps {
    isLoaded: boolean;
    isSsoRegister: boolean;
    agbLabel?: string;
    agbHint?: string;
    newsletterLabel?: string;
    newsletterHint?: string;
}

export const EmailRegisterForm = ({ isLoaded, isSsoRegister, agbLabel, agbHint, newsletterLabel, newsletterHint }: IEmailRegisterFormProps) => {
    const { t } = useTranslation("common");
    const router = useRouter();

    const {
        prefilledRegisterEmail,
        showEmailRegisterForm,
        promotionData,
        showPromotionCheckbox,
        welcomePageNodeId,
        setShowEmailRegisterForm,
        setIsLoading,
        isNewsletterCheckboxEnabled,
    } = useAuthModalStore();

    const { register, handleSubmit, setError, formState, isFieldInvalid } = useForm<IFormValues>({
        mode: "onSubmit",
        defaultValues: { email: prefilledRegisterEmail, password: "", agbs: false, newsletter: isNewsletterCheckboxEnabled },
    });
    const validators = useFormValidator();
    const { isSubmitting, errors } = formState;

    const registerUser = useRegisterMutation({
        onSuccess: data => {
            window.location.href = data.returnUrl;
        },
        onError: data => {
            Object.entries(data).forEach(([fieldName, errorMessage]) => {
                setError(fieldName as keyof IFormValues, { message: errorMessage });
            });
            setIsLoading(false);
        },
    });

    if (!showEmailRegisterForm) {
        return null;
    }

    return (
        <form
            onSubmit={handleSubmit(async values => {
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }

                setIsLoading(true);
                const sendPromotion = promotionData !== null && (promotionData.hideCheckbox || values.sendPromotion);
                const welcomePageNodeIdFromQuery = typeof router.query.r === "string" ? parseInt(router.query.r) : null;
                registerUser({
                    email: values.email ?? prefilledRegisterEmail,
                    password: values.password,
                    agbs: values.agbs,
                    newsletter: values.newsletter,
                    sendPromotion,
                    promotionId: sendPromotion ? promotionData!.id : undefined,
                    welcomePageNodeId: welcomePageNodeIdFromQuery ?? welcomePageNodeId ?? getGlobals().welcomePageNodeId,
                    platform: (router.query.platform as string) ?? getGlobals().registrationPlatforms?.wikifolioWeb,
                });
            })}
            data-test-id={EMAIL_REGISTER_FORM_TEST_ID}
            noValidate
        >
            <Stack spacing={3}>
                <FormControl id="register-email" isInvalid={isFieldInvalid("email")}>
                    <FormLabel>{t("email")}</FormLabel>
                    <Input
                        placeholder={t("email")}
                        type="email"
                        disabled={isSsoRegister}
                        autoFocus
                        {...register("email", { ...validators.email() })}
                    />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
                {/* For third party register disable password field */}
                {!isSsoRegister && (
                    <FormControl id="register-password" isInvalid={isFieldInvalid("password")}>
                        <FormLabel>{t("password")}</FormLabel>
                        <Input placeholder={t("password")} type="password" {...register("password", { ...validators.password() })} />
                        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                    </FormControl>
                )}
                <FormControl isInvalid={isFieldInvalid("agbs")}>
                    <Checkbox
                        isDisabled={!isLoaded}
                        {...register("agbs", {
                            required: { value: true, message: t("accept-agb-s") },
                        })}
                    >
                        {isLoaded ? agbLabel : <Skeleton h="18px" w="200px" />}
                    </Checkbox>
                    <FormErrorMessage ml={4}>{errors.agbs?.message}</FormErrorMessage>
                    <FormHelperText ml={4}>
                        {isLoaded ? <SimpleRichText text={agbHint || ""} /> : <Skeleton isLoaded={isLoaded} h="14px" w="150px" />}
                    </FormHelperText>
                </FormControl>
                <FormControl isInvalid={isFieldInvalid("newsletter")}>
                    <Checkbox {...register("newsletter")}>{isLoaded ? newsletterLabel : <Skeleton h="18px" w="200px" />}</Checkbox>
                    <FormHelperText ml={4}>
                        {isLoaded ? <SimpleRichText text={newsletterHint || ""} /> : <Skeleton isLoaded={isLoaded} h="14px" w="150px" />}
                    </FormHelperText>
                </FormControl>
                {showPromotionCheckbox && (
                    <FormControl>
                        <Checkbox {...register("sendPromotion")}>{promotionData!.label}</Checkbox>
                        {promotionData!.hint && (
                            <FormHelperText ml={4}>
                                <SimpleRichText text={promotionData!.hint} />
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
                <Button type="submit" w="100%" colorScheme="green" isLoading={isSubmitting}>
                    {t("register")}
                </Button>
                <Box fontSize="sm">
                    <ButtonLink onClick={() => setShowEmailRegisterForm(false)} data-test-id={OTHER_REGISTER_OPTIONS_TEST_ID}>
                        {t("other-register-options")}
                    </ButtonLink>
                </Box>
            </Stack>
        </form>
    );
};
