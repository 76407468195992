import { TFunction, useTranslation } from "next-i18next";
import { RegisterOptions } from "react-hook-form";
import { EMAIL_REGEX } from "src/consts/rexeges";

const emailValidation = (t: TFunction): RegisterOptions => {
    return {
        required: { value: true, message: t("check-email") },
        pattern: {
            value: EMAIL_REGEX,
            message: t("check-email"),
        },
    };
};

interface IPasswordValidationOptions {
    minLength?: number;
    maxLength?: number;
}

// DEV-Note: Unfortunately we have to make the min/max length configurable, since some
// old passwords might still be less then 10 characters. However, new ones should only be
// allowed with at least 10 characters.
const passwordValidation = (
    t: TFunction,
    { minLength, maxLength }: IPasswordValidationOptions = { minLength: 10, maxLength: 50 }
): RegisterOptions => {
    const minL = minLength ?? 10;
    const maxL = maxLength ?? 50;

    return {
        required: { value: true, message: t("enter-password") },
        minLength: { value: minL, message: t("enter-valid-password", { 0: minL, 1: maxL }) },
        maxLength: { value: maxL, message: t("enter-valid-password", { 0: minL, 1: maxL }) },
    };
};

export interface IFormValidatorsHolder {
    email: () => RegisterOptions;
    password: (options?: IPasswordValidationOptions) => RegisterOptions;
}

export const useFormValidator = (): IFormValidatorsHolder => {
    const { t } = useTranslation("common");

    return {
        email: () => emailValidation(t),
        password: (options?: IPasswordValidationOptions) => passwordValidation(t, options),
    };
};
