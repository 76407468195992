import { TagManagerArgs } from "react-gtm-module";
import { IGtmData } from "src/types/layout";

export function initGtm(data: IGtmData): TagManagerArgs {
    return {
        gtmId: data.gtmId,
        dataLayer: {
            countryMode: data.countryMode,
            languageMode: data.languageMode,
            userID: data.userId,
            UserSegment: data.userSegment,
            TraderLifecyclePosition: data.traderLifecyclePosition,
            CustomerLifecyclePosition: data.customerLifecyclePosition,
            WikifolioShortDescription: data.wikifolio?.wikifolioShortDescription,
            WikifolioIsin: data.wikifolio?.wikifolioIsin,
        },
    };
}
