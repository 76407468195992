export const shadows = {
    none: "none",
    xs: "0 1px 12px 0 rgba(0, 0, 0, 0.1)",
    sm: "0 4px 16px 0 rgba(0, 0, 0, 0.12)",
    base: "0 8px 24px 0 rgba(0, 0, 0, 0.12)",
    md: "0 8px 24px 0 rgba(0, 0, 0, 0.15)",
    lg: "0 16px 32px 0 rgba(0, 0, 0, 0.15)",
    xl: "0 24px 48px 0 rgba(0, 0, 0, 0.15)",
    "2xl": "0 28px 56px 0 rgba(0, 0, 0, 0.15)",
    "dark-lg": "0 32px 56px 0 rgba(0, 0, 0, 0.25)",
    outline: "none", // remove the "outline" on all focused elements
    focusVisible: "0 0 0 2px #333333", // box-shadow for keyboard focused elements
};
