import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconUnderlying: TIconComponent = props => (
    <Icon viewBox="0 0 16 16" {...props}>
        <path
            d="m13.303 3.283c0.39599-0.38499 1.0291-0.37607 1.4141 0.019917 0.35537 0.36552 0.37511 0.93309 0.064591 1.321l-0.084507 0.093026-4.8 4.6667c-0.35827 0.34831-0.91233 0.37511-1.3007 0.08038l-0.093436-0.08038-1.7029-1.6557-4.1029 3.989c-0.36552 0.35537-0.93309 0.37511-1.321 0.064591l-0.093026-0.084507c-0.35537-0.36552-0.37511-0.93309-0.064591-1.321l0.084507-0.093026 4.8-4.6667c0.35827-0.34831 0.91233-0.37511 1.3007-0.08038l0.093436 0.08038 1.7029 1.6547 4.1029-3.988z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </Icon>
);
