import { StyleObjectOrFn } from "@chakra-ui/react";

// overriding styles for the embedded helpscout chat
export const HELPSCOUT_CHAT_STYLES: StyleObjectOrFn = {
    ".BeaconFabButtonFrame": {
        display: "none!important",
    },
    ".BeaconContainer": {
        right: ["24px!important", "32px!important"],
        bottom: ["80px!important", "88px!important"],
        top: ["88px!important", "98px!important", "auto!important"],
        height: ["calc(100% - 168px)!important", "calc(100% - 184px)!important", null],
    },
    ".BeaconNotificationsFrame": {
        bottom: "78px!important",
        right: "20px!important",
    },
};
