import { useLayoutEffect, useEffect } from "react";

export const isServerSide = () => typeof window === "undefined";

export const getObjectCopy = <T>(object: T): T => JSON.parse(JSON.stringify(object));

export const isNullOrUndefined = (value: null | undefined | unknown): value is null | undefined => {
    return value === null || value === undefined;
};

// React currently throws a warning when using `useLayoutEffect` on the server.
// To get around it, we can conditionally `useEffect` on the server (no-op) and
// `useLayoutEffect` in the browser.
export const useIsomorphicLayoutEffect = isServerSide() ? useEffect : useLayoutEffect;
