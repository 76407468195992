import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconTwitter: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M13.903 10.469L21.348 2h-1.764l-6.465 7.353L7.955 2H2l7.808 11.119L2 22h1.764l6.827-7.765L16.045 22H22l-8.098-11.531h0zm-2.417 2.749l-.791-1.107L4.4 3.3h2.71l5.08 7.11.791 1.107 6.603 9.242h-2.71l-5.389-7.542h0z"
            />
        </g>
    </Icon>
);
