import Accordion from "./accordion";
import Avatar from "./avatar";
import Badge from "./badge";
import Button from "./button";
import Divider from "./divider";
import Form from "./form";
import FormError from "./form-error";
import FormLabel from "./form-label";
import Input from "./input";
import Menu from "./menu";
import Popover from "./popover";
import Progress from "./progress";
import Skeleton from "./skeleton";
import SkipLink from "./skip-link";
import Slider from "./slider";
import Switch from "./switch";
import Tabs from "./tabs";
import Textarea from "./textarea";

export default {
    Accordion,
    Avatar,
    Tabs,
    Divider,
    Input,
    Form,
    FormLabel,
    FormError,
    Button,
    Badge,
    Skeleton,
    Textarea,
    Popover,
    Menu,
    Slider,
    Progress,
    SkipLink,
    Switch,
};
