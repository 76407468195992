import { StyleFunctionProps } from "@chakra-ui/theme-tools";

const parts = ["container", "title", "description", "icon"];

const baseStyle = {
    container: {
        p: 2,
        alignItems: "flex-start",
    },
    title: {
        fontWeight: "semibold",
        flexGrow: 1,
    },
    icon: {
        flexShrink: 0,
        marginEnd: 1,
        boxSize: 3,
    },
};

const colorMap: { [key: string]: string } = {
    green: "green.600",
    red: "red.600",
    orange: "orange.600",
    blue: "gray.800", // info
};

function variantSolid({ colorScheme }: StyleFunctionProps) {
    return {
        container: {
            bg: colorMap[colorScheme],
            color: "white",
        },
    };
}

const variants = {
    solid: variantSolid,
};

const defaultProps = {
    variant: "solid",
    colorScheme: "green",
};

export default {
    parts,
    baseStyle,
    variants,
    defaultProps,
};
