import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input, Skeleton, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useLoginMutation } from "src/api/client/login.api";
import { ButtonLink } from "src/components/base/button";
import { Button } from "src/components/base/button";
import { HashLink } from "src/components/base/hash-link";
import { WfLink } from "src/components/base/wf-link";
import { useFormValidator } from "src/hooks/form-validator/use-form-validator";
import { useForm } from "src/hooks/use-form";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import {
    EMAIL_LOGIN_FORM_TEST_ID,
    FORGOT_PASSWORD_TEST_ID,
    OTHER_LOGIN_OPTIONS_TEST_ID,
    REGISTER_FOR_FREE_TEST_ID,
} from "./email-login-form.test-ids";

interface IFormValues {
    email: string;
    password: string;
    stayLoggedIn: boolean;
}

export interface IEmailLoginFormProps {
    forgotPasswordLink?: string;
}

export const EmailLoginForm = ({ forgotPasswordLink }: IEmailLoginFormProps) => {
    const { t } = useTranslation("common");
    const { buildRegisterModalLink } = useLinkBuilder();
    const { showEmailLoginForm, setShowEmailLoginForm, setIsLoading, returnUrl, returnPageNodeId } = useAuthModalStore();
    const { register, handleSubmit, formState, setError, setValue, isFieldInvalid, setFocus } = useForm<IFormValues>({
        mode: "onSubmit",
        defaultValues: { email: "", password: "", stayLoggedIn: true },
    });
    const validators = useFormValidator();
    const { isSubmitting, errors } = formState;

    const login = useLoginMutation({
        onSuccess: res => {
            window.location.href = res.returnUrl ?? window.location.href.split("#")[0];
        },
        onError: data => {
            Object.entries(data).forEach(([fieldName, errorMessage]) => {
                setError(fieldName as keyof IFormValues, { message: errorMessage });
            });
            setIsLoading(false);
            setFocus("password");
        },
    });

    if (!showEmailLoginForm) {
        return null;
    }

    return (
        <form
            onSubmit={handleSubmit(async values => {
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }

                setIsLoading(true);
                login({
                    email: values.email,
                    password: values.password,
                    keepLoggedIn: values.stayLoggedIn,
                    returnUrl,
                    returnPageNodeId,
                });
                setValue("password", "");
            })}
            data-test-id={EMAIL_LOGIN_FORM_TEST_ID}
            noValidate
        >
            <Stack spacing={3}>
                <FormControl id="login-email" isInvalid={isFieldInvalid("email")}>
                    <FormLabel>{t("email")}</FormLabel>
                    <Input placeholder={t("email")} type="email" autoFocus {...register("email", { ...validators.email() })} />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
                <FormControl id="login-password" isInvalid={isFieldInvalid("password")}>
                    <FormLabel>{t("password")}</FormLabel>
                    <Input placeholder={t("password")} type="password" {...register("password", { ...validators.password({ minLength: 6 }) })} />
                    <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                </FormControl>
                <Flex flexDir={["column", "row"]}>
                    <Checkbox {...register("stayLoggedIn")}>{t("stay-logged-in")}</Checkbox>
                    <Box flexGrow={1} boxSize={2} />
                    {forgotPasswordLink ? (
                        <WfLink href={forgotPasswordLink || "#"} data-test-id={FORGOT_PASSWORD_TEST_ID}>
                            {t("forgot-password")}
                        </WfLink>
                    ) : (
                        <Skeleton alignSelf="center" h="20px" w="170px" />
                    )}
                </Flex>
                <Button type="submit" w="100%" colorScheme="green" isLoading={isSubmitting}>
                    {t("login-button")}
                </Button>
                <Box fontSize="sm">
                    <Text mb={2}>
                        <Text as="span" mr={0.5}>
                            {t("no-user-yet")}
                        </Text>
                        <HashLink href={buildRegisterModalLink()} whiteSpace="nowrap" data-test-id={REGISTER_FOR_FREE_TEST_ID}>
                            {t("register-for-free")}
                        </HashLink>
                    </Text>
                    <ButtonLink fontSize="sm" onClick={() => setShowEmailLoginForm(false)} data-test-id={OTHER_LOGIN_OPTIONS_TEST_ID}>
                        {t("other-login-options")}
                    </ButtonLink>
                </Box>
            </Stack>
        </form>
    );
};
