import { useEffect } from "react";
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalProps } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useAuthModalQuery } from "src/api/client/auth-modal.api";
import { OverlayLoadingSpinner } from "src/components/base/overlay-loading-spinner";
import { WfModalCloseButton } from "src/components/base/wf-modal-close-button";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import { WfHeading } from "../../base/wf-heading";
import { EmailLoginForm } from "./login-modal-content/email-login-form";
import { LoginOverview } from "./login-modal-content/login-overview";
import { EmailRegisterForm } from "./register-modal-content/email-register-form";
import { RegisterOverview } from "./register-modal-content/register-overview";

export interface IAuthModalProps extends Omit<ModalProps, "children"> {
    isLoginModalOpen: boolean;
    isRegisterSsoModalOpen?: boolean;
}

/**
 * This modal unifies the login and register modal in order to prevent UI flickering when switching between them.
 */
export const AuthModal = ({ isLoginModalOpen, isRegisterSsoModalOpen = false, ...modalProps }: IAuthModalProps) => {
    const { t } = useTranslation("common");
    const router = useRouter();
    const { isLoading: isLoadingAuthModalStore, setShowEmailRegisterForm, setPrefilledRegisterEmail } = useAuthModalStore();
    const { data, isLoading } = useAuthModalQuery(modalProps.isOpen);

    useEffect(() => {
        if (isRegisterSsoModalOpen && router?.query?.email) {
            setShowEmailRegisterForm(true);
            setPrefilledRegisterEmail(router.query.email as string);
        }
    }, [isRegisterSsoModalOpen, router?.query?.email, setPrefilledRegisterEmail, setShowEmailRegisterForm]);

    if (!modalProps.isOpen) {
        return null;
    }

    return (
        <Modal {...modalProps}>
            <ModalOverlay />
            <ModalContent maxW={["full", "688px", "708px"]} minH={["100%", "auto"]}>
                <ModalHeader display="flex" alignItems="center" pb={[4, 5]}>
                    <WfHeading as="h2" w="100%" fontSize={["xl", "2xl"]} textAlign="center">
                        {isLoginModalOpen ? t("login") : t("register-for-free")}
                    </WfHeading>
                </ModalHeader>
                <WfModalCloseButton />
                <ModalBody pb={[3, 5]}>
                    {isLoginModalOpen ? (
                        <>
                            {/* Dev-Note:
                                rendering both components (although they return null if inactive) in order to keep
                                the e-mail form state when switching between the two views
                            */}
                            <LoginOverview />
                            <EmailLoginForm forgotPasswordLink={data?.forgotPasswordLink} />
                        </>
                    ) : (
                        <>
                            {/* Dev-Note:
                                rendering both components (although they return null if inactive) in order to keep
                                the e-mail form state when switching between the two views
                            */}
                            <RegisterOverview />
                            <EmailRegisterForm
                                isSsoRegister={isRegisterSsoModalOpen}
                                isLoaded={!isLoading}
                                agbLabel={data?.agbLabel}
                                agbHint={data?.agbHint}
                                newsletterLabel={data?.newsletterLabel}
                                newsletterHint={data?.newsletterHint}
                            />
                        </>
                    )}
                    {isLoadingAuthModalStore && <OverlayLoadingSpinner />}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
