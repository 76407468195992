import { Image, ImageProps } from "@chakra-ui/react";
import { BREAKPOINTS } from "src/theme";
import { buildUrl, IQueryParams } from "src/utils/responsive-image-util";
import { Picture } from ".";

const BREAKPOINT_VALUES = Object.values(BREAKPOINTS);

export interface ISrc {
    queryParams?: IQueryParams;
    url?: string;
}

export interface IResponsiveImageProps extends Omit<ImageProps, "src" | "srcSet" | "alt"> {
    baseUrl: string;
    alt: string | null;
    onError?: () => void;
    srcSet?: ISrc[];
}

export const ResponsiveImage = ({ baseUrl, alt, srcSet, loading = "lazy", onError, ...imageProps }: IResponsiveImageProps) => {
    let defaultSrc: ISrc | undefined;
    let otherSources: Array<ISrc> = [];
    if (srcSet) {
        [defaultSrc, ...otherSources] = srcSet;
    }
    const necessaryBreakpoints = BREAKPOINT_VALUES.slice(0, otherSources.length).reverse();

    const onErrorHandle = () => onError && onError();

    return (
        <Picture display="contents">
            {otherSources.reverse().map((src, index) => {
                if (!src) {
                    return null;
                }

                const breakpoint = necessaryBreakpoints[index];
                if (!breakpoint) {
                    return null;
                }

                // using index as key here, because the order shouldn't change on build time
                // and would take a more complex logic to calculate a unique key here from src object
                return <source key={index} media={`(min-width: ${breakpoint})`} srcSet={buildUrl(src.url || baseUrl, src.queryParams)} />;
            })}
            <Image
                src={buildUrl(defaultSrc?.url ?? baseUrl, defaultSrc?.queryParams)}
                loading={loading}
                alt={alt ?? undefined}
                onError={onErrorHandle}
                {...imageProps}
            />
        </Picture>
    );
};
