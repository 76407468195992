/* eslint-disable @typescript-eslint/no-explicit-any */
import { extendTheme } from "@chakra-ui/react";
import { THEME_COLORS } from "./colors";
import components from "./components";
import Accordion from "./components/accordion";
import Alert from "./components/alert";
import Button from "./components/button";
import Checkbox from "./components/checkbox";
import Link from "./components/link";
import Menu from "./components/menu";
import Modal from "./components/modal";
import Progress from "./components/progress";
import Radio from "./components/radio";
import Select from "./components/select";
import Slider from "./components/slider";
import Table from "./components/table";
import { HELPSCOUT_CHAT_STYLES } from "./global";
import { shadows } from "./shadows";
import Typography from "./typography";

export const SPACE_UNIT_SIZE_PX = 8;

export const BREAKPOINTS = { sm: "768px", md: "992px", lg: "1200px", xl: "100000px" };
const space = [0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].reduce<{ [key: string]: string }>((prev, currentValue) => {
    prev[currentValue.toString()] = currentValue * SPACE_UNIT_SIZE_PX + "px";
    return prev;
}, {});

const customTheme = extendTheme({
    styles: {
        global: {
            ...HELPSCOUT_CHAT_STYLES,
        },
    },
    config: {
        initialColorMode: "light",
        useSystemColorMode: false,
    },
    /** Used for: `padding`, `margin`, and `top`, `left`, `right`, `bottom` */
    space,
    /** Used for: `width`, `height`, and `maxWidth`, `minWidth`, `maxHeight`, `minHeight` */
    sizes: space,
    fonts: Typography.fonts,
    fontSizes: Typography.fontSizes,
    letterSpacings: Typography.letterSpacings,
    components,
});

// Complete override of default chakra theme
customTheme.colors = {
    // keep basic colors from chakra
    transparent: customTheme.colors.transparent,
    current: customTheme.colors.current,
    black: customTheme.colors.black,
    white: customTheme.colors.white,
    whiteAlpha: customTheme.colors.whiteAlpha,
    blackAlpha: customTheme.colors.blackAlpha,
    // apply wikifolio theme colors
    ...THEME_COLORS,
} as any;
customTheme.lineHeights = Typography.lineHeights as any;
customTheme.fontWeights = Typography.fontWeights as any;
customTheme.shadows = shadows as any;
// DN: We have to override the breakpoints
customTheme.breakpoints = BREAKPOINTS as any;

customTheme.components.Alert = Alert as any;
customTheme.components.Accordion = Accordion as any;
customTheme.components.Button = Button as any;
customTheme.components.Select = Select as any;
customTheme.components.Link = Link as any;
customTheme.components.Checkbox = Checkbox as any;
customTheme.components.Radio = Radio as any;
customTheme.components.Modal = Modal as any;
customTheme.components.Table = Table as any;
customTheme.components.Menu = Menu as any;
customTheme.components.Slider = Slider as any;
customTheme.components.Progress = Progress as any;

export default customTheme;
