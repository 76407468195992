import { keyframes } from "@chakra-ui/react";
import { getColor, StyleFunctionProps } from "@chakra-ui/theme-tools";

const defaultStartColor = "gray.100";
const defaultEndColor = "gray.200";

const fade = (startColor: string, endColor: string) =>
    keyframes({
        from: { borderColor: startColor, background: startColor },
        to: { borderColor: endColor, background: endColor },
    });

const baseStyle = ({ startColor = defaultStartColor, endColor = defaultEndColor, speed, theme }: StyleFunctionProps) => {
    const start = getColor(theme, startColor);
    const end = getColor(theme, endColor);

    return {
        opacity: 0.7,
        borderRadius: "2px",
        borderColor: start,
        background: start,
        animation: `${speed}s linear infinite alternate ${fade(start, end)}`,
    };
};

export default {
    baseStyle,
};
