import { useCallback } from "react";
import { API_SEARCH_AUTOCOMPLETE_URL } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { IAutoCompleteTrader } from "src/types/common";
import { EnumSecurityType } from "src/types/common-enum";
import { useRouterLocale } from "src/utils/router/use-router-locale";

export interface ISearchRequest {
    country: string;
    fullText: string;
    underlyingsCount: number;
    wikifoliosCount: number;
}

export interface ISearchUnderlying {
    shortDescription: string;
    /** Isin */
    resourceIdentifier: string;
    securityType: EnumSecurityType;
}

export interface ISearchWikifolio {
    id: string;
    shortDescription: string;
    /** Symbol or isin */
    resourceIdentifier: string;
    performance: number;
    trader: IAutoCompleteTrader;
}

export interface IGlobalSearchResponse {
    totalUnderlyingsFound: number;
    totalWikifoliosFound: number;
    wikifolios: ISearchWikifolio[];
    underlyings: ISearchUnderlying[];
}

interface ISearchAutocompleteMutationVariables {
    underlyingsCount: number;
    wikifoliosCount: number;
    securityTypes?: number[];
}

const DEFAULT_SEARCH_AUTOCOMPLETE_VARIABLES: ISearchAutocompleteMutationVariables = {
    underlyingsCount: 3,
    wikifoliosCount: 3,
};

export const useSearchAutocompleteMutation = (searchVariables: ISearchAutocompleteMutationVariables = DEFAULT_SEARCH_AUTOCOMPLETE_VARIABLES) => {
    const { country } = useRouterLocale();
    const { mutatePromise } = useMutation<IGlobalSearchResponse, unknown, ISearchRequest>(API_SEARCH_AUTOCOMPLETE_URL);

    return useCallback(
        async (searchTerm: string) =>
            await mutatePromise({
                country,
                fullText: searchTerm,
                ...searchVariables,
            }),
        [country, searchVariables, mutatePromise]
    );
};
