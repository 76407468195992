export default {
    fonts: {
        body: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans","Helvetica Neue", sans-serif',
    },
    fontSizes: {
        "2xs": "10px",
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
        "2xl": "24px",
        "3xl": "32px",
        "4xl": "40px",
        "5xl": "60px",
        "6xl": "80px",
    },
    fontWeights: {
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        heavy: 800,
    },
    lineHeights: {
        normal: "normal",
        none: 1,
        shorter: 1.15,
        short: 1.25,
        base: 1.5,
        tall: 1.6,
        taller: 2,
    },
    letterSpacings: {
        tighter: "-1.5px",
        tight: "-1px",
        normal: "0",
        wide: "1px",
        wider: "1.5px",
        widest: "2px",
    },
};
