import { LinkProps } from "@chakra-ui/react";
import { IconArrowRight } from "src/components/icons";
import { WfLinkGhost } from "./wf-link";

export interface IArrowLinkProps extends Omit<LinkProps, "variant"> {
    href: string;
}

export const ArrowLink = ({ children, ...linksProps }: IArrowLinkProps) => (
    <WfLinkGhost {...linksProps}>
        <>
            {children}
            <IconArrowRight ml={0.5} />
        </>
    </WfLinkGhost>
);
