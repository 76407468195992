import { proxy, useSnapshot } from "valtio";

export interface IPromotionData {
    id: string;
    hideCheckbox: boolean;
    label?: string;
    hint?: string;
}

export const authModalStore = proxy({
    isLoading: false,

    // Login
    showEmailLoginForm: false,
    returnPageNodeId: null as number | null,
    returnUrl: undefined as string | undefined,

    // Registration
    showEmailRegisterForm: false,
    prefilledRegisterEmail: "",
    isNewsletterCheckboxEnabled: false,
    promotionData: null as IPromotionData | null,
    welcomePageNodeId: null as number | null,

    get showPromotionCheckbox() {
        return authModalStore.promotionData !== null && !authModalStore.promotionData.hideCheckbox;
    },

    setIsLoading: (value: boolean) => {
        authModalStore.isLoading = value;
    },

    setReturnPageNodeId: (value: number | null) => {
        authModalStore.returnPageNodeId = value;
    },

    setReturnUrl: (value: string) => {
        authModalStore.returnUrl = value;
    },

    setShowEmailLoginForm: (value: boolean) => {
        authModalStore.showEmailLoginForm = value;
    },

    setShowEmailRegisterForm: (value: boolean) => {
        authModalStore.showEmailRegisterForm = value;
    },
    setPrefilledRegisterEmail: (value: string) => {
        authModalStore.prefilledRegisterEmail = value;
    },

    setIsNewsletterCheckboxEnabled: (value: boolean) => {
        authModalStore.isNewsletterCheckboxEnabled = value;
    },

    setPromotionData: (value: IPromotionData | null) => {
        authModalStore.promotionData = value;
    },

    setWelcomePageNodeId: (value: number | null) => {
        authModalStore.welcomePageNodeId = value;
    },

    reset: () => {
        authModalStore.isLoading = false;

        authModalStore.showEmailLoginForm = false;

        authModalStore.showEmailRegisterForm = false;
        authModalStore.prefilledRegisterEmail = "";

        authModalStore.isNewsletterCheckboxEnabled = false;

        authModalStore.promotionData = null;

        authModalStore.welcomePageNodeId = null;
        authModalStore.returnPageNodeId = null;
    },
});

export function useAuthModalStore() {
    return useSnapshot(authModalStore);
}
