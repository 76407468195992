import { useEffect } from "react";
import { useBoolean, useBreakpointValue } from "@chakra-ui/react";
import throttle from "lodash.throttle";
import { useTranslation } from "next-i18next";
import { ButtonProps } from "src/components/base/button";
import { IconArrowRight } from "src/components/icons";
import { scrollToTheTop } from "src/utils/dom-util";
import { ServiceButton } from "./service-button";

interface IJumpToTopButton extends ButtonProps {
    isEnabled: boolean;
}

const getButtonStyles = (isJumpToTopVisible: boolean, isEnabled: boolean, isMobile?: boolean): ButtonProps => {
    const styles = isMobile
        ? {
              display: isJumpToTopVisible && isEnabled ? "block" : "none",
          }
        : {
              opacity: isJumpToTopVisible && isEnabled ? 1 : 0,
              transition: "all 0.2s",
          };

    return {
        padding: 0,
        pointerEvents: isJumpToTopVisible && isEnabled ? "auto" : "none",
        ...styles,
    };
};

export const JumpToTopButton = ({ isEnabled = true, ...props }: IJumpToTopButton) => {
    const { t } = useTranslation("common");
    const [isJumpToTopVisible, setJumpToTopVisible] = useBoolean();
    const isMobile = useBreakpointValue([true, false]);
    const buttonStyles = getButtonStyles(isJumpToTopVisible, isEnabled, isMobile);

    const onScroll = throttle(() => {
        if (window.scrollY > 2 * window.innerHeight) {
            setJumpToTopVisible.on();
        }

        if (window.scrollY == 0) {
            setJumpToTopVisible.off();
        }
    }, 200);

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    });
    return (
        <ServiceButton onClick={() => scrollToTheTop()} {...props} {...buttonStyles} aria-label={t("labels.back-to-top")}>
            <IconArrowRight transform="rotate(-90deg)" width={3} height={3} />
        </ServiceButton>
    );
};
