import {
    As,
    // eslint-disable-next-line no-restricted-imports
    Heading,
    HeadingProps,
} from "@chakra-ui/react";

export const headingFontSizeMap = {
    "6xl": {
        fontSize: "6xl",
        fontWeight: "heavy",
        letterSpacing: "wider",
        lineHeight: "shorter",
    },
    "5xl": {
        fontSize: "5xl",
        fontWeight: "heavy",
        letterSpacing: "wider",
        lineHeight: "shorter",
    },
    "4xl": {
        fontSize: "4xl",
        fontWeight: "heavy",
        letterSpacing: "normal",
        lineHeight: "short",
    },
    "3xl": {
        fontSize: "3xl",
        fontWeight: "bold",
        letterSpacing: "normal",
        lineHeight: "short",
    },
    "2xl": {
        fontSize: "2xl",
        fontWeight: "bold",
        letterSpacing: "normal",
        lineHeight: "base",
    },
    xl: {
        fontSize: "xl",
        fontWeight: "bold",
        letterSpacing: "normal",
        lineHeight: "tall",
    },
    md: {
        fontSize: "md",
        fontWeight: "bold",
        letterSpacing: "normal",
        lineHeight: "base",
    },
    sm: {
        fontSize: "sm",
        fontWeight: "semibold",
        letterSpacing: "normal",
        lineHeight: "base",
    },
};
export type IFontSize = keyof typeof headingFontSizeMap;

/**
 * This functions builds our responsive header font values (size, weight, letterSpacing, lineHeight) depending on the given fontSize
 */
export const buildResponsiveHeaderFont = (fontSize: IFontSize | IFontSize[]) => {
    if (typeof fontSize === "string") {
        return headingFontSizeMap[fontSize];
    }

    const responsiveFont = {
        fontSize: [] as Array<string>,
        fontWeight: [] as Array<string>,
        letterSpacing: [] as Array<string>,
        lineHeight: [] as Array<string>,
    };

    fontSize.forEach(f => {
        const font = headingFontSizeMap[f];
        responsiveFont.fontSize.push(font.fontSize);
        responsiveFont.fontWeight.push(font.fontWeight);
        responsiveFont.letterSpacing.push(font.letterSpacing);
        responsiveFont.lineHeight.push(font.lineHeight);
    });

    return responsiveFont;
};

export interface IWfHeadingProps extends HeadingProps {
    as: As;
    fontSize: IFontSize | IFontSize[];
}

export const WfHeading = ({ fontSize, ...props }: IWfHeadingProps) => {
    const fontValues = buildResponsiveHeaderFont(fontSize);

    return <Heading {...fontValues} {...props} />;
};
