import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconWikifolio: TIconComponent = props => (
    <Icon viewBox="0 0 16 16" {...props}>
        <g transform="translate(1 2)" fill="currentColor">
            <polygon points="10.921 0 8.8514 8.9793 7.5153 3.2863 4.8765 3.2863 3.5401 8.9793 2.8857 5.8771 0 5.8771 1.7471 12 4.9272 12 6.1958 7.0704 7.4643 12 10.645 12 14 0" />
        </g>
    </Icon>
);
