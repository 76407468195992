import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconMail: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M18 0C19.1045695.0 20 .8954305 20 2V2 14C20 15.1045695 19.1045695 16 18 16v0H2C.8954305 16 0 15.1045695.0 14v0V2C0 1.98828487.000100725653 1.97659326.000301358172 1.964926.000856703695 1.95572044.00130715308 1.94636155.0018891532 1.93700609.0342653879.861567687.916517736.0 2 0V0zm0 4.044L10.6139406 9.78935222C10.2889412 10.0421296 9.84685642 10.0674073 9.49843993 9.86518542L9.38605939 9.78935222 2 4.044V14H18V4.044zM17.37 2H2.629L10 7.733 17.37 2z"
                transform="translate(2 4)"
            />
        </g>
    </Icon>
);
