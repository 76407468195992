import { useCopyIsinTracking } from "src/hooks/use-copy-isin-tracking";
import { IGlobal } from "src/types/layout";
import { useConsentUpdated } from "./use-consent-updated";
import { useGtm } from "./use-gtm";
import { useTrackVWOActivity } from "./use-track-vwo-activity";

export function useInitializeTracking(global: IGlobal | null, data: unknown) {
    useGtm(global, data);
    useConsentUpdated();
    useCopyIsinTracking();
    useTrackVWOActivity();
}
