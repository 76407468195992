import { ReactNode } from "react";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";
import { WfHeading } from "src/components/base/wf-heading";
import { WfLink, WfLinkUnstyled } from "src/components/base/wf-link";
import { IconError, IconWikifolioLogo } from "src/components/icons";
import { Container } from "src/components/layout/container";
import { getGlobals } from "src/consts/globals";

const ErrorImage = () => <IconError width={["100%", "auto"]} height={["unset", 328]} />;

interface IProps {
    subHeadline: string;
    children?: ReactNode;
    showLogo?: boolean;
    staticTranslations?: (key: string) => string;
}
export const ErrorCommon = ({ subHeadline, children, showLogo, staticTranslations }: IProps) => {
    const globals = getGlobals();
    const { t } = useTranslation("common");

    const activeT = staticTranslations ?? t;

    const searchExtendedRoute = globals?.routes.searchExtended;

    return (
        <Container mt={[2, 3, "128px"]} mb={[7, 7, "128px"]}>
            <Flex direction="column">
                {showLogo ? (
                    <WfLink
                        href="/"
                        h={[3, 3, 4]}
                        mb={4}
                        alignSelf={["center", "center", "flex-start"]}
                        aria-label="wikifolio Financial Technologies"
                        title="wikifolio Financial Technologies"
                        display="block"
                    >
                        <IconWikifolioLogo boxSize="100%" color="green.600" />
                    </WfLink>
                ) : (
                    <Box h={[4, 4, 0]} mb={[4, 4, 0]}></Box>
                )}
                <Flex direction={["column", "column", "row"]} justifyContent="space-between">
                    <Flex direction="column" alignItems={["center", "center", "flex-start"]}>
                        <WfHeading as="h1" fontSize={["4xl", "4xl", "6xl"]} textAlign={["center", "center", "unset"]}>
                            {activeT("error-page-headline")}
                        </WfHeading>
                        <WfHeading as="h2" fontSize={["2xl", "2xl", "3xl", "4xl"]} mt={2} fontWeight="bold" textAlign={["center", "center", "unset"]}>
                            {subHeadline}
                        </WfHeading>
                        {children}
                        <Stack direction={["column", "row"]} mt={5} spacing={2} w={["100%", "unset"]}>
                            <Button as={WfLinkUnstyled} size="md" variant={searchExtendedRoute && "outline"} href="/">
                                {activeT("home-page")}
                            </Button>
                            {searchExtendedRoute && (
                                <Button as={WfLinkUnstyled} size="md" href={searchExtendedRoute}>
                                    {activeT("search-wikifolios")}
                                </Button>
                            )}
                        </Stack>
                    </Flex>
                    <Flex justifyContent="center" alignItems="center" mt={[5, 10, 0]} ml={[0, 0, 0, "264px"]}>
                        <ErrorImage />
                    </Flex>
                </Flex>
            </Flex>
        </Container>
    );
};
