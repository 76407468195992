import { useInitializeTracking } from "src/analytics/use-initialize-tracking";
import { initGlobals } from "src/consts/globals";
import { useCopyIsinTracking } from "src/hooks/use-copy-isin-tracking";
import { useEmailNewsletterLink } from "src/hooks/use-email-newsletter-link";
import { setUser } from "src/stores/user-store";
import { IBasePageProps } from "src/types/layout";
import { useDisclaimerCheck } from "./use-disclaimer-check";

// This hook initializes main functionality of the app
export function useInitApp(pageProps: IBasePageProps<unknown, unknown>) {
    useDisclaimerCheck(pageProps.global?.disclaimer);
    useCopyIsinTracking();
    initGlobals(pageProps.global);
    // Dev-Note: we don't use the user store via hook directly here, because that would lead to an unnecessary re-render
    setUser(pageProps.user);
    useInitializeTracking(pageProps.global, pageProps.data);
    useEmailNewsletterLink(pageProps.user!);
}
