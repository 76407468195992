import { useCallback } from "react";
import { useRouter } from "next/router";
import { urlSearchParamsToQueryString } from "src/components/pages/search/utils/url-params-helpers";
import { useRouterLocale } from "src/utils/router/use-router-locale";

interface ISearchLinkOptions {
    searchTerm?: string;
    investable?: boolean;
}

export const useLinkBuilder = () => {
    const router = useRouter();
    const { language, country } = useRouterLocale();

    const buildHashLink = (hashName: string) => {
        return `/${router.locale}${decodeURIComponent(router.asPath).split("#")[0]}#${hashName}`;
    };

    const getHashParametersFromUrl = useCallback(() => router.asPath.split("#")[1]?.split("-").splice(1), [router]);
    const getHashParameterFromUrl = useCallback(() => router.asPath.split("#")[1], [router]);

    const buildHashLinkWithParameters = (hashLink: string, parameters?: ReadonlyArray<string>) =>
        buildHashLink(parameters?.length ? `${hashLink}-${parameters.join("-")}` : hashLink);

    const buildLoginModalLink = () => buildHashLinkWithParameters("login", getHashParametersFromUrl());
    const buildRegisterModalLink = () => buildHashLinkWithParameters("register", getHashParametersFromUrl());

    const openLoginModal = () => router.push(buildLoginModalLink(), undefined, { shallow: true });

    const isHashNameInUrl = useCallback((hashName: string) => `${router.asPath.split("#")[1]}`.startsWith(hashName), [router]);

    const getLocaleUrl = (url: string): string => {
        let localeUrl = `/${language}/${country}`;

        if (!url.startsWith("/")) {
            localeUrl += "/";
        }

        return localeUrl + url;
    };

    const buildWikifolioLink = (symbol: string, query?: string) =>
        query ? getLocaleUrl(`/w/${symbol.toLowerCase()}${query}`) : getLocaleUrl(`/w/${symbol.toLowerCase()}`);

    const buildUnderlyingLink = (isin: string, wikifolioSymbol?: string) => {
        const searchParams = new URLSearchParams();

        if (wikifolioSymbol) {
            searchParams.append("wikifolio", wikifolioSymbol.toLowerCase());
        }

        const queryParamString = searchParams.toString();

        return getLocaleUrl(`/s/${isin.toLowerCase()}${queryParamString ? `?${queryParamString}` : ""}`);
    };

    const buildTraderProfileLink = (traderNickName: string) => traderNickName && getLocaleUrl(`/p/${traderNickName.toLowerCase()}`);

    const buildSearchLink = (options?: ISearchLinkOptions) => {
        const url = language == "de" ? getLocaleUrl("/alle-wikifolios/suche") : getLocaleUrl("/all-wikifolios/search");

        if (!options) {
            return url;
        }

        // TODO: Alternative to type cast?
        return `${url}?${urlSearchParamsToQueryString(new URLSearchParams(options as Record<string, string>))}`;
    };

    const buildCommentLink = (traderNickName: string, commentId: string, utmPageSrc: string, origin: string = window.location.origin) => {
        const traderUrl = buildTraderProfileLink(traderNickName);

        const searchParams = new URLSearchParams();
        searchParams.append("commentId", commentId);
        searchParams.append("utm_campaign", "sharedcomment");
        searchParams.append("utm_medium", utmPageSrc);
        const queryParamsString = searchParams.toString();

        return `${origin}${traderUrl}?${queryParamsString}`;
    };

    return {
        buildLoginModalLink,
        isHashNameInUrl,
        getLocaleUrl,
        openLoginModal,
        buildRegisterModalLink,
        buildWikifolioLink,
        buildUnderlyingLink,
        buildTraderProfileLink,
        buildSearchLink,
        buildCommentLink,
        buildHashLink,
        buildHashLinkWithParameters,
        getHashParametersFromUrl,
        getHashParameterFromUrl,
    };
};

export const openLinkInNewTab = (routeUrl: string) => {
    window.open(routeUrl, "_blank");
};
