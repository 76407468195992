import { SystemProps } from "@chakra-ui/react";

const parts = ["container", "track", "filledTrack", "sliderThumb"];

const baseStyleContainer: SystemProps = {
    w: "100%",
};

const baseStyleTrack: SystemProps = {
    background: "gray.200",
    overflow: "hidden",
    borderRadius: "8px",
    height: "4px",
};

const baseStyleFilledTrack: SystemProps = {
    background: "gray.800",
};

export const SLIDER_THUMB_SIZE = 20;

const baseStyleSliderThumb: SystemProps = {
    boxSize: `${SLIDER_THUMB_SIZE}px`,
    top: "50%",
    borderRadius: "50%",
    transform: "translateY(-50%)",
    background: "gray.800",
    transitionProperty: "common",
    transitionDuration: "normal",
    _focus: {
        boxShadow: "0 0 0 3px #bfbfbf",
    },
    _active: {
        transform: "translateY(-50%) scale(1.3)",
    },
};

const baseStyle = {
    container: baseStyleContainer,
    track: baseStyleTrack,
    filledTrack: baseStyleFilledTrack,
    thumb: baseStyleSliderThumb,
};

export default {
    parts,
    baseStyle,
};
