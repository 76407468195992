import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useAuthModalRegularLinksTrigger } from "src/components/modals/auth-modal/hooks/use-auth-modal-regular-links-trigger";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useAuthModalStore } from "src/stores/auth-modal-store";

export const useAuthModal = () => {
    const router = useRouter();
    useAuthModalRegularLinksTrigger(router);
    const authModalStore = useAuthModalStore();
    const { isHashNameInUrl, getHashParametersFromUrl } = useLinkBuilder();
    const [shouldOpenLoginModal, setShouldOpenLoginModal] = useState(false);
    const [shouldOpenRegisterModal, setShouldOpenRegisterModal] = useState(false);
    const [shouldOpenRegisterSsoModal, setShouldOpenRegisterSsoModal] = useState(false);

    useEffect(() => {
        const hashParameters = getHashParametersFromUrl();
        const loginReturnPageNodeId = Number(hashParameters?.[0]) || authModalStore.returnPageNodeId;
        const registerReturnPageNodeId = Number(hashParameters?.[1]) || authModalStore.welcomePageNodeId;

        authModalStore.setReturnPageNodeId(loginReturnPageNodeId);
        authModalStore.setWelcomePageNodeId(registerReturnPageNodeId);

        setShouldOpenLoginModal(isHashNameInUrl("login") || Boolean(router.query.ReturnUrl));
        setShouldOpenRegisterModal(isHashNameInUrl("register"));
        setShouldOpenRegisterSsoModal(isHashNameInUrl("register_sso"));
    }, [isHashNameInUrl, router, authModalStore, getHashParametersFromUrl]);

    const closeAuthModal = useCallback(() => {
        router.push(window.location.pathname, undefined, { shallow: true }).then(() => {
            setShouldOpenLoginModal(false);
            setShouldOpenRegisterModal(false);
            setShouldOpenRegisterSsoModal(false);
            authModalStore.reset();
        });
    }, [router, authModalStore]);

    return {
        authModalStore,
        shouldOpenLoginModal,
        shouldOpenRegisterModal,
        shouldOpenRegisterSsoModal,
        closeAuthModal,
    };
};
