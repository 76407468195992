import { useCallback, useEffect } from "react";
import type { ApplicationInsights as TApplicationInsights, IConfiguration, IConfig } from "@microsoft/applicationinsights-web";
import { AppProps } from "next/app";
import { useCookieBotConsentReady } from "src/hooks/cookie-bot/use-cookie-bot-consent-ready";
import { isServerSide } from "src/utils/common-util";

interface ICustomConfig {
    isEnabled: boolean;
}

interface ILocalConfig {
    appInsightsConfig: IConfiguration & IConfig & ICustomConfig;
    appProps: Pick<AppProps, "router" | "Component">;
}

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        appInsights?: TApplicationInsights;
    }
}

let appInsights: TApplicationInsights;

const useApplicationInsights = ({ appInsightsConfig, appProps }: ILocalConfig) => {
    const isCookieBotConsentReady = useCookieBotConsentReady();

    const initializeAppInsights = useCallback(async () => {
        if (!isServerSide() && appInsightsConfig.isEnabled && !appInsights) {
            // Dynamically load ApplicationInsights only if it is enabled
            const { ApplicationInsights } = await import("@microsoft/applicationinsights-web");
            appInsights = new ApplicationInsights({ config: appInsightsConfig });
            appInsights.loadAppInsights();
            window.appInsights = appInsights;
        }
    }, [appInsightsConfig]);

    const trackPageView = useCallback(() => {
        if (appInsights) {
            const name = appProps.Component.displayName || appProps.Component.name || location.pathname;
            const properties: Record<string, string> = {
                route: appProps.router.route,
            };
            if (appProps.router.query) {
                for (const key in appProps.router.query) {
                    if (key) {
                        properties[`query.${key}`] = String(appProps.router.query[key]);
                    }
                }
            }
            appInsights.trackPageView({ name, properties });
        }
    }, [appProps.Component.displayName, appProps.Component.name, appProps.router.query, appProps.router.route]);

    useEffect(() => {
        const initAndTrack = async () => {
            await initializeAppInsights();
            trackPageView();
        };

        if (isCookieBotConsentReady && window?.Cookiebot?.consent.statistics) {
            initAndTrack();
        }
    }, [appProps.router.asPath, isCookieBotConsentReady, initializeAppInsights, trackPageView]);
};

export default useApplicationInsights;
