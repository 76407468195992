import { useEffect, useRef } from "react";
import { UseDisclosureReturn } from "@chakra-ui/react";
import { useGenericActivityFromElysiumMutation } from "src/api/client/generic-activity-from-elysium.api";
import { EnumActivityType } from "src/api/client/generic-activity.api";
import { isServerSide } from "src/utils/common-util";

export interface IChatState extends UseDisclosureReturn {}
interface IMessageClicked {
    id: string;
}

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Beacon: { (...args: any): any; readyQueue: any[] };
    }
}

let isChatInitialized = false;

async function loadHelpScoutScript() {
    return new Promise<void>(resolve => {
        // needed procedure before we load the helpscout script
        window.Beacon = (() => {
            const _Beacon: typeof window.Beacon = function (t, n, a) {
                window.Beacon.readyQueue.push({ method: t, options: n, data: a });
            };
            _Beacon.readyQueue = [];
            return _Beacon;
        })();

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://beacon-v2.helpscout.net";
        script.onload = () => {
            resolve();
        };

        document.head.appendChild(script);
    });
}

async function initChat(state: Pick<IChatState, "onClose">) {
    if (isChatInitialized) {
        return;
    }
    isChatInitialized = true;

    await loadHelpScoutScript();

    // init helpscout with the wikifolio id
    window.Beacon("init", "66499048-f129-4781-a9c6-254b100003ac");

    // handler when helpscout is closed with buttons inside or keyboard
    window.Beacon("on", "close", state.onClose);
}

function openChat() {
    if (window.Beacon) {
        window.Beacon("open");
    }
}

function closeChat() {
    if (window.Beacon) {
        window.Beacon("close");
    }
}

// this hook takes care of rendering the helpscout chat
export const useHelpscoutChat = (state: IChatState, showServiceButtons: boolean) => {
    const { onClose, onToggle, isOpen } = state;
    const genericActivity = useGenericActivityFromElysiumMutation();
    const isRunOnce = useRef(false);

    if (!isServerSide() && showServiceButtons && !isRunOnce.current) {
        isRunOnce.current = true;
        (async function () {
            await initChat({ onClose });
            window.Beacon("on", "message-clicked", ({ id }: IMessageClicked) => {
                onToggle();
                genericActivity({
                    activityTypeId: EnumActivityType.HelpscoutMessageClicked,
                    parameter: `messageId=${id}`,
                    referrer: window.location.href || null,
                });
            });
        })();
    }

    useEffect(() => {
        if (isOpen) {
            openChat();
        } else {
            closeChat();
        }

        return () => {
            closeChat();
        };
    }, [onClose, isOpen]);
};
