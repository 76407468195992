import { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { IGlobalSearchResponse, useSearchAutocompleteMutation } from "src/api/client/search-autocomplete.api";

const WAIT_MILLISECONDS = 350;
const MIN_QUERY_LENGTH = 2;

export type ISearchResultsMutationVariables = {
    wikifoliosCount: number;
    underlyingsCount: number;
    securityTypes: number[];
};

export interface ISearchResultsArgs {
    queryValue: string;
    mutationVariables?: ISearchResultsMutationVariables;
}
export interface ISearchResults {
    flushResults: () => void;
    results: IGlobalSearchResponse | null;
}

export const useSearchResults = ({ queryValue, mutationVariables }: ISearchResultsArgs): ISearchResults => {
    const navSearchMutation = useSearchAutocompleteMutation(mutationVariables);
    const [searchResults, setSearchResults] = useState<IGlobalSearchResponse | null>(null);

    const getResults = useCallback(
        (queryValue: string) =>
            navSearchMutation(queryValue).then(data => {
                setSearchResults(data);
            }),
        [navSearchMutation]
    );

    const debounceApiCall = useMemo(() => debounce(getResults, WAIT_MILLISECONDS, { leading: false, maxWait: 350 }), [getResults]);

    useEffect(() => {
        if (queryValue.trim().length >= MIN_QUERY_LENGTH) {
            debounceApiCall(queryValue);
        }

        // cancel api call in case of unmount
        return () => {
            debounceApiCall.cancel();
        };
    }, [queryValue, debounceApiCall]);

    return {
        results: searchResults,
        flushResults: () => {
            setSearchResults(null);
        },
    };
};
