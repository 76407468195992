import { ParsedUrlQuery } from "querystring";
import { EnumSearchQueryParams } from "src/components/pages/search/search.types";

export const getNormalizedUrlParams = (params: URLSearchParams) => {
    const urlParams = new URLSearchParams();
    params.forEach((value, key) => urlParams.append(key.toLowerCase(), value));

    return urlParams;
};

type EnumSearchQueryParamsKey = keyof typeof EnumSearchQueryParams;

const getEnumKey = (paramsKey: string) => {
    const enumKeys = Object.keys(EnumSearchQueryParams) as EnumSearchQueryParamsKey[];
    const enumKey = enumKeys.find(key => EnumSearchQueryParams[key].toLowerCase() === paramsKey.toLowerCase())!;
    return enumKey;
};

export const getUpdatedUrlParams = (params: URLSearchParams) => {
    const urlParams = new URLSearchParams();
    params.forEach((value, paramsKey) => {
        const enumKey = getEnumKey(paramsKey);
        if (Boolean(enumKey)) {
            urlParams.append(EnumSearchQueryParams[enumKey], value);
        } else {
            urlParams.append(paramsKey, value);
        }
    });

    return urlParams;
};

export const getNormalizedRouterQuery = (query: ParsedUrlQuery) =>
    Object.keys(query).reduce((acc, key) => {
        const paramKey = getEnumKey(key);
        acc[paramKey ? EnumSearchQueryParams[paramKey] : key] = query[key];
        return acc;
    }, {} as ParsedUrlQuery);

export const parseUrlQueryToUrlSearchParams = (query: ParsedUrlQuery): URLSearchParams => {
    const searchParams = new URLSearchParams();

    for (const key in query) {
        searchParams.append(key, query[key] as string);
    }

    return searchParams;
};

export const urlSearchParamsToQueryString = (urlSearchParams: URLSearchParams) => {
    const encodedQueryString = urlSearchParams.toString();
    return decodeURIComponent(encodedQueryString);
};

export const removeRedundantCommas = (queryString: string) => {
    return queryString.replace(/^,|,$|(?<=,),/g, "");
};
