import { useRef } from "react";
import { forwardRef, useDisclosure, UseDisclosureProps, useOutsideClick } from "@chakra-ui/react";
import { Button, ButtonProps } from "src/components/base/button";

export const useServiceButton = (props?: UseDisclosureProps) => {
    const ref = useRef<HTMLButtonElement>(null);
    const state = useDisclosure(props);
    useOutsideClick({
        ref,
        handler: state.onClose,
    });

    return {
        ref,
        state,
    };
};

export const ServiceButton = forwardRef((props: ButtonProps, ref) => (
    <Button ref={ref} boxShadow="md" rounded="full" p={2} _disabled={{ bg: "gray.800" }} {...props} />
));
