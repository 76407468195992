import { IconProps } from "@chakra-ui/react";
import { IconAustria, IconGermany, IconInternational, IconSwitzerland } from "src/components/icons";
import { EnumCountryKey } from "src/types/common-enum";

interface ICountryFlagProps extends Omit<IconProps, "css" | "children"> {
    countryKey: EnumCountryKey;
}

export const CountryFlag = ({ countryKey, ...iconProps }: ICountryFlagProps) => {
    switch (countryKey) {
        case EnumCountryKey.DE:
            return <IconGermany {...iconProps} />;
        case EnumCountryKey.AT:
            return <IconAustria {...iconProps} />;
        case EnumCountryKey.CH:
            return <IconSwitzerland {...iconProps} />;
        case EnumCountryKey.INT:
            return <IconInternational {...iconProps} />;
        default:
            return null;
    }
};
