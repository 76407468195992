import { SystemStyleObject, useStyleConfig } from "@chakra-ui/react";
import { ISimpleRichTextFontSize } from "src/components/common/rich-text/simple-rich-text";

const svgCheckMark = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='14' height='14'><path fill='rgb(51, 51, 51)' fill-rule='nonzero' d='m12.247 3.3415c0.36368-0.41564 0.99544-0.45775 1.4111-0.094072 0.38366 0.33571 0.44906 0.89984 0.17081 1.3115l-0.076738 0.099532-7 8c-0.35371 0.40424-0.95645 0.45235-1.3682 0.12966l-0.09144-0.081055-3-3c-0.39052-0.39052-0.39052-1.0237 0-1.4142 0.36048-0.36048 0.92772-0.38821 1.32-0.083189l0.094207 0.083189 2.2439 2.2431 6.2964-7.1945z'></path></svg>`;

const svgAvatar = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='3 0 36 30' width='20' height='18'><g transform='translate(4 0)'><circle cx='14' cy='14' r='14' fill='rgb(51, 51, 51)'></circle><circle cx='14' cy='10' r='5' fill='white'></circle><path d='m23.001 22c-2.1534-3.4608-4.9513-5-9.0012-5-4.0109 0-6.8403 1.5954-9 5 3 2.6667 6 4.0001 9 4s6.0004-1.3334 9.0012-4z' fill='white'></path></g></svg>`;
const svgUnderlying = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 10' width='24' height='13'><path fill='rgb(51, 51, 51)' transform='translate(1 -3)' fill-rule='nonzero' d='m13.303 3.283c0.39599-0.38499 1.0291-0.37607 1.4141 0.019917 0.35537 0.36552 0.37511 0.93309 0.064591 1.321l-0.084507 0.093026-4.8 4.6667c-0.35827 0.34831-0.91233 0.37511-1.3007 0.08038l-0.093436-0.08038-1.7029-1.6557-4.1029 3.989c-0.36552 0.35537-0.93309 0.37511-1.321 0.064591l-0.093026-0.084507c-0.35537-0.36552-0.37511-0.93309-0.064591-1.321l0.084507-0.093026 4.8-4.6667c0.35827-0.34831 0.91233-0.37511 1.3007-0.08038l0.093436 0.08038 1.7029 1.6547 4.1029-3.988z' /></svg>`;
const svgWikifolio = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 12' width='16' height='14'><g fill='rgb(51, 51, 51)' transform='translate(2 0)'><polygon points='10.921 0 8.8514 8.9793 7.5153 3.2863 4.8765 3.2863 3.5401 8.9793 2.8857 5.8771 0 5.8771 1.7471 12 4.9272 12 6.1958 7.0704 7.4643 12 10.645 12 14 0'></polygon></g></svg>`;

const getContent = (svg: string) => `url("data:image/svg+xml;utf8,${svg}")`;

const getIconRule = (svgString: string): SystemStyleObject => {
    return {
        whiteSpace: "nowrap",
        span: {
            whiteSpace: "normal",
        },
        _before: {
            content: getContent(svgString),
            display: "inline-flex",
            alignSelf: "center",
            boxSize: "16px",
            mr: 0.5,
            verticalAlign: "middle",
        },
    };
};

interface IStyles {
    linkGray: SystemStyleObject;
    fontSize?: ISimpleRichTextFontSize;
}
const getSx = ({ linkGray }: IStyles): SystemStyleObject => ({
    p: {
        mb: 3,
        _last: {
            mb: 0,
        },
    },
    ul: {
        ml: [3, 5],
    },
    "ul.checkmark": {
        listStyle: `url("data:image/svg+xml;utf8,${svgCheckMark}")`,
    },
    ol: {
        ml: [3, 5],
        counterReset: "customlistcounter",
        li: {
            counterIncrement: "customlistcounter",
            "::marker": {
                content: 'counter(customlistcounter) "  "',
                fontWeight: "bold",
            },
        },
    },
    li: {
        my: 2,
    },
    'a[class^="icon"]': {
        display: "inline-flex",
        alignItems: "baseline",
        lineHeight: "1",
    },
    "a:not(.button, .arrow-link)": linkGray,
    ".icon-profile": getIconRule(svgAvatar),
    ".icon-underlying": getIconRule(svgUnderlying),
    ".icon-wf": getIconRule(svgWikifolio),
});
export interface ISimpleRichTextProps {
    text: string;
}
export const useSimpleRichText = (fontSize?: ISimpleRichTextFontSize) => {
    const linkGray = useStyleConfig("Link", { variant: "underline", colorScheme: "gray" });

    return getSx({ linkGray, fontSize });
};
