import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconCaret: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path
            d="m5.2929 8.2929c0.36048-0.36048 0.92772-0.38821 1.32-0.083189l0.094207 0.083189 5.2929 5.2921 5.2929-5.2921c0.36048-0.36048 0.92772-0.38821 1.32-0.083189l0.094207 0.083189c0.36048 0.36048 0.38821 0.92772 0.083189 1.32l-0.083189 0.094207-6 6c-0.36048 0.36048-0.92772 0.38821-1.32 0.083189l-0.094207-0.083189-6-6c-0.39052-0.39052-0.39052-1.0237 0-1.4142z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </Icon>
);
