import {
    // eslint-disable-next-line no-restricted-imports
    Link,
    LinkProps,
    forwardRef,
} from "@chakra-ui/react";
import NextLink from "next/link";

export interface IHashLinkProps extends LinkProps {
    href: string;
}

export interface IHashLinkUnstyledProps extends Omit<IHashLinkProps, "variant"> {}

// Dev-Note: this component should be used when links only change the url hash (like #login, #register)
// we have a seperate component because the WfLink doesn't use NextLink at the moment. WfLink and this can therefore maybe merged in the future

export const HashLink = forwardRef<IHashLinkProps, "a">(({ children, href, ...linkProps }, ref) => {
    return (
        <NextLink href={href} passHref legacyBehavior>
            <Link ref={ref} {...linkProps}>
                {children}
            </Link>
        </NextLink>
    );
});

export const HashLinkUnstyled = forwardRef<IHashLinkUnstyledProps, "a">(({ children, href, ...linkProps }, ref) => {
    return (
        <NextLink href={href} passHref legacyBehavior>
            <Link ref={ref} {...linkProps} variant="unstyled">
                {children}
            </Link>
        </NextLink>
    );
});
