import { API_AUTH_MODAL_URL } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";

export interface IAuthModalResponse {
    forgotPasswordLink: string;
    agbLabel: string;
    agbHint: string;
    newsletterLabel: string;
    newsletterHint: string;
}

export function useAuthModalQuery(isAuthModalOpen: boolean) {
    return useQuery<IAuthModalResponse>(API_AUTH_MODAL_URL, {
        enabled: isAuthModalOpen,
        // Dev-Note: once the auth modal is opened, we don't want to refetch it again and therefore store it in the cache forever
        cacheTime: Infinity,
        staleTime: Infinity,
    });
}
