import { StyleFunctionProps } from "@chakra-ui/theme-tools";

interface IColor {
    bg: string;
    color?: string;
    borderColor?: string;
}
interface IBtnColor extends IColor {
    _hover: IColor;
    _active: IColor;
    _disabled: IColor;
}

/**
 * This is the place to add new variant of the button, please consult the UI design library before adding new styles.
 * https://www.figma.com/file/zzvO7fdaTfLvgTSrb6ypCs/wikifolio-UI-Library?node-id=0%3A847
 */

const baseStyle = {
    // DN: Following rules are somehow defined in the button component itself
    // https://github.com/chakra-ui/chakra-ui/blob/9244866b14970ba1b384e991c23af7acac7635e4/packages/button/src/button.tsx#L106
    display: "inline-flex",
    appearance: "none",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 250ms",
    userSelect: "none",
    position: "relative",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    outline: "none",
    // end
    lineHeight: "1.2",
    borderRadius: "8px",
    fontWeight: "semibold",
    textDecor: "none",
    _hover: {
        textDecor: "none",
    },
    _focusVisible: {
        boxShadow: "focusVisible",
    },
    _disabled: {
        cursor: "not-allowed",
        boxShadow: "none",
    },
};

const ghostColorMap: { [key: string]: IBtnColor } = {
    green: {
        bg: "transparent",
        color: "green.600",
        _hover: {
            bg: "green.100",
        },
        _active: {
            bg: "green.200",
        },
        _disabled: {
            bg: "transparent",
            color: "green.300",
        },
    },
    lilac: {
        bg: "transparent",
        color: "lilac.600",
        _hover: {
            bg: "lilac.50",
        },
        _active: {
            bg: "lilac.200",
        },
        _disabled: {
            bg: "transparent",
            color: "lilac.300",
        },
    },
    gray: {
        bg: "transparent",
        color: "gray.800",
        _hover: {
            bg: "gray.50",
        },
        _active: {
            bg: "gray.100",
        },
        _disabled: {
            bg: "transparent",
            color: "gray.200",
        },
    },
    whiteAlpha: {
        bg: "transparent",
        color: "white",
        _hover: {
            bg: "whiteAlpha.400",
        },
        _active: {
            bg: "whiteAlpha.600",
        },
        _disabled: {
            bg: "transparent",
            color: "whiteAlpha.600",
        },
    },
    blue: {
        bg: "transparent",
        color: "blue.600",
        _hover: {
            bg: "blue.50",
        },
        _active: {
            bg: "blue.200",
        },
        _disabled: {
            bg: "transparent",
            color: "blue.300",
        },
    },
    orange: {
        bg: "transparent",
        color: "orange.600",
        _hover: {
            bg: "orange.50",
        },
        _active: {
            bg: "orange.200",
        },
        _disabled: {
            bg: "transparent",
            color: "orange.300",
        },
    },
};

function variantGhost({ colorScheme }: Pick<StyleFunctionProps, "colorScheme">) {
    const { bg, color, _hover, _active, _disabled } = ghostColorMap[colorScheme] || {};

    return {
        bg,
        color,
        _hover,
        _active,
        _focus: _active,
        _disabled: {
            ..._disabled,
            _hover: _disabled,
        },
    };
}

function variantOutline(props: Pick<StyleFunctionProps, "colorScheme">) {
    return {
        border: "1px solid currentColor",
        ...variantGhost(props),
    };
}

function variantGhostUnderline(props: StyleFunctionProps) {
    return {
        textDecoration: "underline",
        textUnderlineOffset: "3.36px",
        ...variantGhost(props),

        _hover: {
            ...variantGhost(props)._hover,
            textDecoration: "underline",
        },
    };
}

const solidColorMap: { [key: string]: IBtnColor } = {
    green: {
        bg: "green.600",
        color: "white",
        _hover: {
            bg: "green.500",
        },
        _active: {
            bg: "green.700",
        },
        _disabled: {
            bg: "green.100",
            color: "green.300",
        },
    },
    gray: {
        bg: "gray.800",
        color: "white",
        _hover: {
            bg: "gray.600",
        },
        _active: {
            bg: "gray.900",
        },
        _disabled: {
            bg: "gray.50",
            color: "gray.200",
        },
    },
    whiteAlpha: {
        bg: "white",
        color: "gray.800",
        _hover: {
            bg: "whiteAlpha.800",
        },
        _active: {
            bg: "whiteAlpha.700",
        },
        _disabled: {
            bg: "transparent",
            color: "whiteAlpha.400",
        },
    },
    blue: {
        bg: "blue.600",
        color: "white",
        _hover: {
            bg: "blue.500",
        },
        _active: {
            bg: "blue.700",
        },
        _disabled: {
            bg: "blue.100",
            color: "blue.300",
        },
    },
    lilac: {
        bg: "lilac.600",
        color: "white",
        _hover: {
            bg: "lilac.500",
        },
        _active: {
            bg: "lilac.700",
        },
        _disabled: {
            bg: "lilac.100",
            color: "lilac.300",
        },
    },
    orange: {
        bg: "orange.600",
        color: "white",
        _hover: {
            bg: "orange.500",
        },
        _active: {
            bg: "orange.700",
        },
        _disabled: {
            bg: "orange.100",
            color: "orange.300",
        },
    },
};

function variantSolid({ colorScheme }: Pick<StyleFunctionProps, "colorScheme">) {
    const { bg, color, _hover, _active, _disabled } = solidColorMap[colorScheme] || {};

    return {
        bg,
        color,
        _hover,
        _active,
        _focus: _active,
        _disabled: {
            ..._disabled,
            _hover: _disabled,
        },
    };
}

const variantUnstyled = {
    bg: "none",
    color: "inherit",
    display: "inline",
    lineHeight: "inherit",
    m: 0,
    p: 0,
};

const variantUnderline = {
    bg: "none",
    color: "inherit",
    display: "inline",
    lineHeight: "inherit",
    fontSize: "inherit",
    m: 0,
    p: 0,
    borderRadius: 0,
    borderBottom: "1px solid currentColor",
};

const variants = {
    ghost: variantGhost,
    "ghost-underline": variantGhostUnderline,
    outline: variantOutline,
    solid: variantSolid,
    underline: variantUnderline,
    unstyled: variantUnstyled,
};

export interface IButtonSizeProps {
    // Dev-Note: We have a custom Button wrapper component
    // which relies on those props in order to render CSS responsive button
    // In case you have to justify this interface make sure that the "Responsive Button" story works properly!
    h: number;
    minW: number;
    fontSize: string;
    px: number;
}

const sizes: Record<string, IButtonSizeProps> = {
    lg: {
        h: 7,
        minW: 7,
        fontSize: "md",
        px: 4,
    },
    md: {
        h: 6,
        minW: 6,
        fontSize: "md",
        px: 3,
    },
    sm: {
        h: 5,
        minW: 5,
        fontSize: "sm",
        px: 2,
    },
    xs: {
        h: 4,
        minW: 4,
        fontSize: "sm",
        px: 2,
    },
};

const defaultProps = {
    variant: "solid",
    size: "md",
    colorScheme: "gray",
};

export default {
    baseStyle,
    variants,
    sizes,
    defaultProps,
};
