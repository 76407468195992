const PADDING = [3, 5];
const parts = ["overlay", "dialogContainer", "dialog", "header", "closeButton", "body", "footer"];

const baseStyleOverlay = {
    bg: "blackAlpha.500",
    zIndex: "modal",
};

type Dict = Record<string, unknown>;

function baseStyleDialogContainer(props: Dict) {
    const { isCentered, scrollBehavior } = props;

    return {
        display: "flex",
        zIndex: "modal",
        justifyContent: "center",
        alignItems: isCentered ? "center" : "flex-start",
        overflow: scrollBehavior === "inside" ? "hidden" : "auto",
    };
}

const BORDER_RADIUS = [0, "8px"];

function baseStyleDialog(props: Dict) {
    const { scrollBehavior } = props;

    return {
        borderRadius: BORDER_RADIUS,
        bg: "white",
        color: "inherit",
        my: [0, "3.75rem"],
        zIndex: "modal",
        maxH: scrollBehavior === "inside" ? "calc(100% - 7.5rem)" : undefined,
        boxShadow: "lg",
        minH: ["100%", "auto"],
    };
}

const baseStyleHeader = {
    pt: [9, 5],
    pb: 3,
    px: PADDING,
    fontSize: ["xl", "2xl"],
    fontWeight: "bold",
};

const baseStyleCloseButton = {
    position: "absolute",
    top: [3, 5],
    insetEnd: [3, 5],
    boxSize: 5,
};

function baseStyleBody(props: Dict) {
    const { scrollBehavior } = props;
    return {
        px: PADDING,
        py: 0,
        flex: 1,
        overflow: scrollBehavior === "inside" ? "auto" : undefined,
    };
}

const baseStyleFooter = {
    p: PADDING,
    borderBottomRadius: BORDER_RADIUS,
};

const baseStyle = (props: Dict) => ({
    overlay: baseStyleOverlay,
    dialogContainer: baseStyleDialogContainer(props),
    dialog: baseStyleDialog(props),
    header: baseStyleHeader,
    closeButton: baseStyleCloseButton,
    body: baseStyleBody(props),
    footer: baseStyleFooter,
});

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string) {
    if (value === "full") {
        return { dialog: { maxW: "100vw", minH: "100vh" } };
    }
    return { dialog: { maxW: value } };
}

const sizes = {
    xs: getSize("xs"),
    sm: getSize("sm"),
    md: getSize("md"),
    lg: getSize("lg"),
    xl: getSize("xl"),
    "2xl": getSize("2xl"),
    "3xl": getSize("3xl"),
    "4xl": getSize("4xl"),
    "5xl": getSize("5xl"),
    "6xl": getSize("6xl"),
    full: getSize("full"),
};

const defaultProps = {
    size: "md",
};

export default {
    parts,
    baseStyle,
    sizes,
    defaultProps,
};
