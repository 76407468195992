import { SystemProps } from "@chakra-ui/react";

const parts = ["container", "button", "panel", "icon"];

const baseStyleContainer: SystemProps = {
    borderBottomWidth: "1px",
    borderColor: "gray.200",
};

const baseStyleButton: SystemProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    textAlign: "left",
    fontWeight: "semibold",
    _focusVisible: {
        boxShadow: "focusVisible",
    },
    _hover: {
        color: "gray.600",
    },
    _active: {
        color: "gray.900",
    },
};

const baseStylePanel: SystemProps = {
    pt: 0.5,
    pb: 2,
};

const baseStyleIcon: SystemProps = {
    boxSize: 3,
    ml: 1,
};

const baseStyle = {
    container: baseStyleContainer,
    button: baseStyleButton,
    panel: baseStylePanel,
    icon: baseStyleIcon,
};

const sizes = {
    sm: {
        button: { py: 2 },
    },
    md: {
        button: { py: 3 },
    },
    lg: {
        button: { fontSize: "lg", py: 3 },
    },
};

const variants = {
    compact: {
        button: { my: [0.5, 0], py: 1, px: 2, alignItems: "center" },
        panel: { pr: 2, pb: 2, pl: [2, 3], pt: 0 },
        container: { borderColor: "gray.100" },
    },
};

const defaultProps = {
    size: "md",
};

export default {
    parts,
    baseStyle,
    sizes,
    variants,
    defaultProps,
};
