import {
    Path,
    // eslint-disable-next-line no-restricted-imports
    useForm as _useForm,
    UseFormProps,
    FieldValues,
} from "react-hook-form";

export const useForm = <IFormValues extends FieldValues>(props: UseFormProps<IFormValues>) => {
    const form = _useForm<IFormValues>(props);

    return {
        ...form,
        isFieldInvalid: (fieldName: Path<IFormValues>) => form.getFieldState(fieldName).invalid,
    };
};
