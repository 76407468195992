import smoothScroll from "smoothscroll-polyfill";
import { isServerSide } from "./common-util";
import { interceptBrokenNextDataLinks } from "./intercept-broken-next-data-links";
import { setupStringFormat } from "./string-util";

export const initAppHelperUtils = () => {
    if (!isServerSide()) {
        smoothScroll.polyfill();
        interceptBrokenNextDataLinks();
    }
    setupStringFormat();
};
