import { Box, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";
import { HashLink } from "src/components/base/hash-link";
import { ThirdPartyAuthButtons, EnumPlacement } from "src/components/common/auth";
import { getGlobals } from "src/consts/globals";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import { REGISTER_OVERVIEW_BUTTONS_TEST_ID } from "./register-overview.test-ids";

export const RegisterOverview = () => {
    const { t } = useTranslation("common");
    const { buildLoginModalLink } = useLinkBuilder();
    const { showEmailRegisterForm, setShowEmailRegisterForm } = useAuthModalStore();
    const loginProviders = getGlobals().loginProviders;

    if (showEmailRegisterForm) {
        return null;
    }

    return (
        <>
            <Stack spacing={2} px={[0, 4, 5]} pb={[4, 5]} data-test-id={REGISTER_OVERVIEW_BUTTONS_TEST_ID}>
                <Button variant="outline" onClick={() => setShowEmailRegisterForm(true)} className="gtm-register" data-gtm-action="register">
                    {t("register-with-email")}
                </Button>

                {loginProviders?.length && <ThirdPartyAuthButtons placement={EnumPlacement.RegisterModal} loginProviders={loginProviders} />}
            </Stack>
            <Box textAlign="center" fontSize="sm">
                <Text mb={1}>{t("already-registered")}</Text>
                <HashLink href={buildLoginModalLink()}>{t("go-to-login")}</HashLink>
            </Box>
        </>
    );
};
