import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconFacebook: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" color="#1877f2" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M13.581 12.003V10.1277C13.581 9.33709 13.96808 8.5665 15.2093 8.5665h1.2599V6.1062C16.4692 6.1062 16.4643965 6.10537972 16.455114 6.1038455 16.4530508 6.10301387 15.308614 5.9076455 14.214614 5.9076455 11.930414 5.9076455 10.437514 7.2924455 10.437514 9.7993455V12.0030455H7.898414V14.8944455H10.437514V21.8842455C5.656914 21.1338455 2.000014 16.9956455 2.000014 12.0030455c0-5.524400000000001 4.4771-10.003 10-10.003 5.5229.0 10 4.478400000000001 10 10.003C22.000014 16.9957455 18.343114 21.1338455 13.562514 21.8842455 13.5685093 21.8807938 13.574804 21.8798118 13.581096 21.878824L13.5810139 14.894324 15.8926139 14.8944861 15.8920358 14.892324 15.9092348 14.892877 16.3522248 12.003077H13.5811248L13.581 12.003z"
            />
        </g>
    </Icon>
);
