import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconArrowRight: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path d="M19 12H4M16 18l4-6-4-6" />
            </g>
        </g>
    </Icon>
);
