import { ISearchRequestParams } from "src/api/client/extended-search.api";

export type ISearchRequestParamsOmitCountry = Omit<ISearchRequestParams, "country">;
export interface ISearch {
    onSearch: (params: ISearchRequestParamsOmitCountry) => void;
}

export enum EnumSearchQueryParams {
    SearchTerm = "searchTerm",
    SortDirection = "sortOrder",
    SortByRanking = "sortBy",
    IsInvestable = "investable",
    IsAtInvestable = "austrianLicenseOnly",
    IsChInvestable = "swissLicenseOnly",
    WikifoliosWithLeverageProducts = "leverageProductsOnly",
    WikifoliosWithoutLeverageProducts = "withoutLeverageProductsOnly",
    Tags = "tags",
    BrokerIds = "brokers",
}
