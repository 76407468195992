interface IConfig {
    isDev: boolean;
    isProduction: boolean;
    isApplicationInsightsEnabled: boolean;
    applicationInsightsKey: string;
    searchAutoCompleteEndpoint: string;
    azureCdnEndpoint: string;
    publicCloudEnvShortName: string;
    chartImagePreviewUrl: string;
}

const evaluateVariable = (value: string | undefined): boolean => value === "true";

const config: IConfig = {
    isDev: process.env.NODE_ENV === "development",
    isProduction: process.env.NODE_ENV === "production",
    isApplicationInsightsEnabled: evaluateVariable(process.env.NEXT_PUBLIC_APPLICATION_INSIGHTS_ENABLED),
    applicationInsightsKey: process.env.NEXT_PUBLIC_APPLICATION_INSIGHTS_KEY ?? "",
    searchAutoCompleteEndpoint: process.env.NEXT_PUBLIC_SEARCH_AUTO_COMPLETE_ENDPOINT ?? "",
    azureCdnEndpoint: process.env.NEXT_PUBLIC_AZURE_CDN_ENDPOINT ?? "",
    publicCloudEnvShortName: process.env.NEXT_PUBLIC_CLOUD_BLOB_ENVIRONMENT_SHORTNAME ?? "",
    chartImagePreviewUrl: process.env.NEXT_PUBLIC_CHART_IMAGE_PREVIEW_URL ?? "",
};

export default config;
