import { StyleFunctionProps } from "@chakra-ui/theme-tools";

interface IColor {
    color: string;
    hoverColor: string;
    activeColor: string;
}

const baseStyle = {
    transition: `all 0.15s ease-out`,
    cursor: "pointer",
    outline: "none",
    color: "inherit",
    fontWeight: "bold",
    textUnderlineOffset: "0.24em",
    _hover: {
        textDecoration: "underline",
    },
    _focusVisible: {
        boxShadow: "focusVisible",
    },
};

const sizes = {
    sm: {
        fontSize: "sm",
    },
    md: {
        fontSize: "md",
    },
    lg: {
        fontSize: "lg",
    },
    xl: {
        fontSize: "xl",
    },
};

function variantGhost(props: StyleFunctionProps) {
    const { colorScheme: c } = props;
    const colorMap: { [key: string]: IColor } = {
        green: {
            color: "green.600",
            hoverColor: "green.600",
            activeColor: "green.800",
        },
        gray: {
            color: "gray.800",
            hoverColor: "gray.800",
            activeColor: "gray.900",
        },
    };

    const { color, hoverColor, activeColor } = colorMap[c] || {};

    return {
        color,
        _hover: { color: hoverColor },
        _active: { color: activeColor },
    };
}

function variantUnderline(props: StyleFunctionProps) {
    const { colorScheme: c } = props;
    const colorMap: { [key: string]: IColor } = {
        green: {
            color: "green.600",
            hoverColor: "green.400",
            activeColor: "green.800",
        },
        gray: {
            color: "gray.800",
            hoverColor: "gray.600",
            activeColor: "gray.900",
        },
    };

    const { color, hoverColor, activeColor } = colorMap[c] || {};

    return {
        color,
        textDecoration: "underline",
        _hover: { color: hoverColor },
        _active: { color: activeColor },
    };
}

const variantUnstyled = {
    fontWeight: "normal",
    _hover: {
        textDecor: "none",
    },
};

const variants = {
    underline: variantUnderline,
    ghost: variantGhost,
    unstyled: variantUnstyled,
};

const defaultProps = {
    colorScheme: "gray",
    variant: "underline",
};

export default {
    baseStyle,
    sizes,
    variants,
    defaultProps,
};
