import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconInternational: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g transform="translate(2 3)" fill="#88C6EB">
            <path d="m19.094 18h-18.187c-0.50091 0-0.90636-0.446-0.90636-0.997v-16.006c0-0.551 0.40545-0.997 0.90636-0.997h18.187c0.50091 0 0.90636 0.446 0.90636 0.997v16.006c0 0.551-0.40545 0.997-0.90636 0.997" />
            <path
                d="m16 9c0 3.313-2.687 6-6 6s-6-2.687-6-6 2.687-6 6-6 6 2.687 6 6z"
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                d="m10 3c-0.507 0.676-2 2.845-2 6 0 0.845 0.107 3.476 2 6m0-12c0.507 0.676 2 2.845 2 6 0 0.845-0.107 3.476-2 6"
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <line x1="4" x2="16" y1="9" y2="9" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
    </Icon>
);
