const SECONDS_FOR_HUNDRED_PERCENT = 2;

type Dict = Record<string, unknown>;

export default {
    baseStyle: (props: Dict) => {
        const time = typeof props.value === "number" ? SECONDS_FOR_HUNDRED_PERCENT * (props.value / 100) : 0;
        return {
            filledTrack: {
                bg: "green.200",
                transition: `width ${time}s linear`,
            },
        };
    },
};
