import { Box, BoxProps } from "@chakra-ui/react";

export const CONTAINER_PX = [3, 5, 3, 3];
export const CONTAINER_PX_NEGATIVE = CONTAINER_PX.map(value => -value);
export const CONTAINER_PX_XS_SM = [CONTAINER_PX[0], CONTAINER_PX[1]];
export const CONTAINER_PX_XS_SM_NEGATIVE = [-CONTAINER_PX[0], -CONTAINER_PX[1]];
export const CONTAINER_PX_XS_SM_MD = [CONTAINER_PX[0], CONTAINER_PX[1], CONTAINER_PX[2]];
export const CONTAINER_PX_XS_SM_MD_NEGATIVE = [-CONTAINER_PX[0], -CONTAINER_PX[1], -CONTAINER_PX[2]];
export const CONTAINER_PX_SM_MD = [CONTAINER_PX[1], CONTAINER_PX[2]];
export const CONTAINER_PX_SM_MD_NEGATIVE = [-CONTAINER_PX[1], -CONTAINER_PX[2]];
export const CONTAINER_PX_SM_MD_LG = [CONTAINER_PX[1], CONTAINER_PX[2], CONTAINER_PX[3]];
export const CONTAINER_W = ["100%", "750px", "970px", "1170px"];

export interface IContainerProps extends Omit<BoxProps, "px"> {
    /** If you want to override ONLY large padding X -> pass `[null, null, null, lg-px]` */
    px?: (number | null)[];
}

/**
 * The container component is an equivalent for `.container` and is used solution wide!
 */
export const Container = ({ children, px, ...boxProps }: IContainerProps) => {
    const _px = px ? CONTAINER_PX.map((cPx, i) => px[i] ?? cPx) : CONTAINER_PX;

    return (
        <Box w={CONTAINER_W} px={_px} mx="auto" {...boxProps}>
            {children}
        </Box>
    );
};

export const ContainerHeader = ({ children, ...boxProps }: BoxProps) => (
    <Box maxW="1920px" w="100%" mx="auto" {...boxProps}>
        {children}
    </Box>
);

export const ContainerBig = ({ children, ...boxProps }: BoxProps) => (
    <Box maxW="1440px" w="100%" mx="auto" {...boxProps}>
        {children}
    </Box>
);

export const ContainerSmall = ({ children, ...boxProps }: BoxProps) => (
    <Box px={[3, 0]} w={["100%", "520px", "620px"]} mx="auto" {...boxProps}>
        {children}
    </Box>
);

export const ContainerWallOfText = ({ children, ...boxProps }: BoxProps) => (
    <Box px={[3, 0]} w={["100%", "571px", "620px", "749px"]} mx="auto" {...boxProps}>
        {children}
    </Box>
);
