export enum EnumSecurityType {
    Bond = 610,
    Stock = 620,
    ETF = 630,
    Derivatives = 640,
    Wikifolios = 650,
}

export enum EnumIssuerType {
    IssuerNull = 0,
    LUS = 1900,
    HSBC = 1910,
    SocGen = 1920,
    UBS = 1940,
    Vontobel = 1950,
}

export enum EnumWfActionType {
    CopyIsin = "Copy ISIN",
    Watchlist = "Add to watchlist",
    GoToWf = "Go to wikifolio",
    Invest = "Invest now",
}

export enum EnumWikifolioStatus {
    Test = 110,
    PublishingRequested = 120,
    Published = 130,
    EmissionProcessStarted = 135,
    Investable = 140,
    Barred = 150,
    ClosingInProgress = 155,
    Closed = 160,
    SEO = 170,
    IssuingRequested = 175,
    ReadyForIssuing = 180,
}

export enum EnumUserStatus {
    Unverified = 410,
    Verified = 420,
    Barred = 430,
    TerminatedByUser = 440,
    TerminatedByWikifolio = 450,
    DeletionRequested = 460,
    Deleted = 470,
}

export enum EnumFeedContentType {
    Comment = 100,
    WikifolioTag = 200,
    Wikifolio52WeekHigh = 201,
    WikifolioPortfolioChanges = 202,
    WikifolioTakingProfits = 203,
    MagazineArticle = 300,
    Recommendation = 400,
    BuyingTheDip = 500,
    FearOfMissingOut = 501,
    TakingProfits = 502,
    JumpingShip = 503,
    TopBuySentiment = 510,
    TopSellSentiment = 511,
    Voting = 99999,
}

export enum EnumDownloadType {
    Portfolio = 0,
    PriceData = 1,
    AccountStatement = 2,
    InflowOutflow = 3,
}

export enum EnumCountryKey {
    DE = "de",
    AT = "at",
    CH = "ch",
    INT = "int",
}

export enum EnumTagColor {
    Gray = "gray",
    Lilac = "lilac",
    Green = "green",
    Blue = "blue",
    Orange = "orange",
}

export enum EnumReactionColor {
    Blue = "blue",
    Yellow = "yellow",
    Red = "red",
    Green = "green",
    Grey = "grey",
}

export enum EnumReactionType {
    ArticleUserPricePredictionUnderlying = "articleUserPricePredictionUnderlying",
    ArticleUserMarketOverReaction = "articleUserMarketOverreaction",
    ArticleUserSurprise = "articleUserSurprise",
}

export enum EnumReactionEntityType {
    ExternalNewsArticle = "ExternalNewsArticle",
}

export enum EnumMouseButtons {
    Main, // Left
    Auxiliary, // Middle
    Secondary, // Right
    Fourth, // Back
    Fifth, // Forward
}

export enum EnumUserSegment {
    Beginner = 4510,
    Investor = 4520,
    NewsJunkie = 4530,
    Trader = 4540,
    Undetermined = 4550,
    NotAutoClassified = 4560,
    Investor01 = 4570,
    Investor02 = 4571,
    Investor03 = 4572,
    Investor04 = 4573,
    Investor05 = 4574,
    Trader01 = 4580,
    Trader02 = 4581,
    Trader03 = 4582,
    Trader04 = 4583,
}

export enum EnumHorizontalAlignment {
    None = 0,
    Left = 1,
    Center = 2,
    Right = 3,
}
