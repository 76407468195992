// These are global constants we retrieve from the backend on initial page load

import { IGlobal } from "src/types/layout";

let globals: IGlobal | undefined;

export function initGlobals(value: IGlobal | null) {
    if (value) {
        globals = value;
    }
}

export function unsetGlobals() {
    globals = undefined;
}

export function getGlobals(): IGlobal {
    return globals!;
}
