import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconApple: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M18.5474 21.1946C17.4626 22.2474 16.2657 22.0832 15.1248 21.5869C13.9119 21.0811 12.803 21.0478 11.5221 21.5869C9.92618 22.2754 9.0802 22.0752 8.11947 21.1946C2.69535 15.6103 3.49596 7.10384 9.66065 6.78359C11.1565 6.86366 12.2026 7.60956 13.0833 7.67227C14.3922 7.4054 15.6452 6.64349 17.0463 6.74356C18.7302 6.87967 19.9885 7.54417 20.8291 8.73975C17.3665 10.82 18.1871 15.3835 21.3669 16.6658C20.7317 18.3364 19.9151 19.9883 18.5447 21.2092L18.5474 21.1946ZM12.9632 6.72355C12.8017 4.24166 14.8126 2.20011 17.1263 1.99995C17.4452 4.86213 14.5243 7.00376 12.9632 6.72355Z"
                fill="#333333"
            />
        </g>
    </Icon>
);
