const defaultColor = "gray.800";

const colorMap = new Map([
    [0, defaultColor],
    [1, "green.600"],
    [2, "sky.600"],
    [3, "teal.600"],
    [4, "lilac.600"],
    [5, "orange.600"],
    [6, "blush.600"],
    [7, "blue.600"],
]);

export const getAvatarColor = (id: string) => {
    const unicodeSum = id.split("").reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
    return colorMap.get(unicodeSum % colorMap.size) || defaultColor;
};
