import { HStack, VStack, Box } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { IconInfo } from "src/components/icons";
import { Banner } from "src/components/layout/banners/banner";
import { ILtsuBanner } from "src/types/banners";

const LTSU_BANNER_TEST_ID = "ltsu-banner";

interface ILtsuBannerProps extends ILtsuBanner {}

export const LtsuBanner = ({ noticeText, link }: ILtsuBannerProps) => {
    return (
        <Banner background="white" data-test-id={LTSU_BANNER_TEST_ID}>
            <HStack spacing={[1, 2]} alignItems="flex-start">
                <Box>
                    <IconInfo boxSize={4} fill="blush.200" color="blush.700" />
                </Box>
                <VStack spacing={2} w={["100%", "auto"]} alignItems="flex-start">
                    <SimpleRichText fontSize="sm" wordBreak="break-word" text={noticeText} />
                    <ArrowLink href={link.url || "#"} target="_blank" fontSize="sm" display="inline-flex" alignItems="center">
                        {link.name}
                    </ArrowLink>
                </VStack>
            </HStack>
        </Banner>
    );
};
