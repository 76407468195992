import { isDark, mode, randomColor, StyleFunctionProps } from "@chakra-ui/theme-tools";

const parts = ["container", "excessLabel", "badge", "label"];

function baseStyleBadge(props: StyleFunctionProps) {
    return {
        transform: "translate(25%, 25%)",
        borderRadius: "full",
        border: "0.2em solid",
        borderColor: mode("white", "gray.800")(props),
    };
}

function baseStyleExcessLabel(props: StyleFunctionProps) {
    return {
        bg: mode("gray.200", "whiteAlpha.400")(props),
    };
}

function baseStyleContainer(props: StyleFunctionProps) {
    const { name, theme } = props;
    const bg = name ? randomColor({ string: name }) : "gray.400";
    const isBgDark = isDark(bg)(theme);

    let color = "white";
    if (!isBgDark) color = "gray.800";

    const borderColor = mode("white", "gray.800")(props);

    return {
        bg,
        color,
        borderColor,
        verticalAlign: "top",
    };
}

const baseStyle = (props: StyleFunctionProps) => ({
    badge: baseStyleBadge(props),
    excessLabel: baseStyleExcessLabel(props),
    container: baseStyleContainer(props),
    image: {},
});

function getSize(size: string | number) {
    return {
        container: {
            width: size,
            height: size,
            fontSize: `calc(${size} / 2.5)`,
        },
        excessLabel: {
            width: size,
            height: size,
        },
        label: {
            fontSize: `calc(${size} / 2.5)`,
            lineHeight: size !== "100%" ? size : undefined,
        },
        image: {
            width: size,
            height: size,
        },
    };
}

export const avatarSizeMap = {
    xs: 4,
    sm: 5,
    md: 6,
    lg: 7,
    xl: 8,
    "2xl": 10,
};

const sizes = {
    ...Object.entries(avatarSizeMap).reduce((prevValue, [key, value]) => ({ ...prevValue, [key]: getSize(value) }), {}),
    full: getSize("100%"),
};

const variants = {
    grayOutline: {
        container: { borderWidth: "1px", borderColor: "gray.100", backgroundColor: "white" },
    },
};

const defaultProps = {
    size: "md",
};

export default {
    parts,
    baseStyle,
    sizes,
    variants,
    defaultProps,
};
