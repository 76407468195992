const parts = ["table", "thead", "tbody", "tr", "th", "td", "caption"];

const baseStyle = {
    table: {
        borderCollapse: "collapse",
        width: "full",
    },
    th: {
        fontWeight: "bold",
        textAlign: "right",
        _first: {
            textAlign: "left",
        },
    },
    td: {
        textAlign: "right",
        _first: {
            textAlign: "left",
        },
    },
};

const simpleVariant = {
    tbody: {
        borderBottom: "1px",
        borderColor: "gray.100",
    },
    th: {
        borderY: "2px",
        borderColor: "gray.100",
        p: 2,
        verticalAlign: "top",
        fontSize: "xs",
        _notFirst: {
            w: "1px",
        },
    },
    td: {
        borderTop: "1px",
        borderColor: "gray.100",
        px: 2,
        py: 1,
        h: 8,
        fontSize: "sm",
        _notFirst: {
            w: "1px",
        },
    },
};

const extendedVariant = {
    th: {
        pb: 2,
        pl: 2,
        fontSize: "sm",
        fontWeight: "normal",
        verticalAlign: "top",
    },
    td: {
        pr: "36px",
        pb: 2,
        pl: 2,
        fontSize: "sm",
        border: "none",
        verticalAlign: "top",
        _first: {
            textAlign: "right",
        },
    },
};

const variants = {
    simple: simpleVariant,
    extended: extendedVariant,
    unstyled: {},
};

const defaultProps = {
    variant: "simple",
};

export default {
    parts,
    baseStyle,
    variants,
    defaultProps,
};
