import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconClose: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <path
            d="m5.6129 4.2097 0.094207 0.083189 6.2929 6.2921 6.2929-6.2921c0.39052-0.39052 1.0237-0.39052 1.4142 0 0.36048 0.36048 0.38821 0.92772 0.083189 1.32l-0.083189 0.094207-6.2921 6.2929 6.2921 6.2929c0.39052 0.39052 0.39052 1.0237 0 1.4142-0.36048 0.36048-0.92772 0.38821-1.32 0.083189l-0.094207-0.083189-6.2929-6.2921-6.2929 6.2921c-0.39052 0.39052-1.0237 0.39052-1.4142 0-0.36048-0.36048-0.38821-0.92772-0.083189-1.32l0.083189-0.094207 6.2921-6.2929-6.2921-6.2929c-0.39052-0.39052-0.39052-1.0237 0-1.4142 0.36048-0.36048 0.92772-0.38821 1.32-0.083189z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </Icon>
);
