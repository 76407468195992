import { Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useResendDoubleOptInEmailMutation } from "src/api/client/resend-double-opt-in-email.api";
import { ArrowLink } from "src/components/base/arrow-link";
import { Button } from "src/components/base/button";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Banner } from "src/components/layout/banners/banner";
import { useBannerAction, EnumActionState } from "src/components/layout/banners/use-banner-action";
import { DOUBLE_OPT_IN_BANNER_TEST_ID } from "./double-opt-in-banner.test-ids";

interface IDoubleOptInBannerProps {
    emailSettingsPageUrl: string;
}

export const DoubleOptInBanner = ({ emailSettingsPageUrl }: IDoubleOptInBannerProps) => {
    const { t } = useTranslation("common");
    const resendDoubleOptInEmailMutation = useResendDoubleOptInEmailMutation();
    const { actionState, handleAction } = useBannerAction(resendDoubleOptInEmailMutation);

    return (
        <Banner data-test-id={DOUBLE_OPT_IN_BANNER_TEST_ID}>
            <Stack direction={["column", "row"]} spacing={[1, 3]} alignItems={["flex-start", "center"]} justifyContent="space-between">
                <SimpleRichText fontSize="sm" wordBreak="break-word" text={t("double-opt-in-description")} />
                <Stack
                    direction={["column", "row"]}
                    spacing={[1, 3]}
                    w={["100%", "auto"]}
                    alignItems="center"
                    justifyContent={["stretch", "space-between"]}
                >
                    <Button
                        isLoading={actionState === EnumActionState.SENDING}
                        isDisabled={actionState === EnumActionState.SENT}
                        onClick={handleAction}
                        w={["100%", "auto"]}
                        colorScheme="gray"
                        size="sm"
                        position="relative"
                        flexShrink={0}
                    >
                        {t("request-e-mail")}
                    </Button>
                    <ArrowLink href={emailSettingsPageUrl || "#"} target="_blank" fontSize="sm" display="inline-flex" alignItems="center">
                        {t("settings")}
                    </ArrowLink>
                </Stack>
            </Stack>
        </Banner>
    );
};
