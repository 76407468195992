import { useRouter } from "next/router";
import { IGlobalSearchResponse, ISearchUnderlying, ISearchWikifolio } from "src/api/client/search-autocomplete.api";
import { useLinkBuilder } from "src/hooks/use-link-builder";

export const useNavigateOnSelect = (results: IGlobalSearchResponse | null) => {
    const router = useRouter();
    const { buildUnderlyingLink, buildWikifolioLink } = useLinkBuilder();

    const handleSelect = (selectedItem: ISearchWikifolio | ISearchUnderlying, selectedIndex: number) => {
        const url =
            results?.wikifolios && results.wikifolios?.length > selectedIndex
                ? buildWikifolioLink(selectedItem.resourceIdentifier)
                : buildUnderlyingLink(selectedItem.resourceIdentifier);
        return router.push(url);
    };

    return { handleSelect };
};
