import { useEffect, useState } from "react";
import { useNewsTickerQuery } from "src/api/client/news-ticker.api";

// 15 minutes
const FETCH_INTERVAL = 15 * 60 * 1000;
export const useNewsTicker = () => {
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [hasShownAllItems, setHasShownAllItems] = useState(false);

    const { data: newsReactions, refetch } = useNewsTickerQuery();

    useEffect(() => {
        const interval = setInterval(() => setShouldRefetch(true), FETCH_INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [setShouldRefetch]);

    useEffect(() => {
        if (shouldRefetch && hasShownAllItems) {
            setHasShownAllItems(false);
            setShouldRefetch(false);
            refetch();
        }
    }, [refetch, shouldRefetch, hasShownAllItems]);

    return {
        newsReactions,
        setHasShownAllItems,
    };
};
