import { UseMutationOptions } from "react-query";
import { API_ACCEPT_DISCLAIMER_MUTATION } from "src/consts/urls";
import { useMutation } from "../../hooks/react-query/use-mutation";

export interface IAcceptDisclaimerResponse {
    newUrl: string;
}

export interface IAcceptDisclaimerRequest {
    country: string;
    url: string;
    storeCookie?: boolean;
}

interface IError {
    [fieldName: string]: string;
}

export const useAcceptDisclaimerMutation = (options: UseMutationOptions<IAcceptDisclaimerResponse, IError, IAcceptDisclaimerRequest>) => {
    const { mutate } = useMutation<IAcceptDisclaimerResponse, IError, IAcceptDisclaimerRequest>(API_ACCEPT_DISCLAIMER_MUTATION, options);
    return mutate;
};
