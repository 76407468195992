import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconLinkedIn: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M19.667 3C20.4005 3 21 3.581 21 4.297v0V19.701C21 20.418 20.4005 21 19.667 21v0H4.3275C3.595 21 3 20.418 3 19.701v0V4.297C3 3.581 3.595 3 4.3275 3V3zM8.3395 9.7485H5.667V18.338H8.3395V9.7485zM15.136 9.5345C13.8375 9.5345 12.9665 10.2465 12.6105 10.922v0H12.5735V9.7485H10.0145V18.338H12.681V14.089C12.681 12.968 12.893 11.8835 14.2825 11.8835 15.6505 11.8835 15.67 13.165 15.67 14.161V18.338h2.667V13.6265C18.337 11.313 17.8375 9.5345 15.136 9.5345zM7.004 5.478C6.1465 5.478 5.455 6.172 5.455 7.0255 5.455 7.88 6.1465 8.5735 7.004 8.5735 7.8585 8.5735 8.551 7.88 8.551 7.0255 8.551 6.172 7.8585 5.478 7.004 5.478z"
            />
        </g>
    </Icon>
);
