import { MouseEvent } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { useRouter } from "next/dist/client/router";
import { useTranslation } from "next-i18next";
import { TraderAvatar } from "src/components/common/trader-avatar";
import { LOGOUT_URL } from "src/consts/urls";
import { ILink, IUser } from "src/types/common";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";
import { NavIconCaret } from "./menu-desktop";
import { SubMenuContainer, SubMenuLink } from "./sub-menu";
import { useDesktopNav } from "./use-desktop-menu";
import { useNavigationTracking } from "./use-navigation-tracking";

interface IProps extends FlexProps {
    user: IUser;
    links: ILink[];
}

export const MenuUser = ({ user, links, ...flexProps }: IProps) => {
    const path = useRouter().asPath;
    const { t } = useTranslation("common");
    const { sx, handleSubMenuItemClick } = useDesktopNav();
    const { trackSubNavClick } = useNavigationTracking();

    const onSubMenuItemLinkClick = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>, linkUrl: string) => {
        handleSubMenuItemClick(event);
        trackSubNavClick(linkUrl);
    };

    return (
        <Flex pos="relative" align="center" role="group" cursor="pointer" aria-haspopup="true" sx={sx} {...flexProps}>
            <Flex align="center" h={5} rounded="20px" border="1px" tabIndex={0}>
                <TraderAvatar size="xs" mx={0.5} trader={user} />
                <NavIconCaret mr={1} />
            </Flex>
            <SubMenuContainer align="right">
                {links.map(link => (
                    <SubMenuLink
                        key={link.url}
                        href={link.url}
                        target={link.target}
                        isActive={link.url === path}
                        onClick={event => {
                            onSubMenuItemLinkClick(event, link.url);
                        }}
                        onAuxClick={triggerCallbackOnMiddleClickEvent(event => onSubMenuItemLinkClick(event, link.url))}
                    >
                        {link.name}
                    </SubMenuLink>
                ))}
                <SubMenuLink
                    href={LOGOUT_URL}
                    isActive={false}
                    onClick={event => {
                        onSubMenuItemLinkClick(event, LOGOUT_URL);
                    }}
                    onAuxClick={triggerCallbackOnMiddleClickEvent(event => onSubMenuItemLinkClick(event, LOGOUT_URL))}
                >
                    {t("logout")}
                </SubMenuLink>
            </SubMenuContainer>
        </Flex>
    );
};
