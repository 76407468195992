import { FormEvent, KeyboardEvent as ReactKeyboardEvent } from "react";
import { KEY_DOWN, KEY_ENTER, KEY_ESCAPE, KEY_UP } from "src/consts/keyboard";
import { ITypeaheadState } from "./use-typeahead-state";

interface IUseTypeaheadEventHandlersProps<TResultItem> {
    combinedResults: ReadonlyArray<TResultItem>;
    state: ITypeaheadState;
    events: {
        onClear: () => void;
        onFocus?: () => void;
        onSelect: (selectedItem: TResultItem, selectedIndex: number) => void;
        onSubmit?: () => void;
        setQuery: (query: string) => void;
    };
}

export const useTypeaheadEventHandlers = <TResultItem>({ combinedResults, state, events }: IUseTypeaheadEventHandlersProps<TResultItem>) => {
    const { selectedIndex, setOpen, isOpen, setSelectedIndex } = state;
    const { onSelect, onClear, setQuery, onFocus, onSubmit } = events;
    const handleEnterKey = (event: FormEvent<EventTarget>) => {
        if (selectedIndex !== null && combinedResults[selectedIndex]) {
            const selectedItem = combinedResults[selectedIndex];
            onSelect(selectedItem, selectedIndex);
            event.preventDefault();
            setOpen.off();
        }
    };

    const handleOpen = () => {
        if (!isOpen) {
            setOpen.on();
            setSelectedIndex(null);
        }
    };

    const handleClear = () => {
        onClear();
        setQuery("");
    };

    const handleClose = () => {
        setOpen.off();
    };

    const handleFocus = () => {
        onFocus?.();
        setOpen.on();
    };

    const countOfResults = combinedResults.length;
    const lastIndex = countOfResults - 1;
    const firstIndex = 0;

    const handleKeyDown = (event: ReactKeyboardEvent<EventTarget>) => {
        switch (event.key) {
            case KEY_UP:
                event.preventDefault();

                if (isOpen) {
                    setSelectedIndex(prevSelectedIndex =>
                        prevSelectedIndex === null || prevSelectedIndex === firstIndex ? lastIndex : prevSelectedIndex - 1
                    );
                } else {
                    handleOpen();
                }

                break;
            case KEY_DOWN:
                event.preventDefault();

                if (isOpen) {
                    setSelectedIndex(prevSelectedIndex =>
                        prevSelectedIndex === null || prevSelectedIndex === lastIndex ? firstIndex : prevSelectedIndex + 1
                    );
                } else {
                    handleOpen();
                }

                break;
            case KEY_ENTER:
                handleEnterKey(event);

                break;
            case KEY_ESCAPE:
                event.preventDefault();

                handleClose();

                break;
        }
    };

    const handleSearchChange = (value: string) => {
        setQuery(value);
        setSelectedIndex(null);
        setOpen.on();
    };

    const handleSubmit = (event: FormEvent<EventTarget>) => {
        event.preventDefault();

        onSubmit?.();
    };

    return {
        handleClear,
        handleClose,
        handleFocus,
        handleKeyDown,
        handleSearchChange,
        handleSubmit,
    };
};
