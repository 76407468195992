import config from "src/config";
export interface IQueryParams {
    w?: number;
    h?: number;
    fit?: "clamp" | "clip" | "crop" | "facearea" | "fill" | "fillmax" | "max" | "min" | "scale";
    dpr?: number;
    auto?: string;
}

const DEFAULT_QUERY_PARAMS: IQueryParams = {
    dpr: 2,
    auto: "format",
};

function buildQueryString(_queryParams?: IQueryParams) {
    const queryParams = Object.assign({}, DEFAULT_QUERY_PARAMS, _queryParams);
    const queryString = Object.keys(queryParams)
        .map(key => {
            const val = queryParams[key as keyof IQueryParams];
            if (Boolean(val)) {
                return `${key}=${val}`;
            } else {
                return null;
            }
        })
        .filter(Boolean)
        .join("&");

    return queryString ? `${queryString}` : "";
}

export const adjustBaseUrl = (baseUrl: string): string => {
    if (!process.env.NEXT_PUBLIC_IMGIX_URL) {
        return baseUrl;
    }

    let imgixUrl: string = process.env.NEXT_PUBLIC_IMGIX_URL;
    if (!imgixUrl.endsWith("/")) {
        imgixUrl += "/";
    }

    // split the URL by "/", this gives us an string[] which we can use to convert the baseUrl
    const urlParts = baseUrl.split("/");

    for (let i = 3; i < urlParts.length; i++) {
        imgixUrl += urlParts[i];

        // adds "/" to all but the last iteration, so we do not have "/" at the end of the string
        if (i + 1 !== urlParts.length) {
            imgixUrl += "/";
        }
    }

    return imgixUrl;
};

const isThirdPartyImageSrcUrl = (url: string) =>
    ![new RegExp(process.env.NEXT_PUBLIC_WIKIFOLIO_BLOB_ENDPOINT ?? ""), new RegExp(process.env.NEXT_PUBLIC_IMGIX_URL ?? "")].some(baseUrl =>
        baseUrl.test(url)
    );

export function buildUrl(baseUrl?: string, _queryParams?: IQueryParams) {
    if (!baseUrl) {
        return "";
    }

    if (isThirdPartyImageSrcUrl(baseUrl)) {
        return baseUrl;
    }

    let separator = "?";
    if (baseUrl.indexOf("?") > -1) {
        separator = "&";
    }

    return `${adjustBaseUrl(baseUrl)}${separator}${buildQueryString(_queryParams)}`;
}

export function getImageBaseUrl() {
    return `${adjustBaseUrl(process.env.NEXT_PUBLIC_IMGIX_URL ?? "")}${config.publicCloudEnvShortName}/`;
}
