import { memo } from "react";
import {
    Box,
    Divider,
    Flex,
    HStack,
    // eslint-disable-next-line no-restricted-imports
    Link,
    List,
    ListItem,
    Stack,
    StackDivider,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfLinkGhost } from "src/components/base/wf-link";
import { WfSelect } from "src/components/base/wf-select";
import { IconMail, IconPhone, IconFacebook, IconInstagram, IconLinkedIn, IconTwitter, IconYoutube } from "src/components/icons";
import { Container } from "src/components/layout/container";
import { CurrencySelector } from "src/components/layout/footer/currency-selector";
import { extractLocale } from "src/consts/locale";
import { useDisclaimerStore } from "src/stores/disclaimer-store";
import { IFooter } from "src/types/layout";
import { FOOTER_SELECT_COUNTRY_TEST_ID } from "./footer.test-ids";

const divider_color = "gray.300";

const Footer = (props: IFooter) => {
    const { linkList, currencies, currentIso, countries, email, phone, bottomLinkList } = props;
    const { t } = useTranslation("common");
    const disclaimerStore = useDisclaimerStore();

    return (
        <Box as="footer" bg="gray.100" mt="auto">
            <Container py={5}>
                <Stack direction={["column", "row"]} justifyContent="space-between" spacing={[3, 2]}>
                    <Stack direction={["column", "row"]} spacing={[3, 4]} divider={<StackDivider borderColor={[divider_color, "transparent"]} />}>
                        {linkList.map((item, i) => (
                            <Box key={i} textAlign={["center", "left"]}>
                                <Box fontWeight="bold" mb={[2, 3]}>
                                    {item.title}
                                </Box>
                                <List spacing={2}>
                                    {item.links.map((link, i) => (
                                        <ListItem key={i}>
                                            <WfLinkGhost href={link.url} target={link.target} color="gray.600" fontSize="sm" fontWeight="normal">
                                                {link.name}
                                            </WfLinkGhost>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        ))}
                    </Stack>
                    <Flex flexShrink={0} direction="column" align="flex-end">
                        <Box width={["full", "auto"]} mb={2}>
                            <WfSelect
                                size="sm"
                                fontSize="sm"
                                fontWeight="semibold"
                                borderColor="gray.800"
                                bg="transparent"
                                value={currentIso}
                                onChange={e => {
                                    const { country } = extractLocale(e.target.value);
                                    disclaimerStore.tryChangeCountry(country, false);
                                }}
                                aria-label={t("labels.select-country")}
                                data-test-id={FOOTER_SELECT_COUNTRY_TEST_ID}
                            >
                                {countries.map(country => (
                                    <option key={country.value} value={country.value}>
                                        {country.label}
                                    </option>
                                ))}
                            </WfSelect>
                        </Box>
                        <Box width={["full", "auto"]}>
                            <CurrencySelector currencies={currencies} />
                        </Box>
                    </Flex>
                </Stack>
                <Divider borderColor={["transparent", divider_color]} my={[2, 5]} />
                <Stack direction={["column", "row"]} spacing={4} justify="space-between" align="center">
                    <Stack direction={["column", "row"]} spacing={[3, 4]} alignItems="center">
                        <Link href={phone.url} target={phone.target} variant="ghost" size="sm" className="gtm-phone">
                            <IconPhone boxSize={3} mr={1} />
                            {phone.name}
                        </Link>
                        <Link href={email.url} target={email.target} variant="ghost" size="sm" className="gtm-contact__mail">
                            <IconMail boxSize={3} mr={1} />
                            {email.name}
                        </Link>
                    </Stack>
                    <HStack spacing={2}>
                        <Link
                            href="https://www.facebook.com/wikifolio"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label={t("follow-facebook")}
                            className="gtm-footer__social-icon"
                        >
                            <IconFacebook boxSize={3} color="inherit" />
                        </Link>
                        <Link
                            href="https://www.instagram.com/wikifolio/"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label={t("follow-instagram")}
                            className="gtm-footer__social-icon"
                        >
                            <IconInstagram boxSize={3} />
                        </Link>
                        <Link
                            href="https://twitter.com/wikifolio"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label={t("follow-twitter")}
                            className="gtm-footer__social-icon"
                        >
                            <IconTwitter boxSize={3} />
                        </Link>
                        <Link
                            href="https://www.linkedin.com/company/2486880/"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label={t("follow-linked-in")}
                            className="gtm-footer__social-icon"
                        >
                            <IconLinkedIn boxSize={3} />
                        </Link>
                        <Link
                            href="https://www.youtube.com/user/wikifoliocom"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label={t("follow-youtube")}
                            className="gtm-footer__social-icon"
                        >
                            <IconYoutube boxSize={3} />
                        </Link>
                    </HStack>
                </Stack>
                <Flex direction={["column", "row-reverse"]} justify="space-between" align="center" mt={4}>
                    <HStack spacing={2} mb={[4, 0]}>
                        {bottomLinkList.map((link, idx) => (
                            <WfLinkGhost key={idx} href={link.url} target={link.target} size="sm" fontWeight="normal" color="gray.600">
                                {link.name}
                            </WfLinkGhost>
                        ))}
                    </HStack>
                    <Box textAlign="center" fontSize="sm" color="gray.600">
                        {new Date().getFullYear()} © wikifolio Financial Technologies AG
                    </Box>
                </Flex>
            </Container>
        </Box>
    );
};

export const FooterMemo = memo(Footer);
