import { useCallback, useEffect } from "react";
import { useBoolean } from "@chakra-ui/react";

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        Cookiebot?: {
            consent: {
                statistics: boolean;
            };
        };
    }
}

const COOKIEBOT_LOADED_CHECK_TIMEOUT = 4000;

export function useCookieBotConsentReady() {
    const [isConsentReady, setIsConsentReady] = useBoolean(false);
    const handleCookieBotConsentReady = useCallback(() => {
        setIsConsentReady.on();
    }, [setIsConsentReady]);

    useEffect(() => {
        setTimeout(() => {
            if (!document.getElementById("Cookiebot") && !window.Cookiebot) {
                handleCookieBotConsentReady();
            }
        }, COOKIEBOT_LOADED_CHECK_TIMEOUT);

        window.addEventListener("CookiebotOnConsentReady", handleCookieBotConsentReady, false);

        return () => {
            window.removeEventListener("CookiebotOnConsentReady", handleCookieBotConsentReady);
        };
    }, [handleCookieBotConsentReady]);

    return isConsentReady;
}
