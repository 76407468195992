import { useMemo } from "react";
import { NAV_HEIGHT } from "src/components/layout/default-layout";
import { TOP_SPACE_WITH_TICKER } from "src/components/layout/nav/constants";
import { LANGUAGE } from "src/consts/locale";
import { useRouterLocale } from "src/utils/router/use-router-locale";

/**
 * This hook provides the appropriate height for the header, considering the presence of a ticker news bar.
 * In the current implementation, the ticker news bar exists only in German (DE) language mode, leading to a larger header height.
 * If the language changes to English (EN), the ticker news bar is not present, resulting in a smaller header height.
 */
export const useNavHeight = () => {
    const { language } = useRouterLocale();

    const NAV_HEIGHT_VARIATION = useMemo(() => {
        return language === LANGUAGE.DE ? TOP_SPACE_WITH_TICKER : NAV_HEIGHT;
    }, [language]);

    return { NAV_HEIGHT_VARIATION } as const;
};
