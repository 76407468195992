import { Avatar } from "@chakra-ui/react";
import { avatarSizeMap } from "src/theme/components/avatar";
import { TRADER_AVATAR_INITIALS_TEST_ID } from "./initials-avatar.test-ids";

export type IAvatarSize = keyof typeof avatarSizeMap;

const fontSizeMapping: { [key: string]: string } = {
    "2xl": "32px",
    xl: "24px",
    lg: "20px",
    md: "20px",
    sm: "16px",
    xs: "12px",
};

const lineHeightMapping: { [key: string]: string } = {
    "2xl": "80px",
    xl: "64px",
    lg: "56px",
    md: "48px",
    sm: "40px",
    xs: "32px",
};

interface IInitialsAvatar {
    size: IAvatarSize | IAvatarSize[];
    firstName: string;
    lastName: string;
    bg: string;
}

export const InitialsAvatar = ({ size, firstName, lastName, bg }: IInitialsAvatar) => {
    const initials = `${firstName.substring(0, 1)} ${lastName.substring(0, 1)}`;

    const fontSize = Array.isArray(size) ? size.map(s => fontSizeMapping[s]) : fontSizeMapping[size];
    const lineHeight = Array.isArray(size) ? size.map(s => lineHeightMapping[s]) : lineHeightMapping[size];

    return (
        <Avatar
            data-test-id={TRADER_AVATAR_INITIALS_TEST_ID}
            textColor="white"
            size={size}
            fontFamily="Arial"
            fontWeight={fontSize === "2xl" ? 700 : 600}
            bg={bg}
            name={initials}
            sx={{
                ".chakra-avatar__initials": {
                    fontSize,
                },
                ":first-of-type div": {
                    lineHeight,
                },
            }}
            aria-hidden
        />
    );
};
