const parts = ["item", "list", "button", "groupTitle", "divider"];

const baseStyleList = {
    bg: "white",
    boxShadow: "none",
    color: "inherit",
    minW: "180px",
    py: 1,
    zIndex: 1,
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "gray.200",
};

const baseStyleItem = {
    py: 1,
    px: 2,
    h: 6,
    transitionProperty: "background",
    transitionDuration: "ultra-fast",
    transitionTimingFunction: "ease-in",
    _focus: {
        bg: "gray.100",
    },
    _active: {
        bg: "gray.200",
    },
    _expanded: {
        bg: "gray.100",
    },
    _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
    },
};

const baseStyleGroupTitle = {
    mx: 4,
    my: 2,
    fontWeight: "semibold",
    fontSize: "sm",
};

const baseStyleDivider = {
    border: 0,
    borderBottom: "1px solid",
    borderColor: "inherit",
    my: 1,
    opacity: 0.6,
};

const baseStyleButton = {
    transitionProperty: "common",
    transitionDuration: "normal",
};

const baseStyle = {
    button: baseStyleButton,
    list: baseStyleList,
    item: baseStyleItem,
    groupTitle: baseStyleGroupTitle,
    divider: baseStyleDivider,
};

const variantContextMenu = {
    list: {
        minW: null,
        mt: 1,
    },
};

export default {
    parts,
    baseStyle,
    variants: {
        contextMenu: variantContextMenu,
    },
};
