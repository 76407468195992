import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { EnumActivityType, useGenericActivityMutation } from "src/api/client/generic-activity.api";
import { EnumMouseButtons } from "src/types/common-enum";
import { useCanRunVwoExperiments } from "./use-can-run-vwo-experiments";

interface IExperimentData {
    campaignName: string;
    campaignId: string;
    variationName: string;
    variationId: string;
}

export const useTrackVWOActivity = () => {
    const genericActivity = useGenericActivityMutation();
    const canRunVwoExperiments = useCanRunVwoExperiments();
    const experimentData: MutableRefObject<IExperimentData | null> = useRef(null);

    const trackInitVwo = useCallback(
        (event: Event) => {
            experimentData.current = (<CustomEvent>event).detail;
            const { campaignId, variationId } = experimentData.current as IExperimentData;

            genericActivity({
                activityTypeId: EnumActivityType.VwoExperimentSeen,
                parameter: `exp=${campaignId},var=${variationId}`,
            });
        },
        [genericActivity]
    );

    const trackClicks = useCallback(
        (event: MouseEvent) => {
            const isLeftOrMiddleMouseButtonPressed = event.button === EnumMouseButtons.Main || event.button === EnumMouseButtons.Auxiliary;
            if (isLeftOrMiddleMouseButtonPressed) {
                const element = (event?.target as Element).closest(".js-vwo-tracking");
                if (element && experimentData.current) {
                    const id = element.getAttribute("data-id");
                    const { campaignId, variationId } = experimentData.current as IExperimentData;

                    genericActivity({
                        activityTypeId: EnumActivityType.VwoExperimentClicked,
                        parameter: `exp=${campaignId},var=${variationId},elem=${id}`,
                    });
                }
            }
        },
        [genericActivity]
    );

    useEffect(() => {
        if (canRunVwoExperiments) {
            document.addEventListener("vwo:loaded", trackInitVwo);
            document.addEventListener("click", trackClicks);
            document.addEventListener("auxclick", trackClicks);
        }

        return () => {
            document.removeEventListener("vwo:loaded", trackInitVwo);
            document.removeEventListener("click", trackClicks);
            document.removeEventListener("auxclick", trackClicks);
        };
    }, [canRunVwoExperiments, trackClicks, trackInitVwo]);
};
