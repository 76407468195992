import { Box, Text, Divider } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfLink } from "src/components/base/wf-link";
import { useExtendedSearchUrl } from "src/components/common/typeahead/hooks/use-extended-search-url";
import { UnderlyingSearchItem, WikifolioSearchItem } from "src/components/common/typeahead/nav-search-item";
import { ISearchResults } from "src/components/common/typeahead/typeahead.types";
import { getGlobals } from "src/consts/globals";
import { createStockInsightsPageUnderlyingListUrl } from "src/consts/urls";
import { useRouterLocale } from "src/utils/router/use-router-locale";

const NAV_SEARCH_RESULTS_TEST_ID = "nav-search-results";
const NAV_SEARCH_NO_WIKIFOLIOS_FOUND_MESSAGE_TEST_ID = "nav-search-no-wikifolios-found-message";
const NAV_SEARCH_NO_STOCKS_FOUND_MESSAGE_TEST_ID = "nav-search-no-stocks-found-message";

export const GlobalSearchResults = ({ results, selectedIndex, query, placement = "top" }: ISearchResults) => {
    const globals = getGlobals();
    const { language, country } = useRouterLocale();
    const { getExtendedSearchUrl } = useExtendedSearchUrl();
    const { t } = useTranslation("common");

    const { wikifolios, underlyings } = results ?? {};

    const wikifoliosLength = wikifolios?.length ?? 0;
    const noWfFound = wikifoliosLength === 0;
    const noUnderLyingsFound = underlyings?.length === 0;

    const renderSeeAllMatchingWFUrl = !noWfFound && query;

    return (
        <Box
            borderWidth={[0, 0, "1px", "1px"]}
            borderRadius="8px"
            bg="white"
            position="absolute"
            width="100%"
            overflow="hidden"
            borderColor="gray.400"
            mt={2}
            pt={2}
            zIndex={["initial", "initial", "overlay"]}
            data-test-id={NAV_SEARCH_RESULTS_TEST_ID}
        >
            <Text px={[1, 3]} pb={1} fontSize="lg" fontWeight="bold" lineHeight="tall">
                {t("wikifolio")}
            </Text>

            {noWfFound ? (
                <Text px={[1, 3]} pb={1} lineHeight="tall" data-test-id={NAV_SEARCH_NO_WIKIFOLIOS_FOUND_MESSAGE_TEST_ID}>
                    {t("wikifolios-not-found")}
                </Text>
            ) : (
                wikifolios?.map((wikifolio, index) => (
                    <WikifolioSearchItem
                        key={wikifolio.resourceIdentifier}
                        query={query}
                        isSelected={index === selectedIndex}
                        resourceIdentifier={wikifolio.resourceIdentifier}
                        shortDescription={wikifolio.shortDescription}
                        performance={wikifolio.performance}
                        trader={wikifolio.trader}
                        placement={placement}
                    />
                ))
            )}

            {renderSeeAllMatchingWFUrl ? (
                <WfLink pt={2} px={[1, 3]} pb={3} fontSize="sm" display="block" href={getExtendedSearchUrl(query)}>
                    {t("see-all-matching-wf")}
                </WfLink>
            ) : (
                <WfLink pt={2} px={[1, 3]} pb={3} fontSize="sm" display="block" href={globals.routes.searchExtended || "/"}>
                    {t("see-all-wikifolios")}
                </WfLink>
            )}

            <Box mx={[1, 3]} mb={3}>
                <Divider borderColor="gray.100" />
            </Box>

            <Text px={[1, 3]} pb={1} fontSize="lg" fontWeight="bold" lineHeight="tall">
                {t("stocks-and-etfs")}
            </Text>

            {noUnderLyingsFound ? (
                <Text px={[1, 3]} pb={1} lineHeight="tall" data-test-id={NAV_SEARCH_NO_STOCKS_FOUND_MESSAGE_TEST_ID}>
                    {t("stocks-etfs-not-found")}
                </Text>
            ) : (
                underlyings?.map((underlying, index) => (
                    <UnderlyingSearchItem
                        key={underlying.resourceIdentifier}
                        query={query}
                        isSelected={index + wikifoliosLength === selectedIndex}
                        resourceIdentifier={underlying.resourceIdentifier}
                        shortDescription={underlying.shortDescription}
                        securityType={underlying.securityType}
                        placement={placement}
                    />
                ))
            )}
            <WfLink pt={2} px={[1, 3]} pb={3} fontSize="sm" display="block" href={createStockInsightsPageUnderlyingListUrl(language, country)}>
                {t("see-current-stock-trends")}
            </WfLink>
        </Box>
    );
};
