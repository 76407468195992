export default {
    baseStyle: {
        helperText: {
            mt: 1,
            color: "gray.500",
            fontSize: "xs",
            lineHeight: "base",
        },
    },
};
