import { BoxProps, IconButton } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ButtonProps } from "src/components/base/button";
import { IconClose } from "src/components/icons";

interface ICloseButtonProps extends Omit<ButtonProps, "children" | "size"> {
    iconBoxSize: BoxProps["boxSize"];
}
export const CloseButton = ({ iconBoxSize, ...buttonProps }: ICloseButtonProps) => {
    const { t } = useTranslation("common");
    return <IconButton icon={<IconClose boxSize={iconBoxSize} />} aria-label={t("close")} {...buttonProps} />;
};
