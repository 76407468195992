import { getGlobals } from "src/consts/globals";
import { logger } from "src/utils/logger-util";

const CREATE_BASE_URL_ERROR_MESSAGE = "Can't create base url for extended search.";

interface IReturnObject {
    getExtendedSearchUrl: (searchTerm: string | undefined) => string;
}

export const useExtendedSearchUrl = (): IReturnObject => {
    const globals = getGlobals();

    const getExtendedSearchUrl = (searchTerm: string | undefined) => {
        if (!globals.routes.searchExtended) {
            logger.error({ message: CREATE_BASE_URL_ERROR_MESSAGE });
            return "/";
        }

        return [`${globals.routes.searchExtended}?`, searchTerm ? `searchTerm=${encodeURIComponent(searchTerm)}` : null].filter(Boolean).join("");
    };

    return { getExtendedSearchUrl };
};
