import { TFunction } from "i18next";
import { ILanguage } from "src/consts/locale";
import { getLocale } from "./shared";

// Dev-Note: mapping for the ordinal suffixes as described here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules#using_options
const ordinalSuffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
]);

// Dev-Note: we always initialize it with en-US because we only need it for the english locale
const ordinalFormatter = new Intl.PluralRules("en-US", { type: "ordinal" });

export type IFractionDigits = 0 | 1 | 2 | 3;

const getFixFractionDigits = (fractionDigits: IFractionDigits) => {
    return {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    };
};

const getNumberFormatters = (language: ILanguage, options: Intl.NumberFormatOptions) => {
    const locale = getLocale(language);

    return [
        new Intl.NumberFormat(locale, { ...options, maximumFractionDigits: 0 }),
        new Intl.NumberFormat(locale, { ...options, ...getFixFractionDigits(1) }),
        new Intl.NumberFormat(locale, { ...options, ...getFixFractionDigits(2) }),
        new Intl.NumberFormat(locale, { ...options, ...getFixFractionDigits(3) }),
    ];
};

const getDecimalFormatter = (language: ILanguage) => getNumberFormatters(language, { style: "decimal" });

const getPercentFormatter = (language: ILanguage) => getNumberFormatters(language, { style: "percent" });

const getPercentWithoutSignFormatter = (language: ILanguage) => getNumberFormatters(language, { style: "percent", signDisplay: "never" });

const getPerformanceFormatter = (language: ILanguage) => getNumberFormatters(language, { style: "percent", signDisplay: "exceptZero" });

const _format = (formatter: Intl.NumberFormat, value: number) => {
    return formatter
        .formatToParts(value)
        .map(({ value }) => value)
        .join("");
};

/**
 * Returns string formatted value
 * - `0`: Default format to `int`
 * - `1`: `1 > 1.0` `1.19 > 1.2`
 * - `2`: `1 > 1.00` `1.119 > 1.12`
 * - `3`: `1 > 1.000` `1.1119 > 1.112`
 */

const negativeZeroRegex = /^-0[\.,]0$/;

interface IFormat {
    value: number;
    fractionDigits?: IFractionDigits;
    language: ILanguage;
}
export const formatNumber = ({ value, fractionDigits = 0, language }: IFormat) => {
    const formattedValue = getDecimalFormatter(language)[fractionDigits].format(value);
    return negativeZeroRegex.test(formattedValue) ? formattedValue.slice(1) : formattedValue;
};

/**
 * Returns string formatted performance value
 * - `0`: Default format to `int %`
 * - `1`: `0.1 > +10.0 %` `0.1019 > +10.2 %`
 * - `2`: `0.1 > +10.00 %` `0.10119 > +10.12 %`
 * - `3`: `0.1 > +10.000 %` `0.101119 > +10.112 %`
 */
export const formatPerformance = ({ value, fractionDigits = 0, language }: IFormat) => {
    return _format(getPerformanceFormatter(language)[fractionDigits], value);
};

/**
 * Returns string formatted percent value
 * - `0`: Default format to `int %`
 * - `1`: `0.1 > 10.0 %` `0.1019 > 10.2 %`
 * - `2`: `0.1 > 10.00 %` `0.10119 > 10.12 %`
 * - `3`: `0.1 > 10.000 %` `0.101119 > 10.112 %`
 */
export const formatPercent = ({ value, fractionDigits = 0, language }: IFormat) => {
    return _format(getPercentFormatter(language)[fractionDigits], value);
};

/**
 * Returns string formatted volatility value as a performance value without a sign
 * - `0`: Default format to `int %`
 * - `1`: `0.1 > 10.0 %` `0.1019 > 10.2 %`
 * - `2`: `0.1 > 10.00 %` `0.10119 > 10.12 %`
 * - `3`: `0.1 > 10.000 %` `0.101119 > 10.112 %`
 */
export const formatVolatility = ({ value, fractionDigits = 0, language }: IFormat) => {
    const isPercentageHigherThan100 = value > 1;
    const formattedValue = _format(getPercentWithoutSignFormatter(language)[fractionDigits], isPercentageHigherThan100 ? 1 : value);

    return `${isPercentageHigherThan100 ? "> " : ""} ${formattedValue}`;
};

/**
 * Returns string formatted performance in percent value
 * - `0`: Default format to `int %`
 * - `1`: `10 > +10.0 %` `10.19 > +10.2 %`
 * - `2`: `10 > +10.00 %` `10.119 > +10.12 %`
 * - `3`: `10 > +10.000 %` `10.1119 > +10.112 %`
 */
// TODO: remove this method after implementing BE part of https://wikifolio.atlassian.net/browse/WIKI-26219
export const formatPercentPerformance = ({ value, fractionDigits = 0, language }: IFormat) => {
    const normalizedValue = value / 100;
    return _format(getPerformanceFormatter(language)[fractionDigits], normalizedValue);
};

interface IFormatMoney {
    value: number;
    t: TFunction;
    language: ILanguage;
}

/**
 * AC - DE!:
 * - Under `100` → `"99,00"`
 * - EUR `100` → `"0,10 Tsd"`
 * - EUR `1_000` → `" 1,00 Tsd"`
 * - EUR `10_000` → `"10,00 Tsd"`
 * - EUR `100_000` → `"0,10 Mio"`
 * - EUR `1_000_000` → `"1,00 Mio"`
 * - EUR `100_000_000` → `"100,00 Mio"`
 */
export const formatMoney = ({ value, t, language }: IFormatMoney) => {
    let _value = value;
    let suffix = "";
    if (value >= 100_000) {
        _value = value / 1_000_000;
        suffix = t("million-short");
    } else if (value >= 100) {
        _value = value / 1_000;
        suffix = t("thousand-short");
    }

    const formattedValue = formatNumber({ value: _value, fractionDigits: 2, language });

    return `${formattedValue}${suffix}`;
};

/**
 * Returns string formatted ordinal value
 *
 * EN!
 * - `0` > `0th`
 * - `1` > `1st`
 * - `2` > `2nd`
 * - `3` > `3rd`
 * ...
 */

interface IFormatOrdinals {
    value: number;
    language: ILanguage;
}
export const formatOrdinals = ({ value, language }: IFormatOrdinals) => {
    let suffix = ".";
    if (language === "en") {
        const rule = ordinalFormatter.select(value);
        suffix = ordinalSuffixes.get(rule) || ".";
    }
    return `${value}${suffix}`;
};

/**
 * Returns string formatted number with "+" sign if value is greater than 0
 * - `0`: Default format to `int`
 * - `1`: `1 > 1.0` `1.19 > 1.2`
 * - `2`: `1 > 1.00` `1.119 > 1.12`
 * - `3`: `1 > 1.000` `1.1119 > 1.112`
 */

interface IFormatNumberWithSignParams extends IFormat {}

export const formatNumberWithSign = ({ value, fractionDigits, language }: IFormatNumberWithSignParams) => {
    const numberPrefix = value > 0 ? "+" : "";
    return `${numberPrefix}${formatNumber({ value, fractionDigits, language })}`;
};

/**
 * Returns string formatted number with "+" sign if value is greater than 0, but with 0 to 2 fractions.
 * - `0` -> 0
 * - `1` -> +1
 * - `1.1` -> +1.1
 * - `1.22` -> +1.22
 * - `3.0` -> +3
 */
interface IFormatNumberWithLeadingSign {
    language: ILanguage;
    value: number;
    showPositiveLeadingSign: boolean;
}

export const formatRangeSliderNumber = ({ language, value, showPositiveLeadingSign }: IFormatNumberWithLeadingSign) => {
    const locale = getLocale(language);
    return new Intl.NumberFormat(locale, { maximumFractionDigits: 2, signDisplay: showPositiveLeadingSign ? "exceptZero" : "auto" }).format(value);
};
