import { EnumNavigationTrack } from "src/analytics/enum";
import { useGenericActivityFromElysiumMutation } from "src/api/client/generic-activity-from-elysium.api";
import { EnumActivityType } from "src/api/client/generic-activity.api";
import { isValidAbsoluteUrl } from "src/utils/url-util";

type IMenuLinkClick = {
    url: string;
    activityTypeId: EnumActivityType;
    type: EnumNavigationTrack;
};

export const useNavigationTracking = () => {
    const genericActivity = useGenericActivityFromElysiumMutation();

    const trackNavClick = ({ url, activityTypeId, type }: IMenuLinkClick) => {
        const targetUrl = isValidAbsoluteUrl(url) ? url : `${window.location.origin}${url}`;

        genericActivity({
            activityTypeId,
            parameter: JSON.stringify({ type, targetUrl }),
            referrer: window.location.href,
        });
    };

    const trackSubNavClick = (url: string) =>
        trackNavClick({
            url,
            activityTypeId: EnumActivityType.NavigationClicked,
            type: EnumNavigationTrack.Sub,
        });

    const trackMainNavClick = (url: string) =>
        trackNavClick({
            url,
            activityTypeId: EnumActivityType.NavigationClicked,
            type: EnumNavigationTrack.Main,
        });

    return {
        trackMainNavClick,
        trackSubNavClick,
    };
};
