import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconAvatar: TIconComponent = props => (
    <Icon viewBox="0 0 32 32" {...props}>
        <circle cx="16" cy="16" r="16" fill="currentColor" />
        <circle cx="16" cy="12" r="6" fill="#F2F2F2" />
        <path
            d="m26 26.017c-2.3871-3.8453-5.5033-6.017-9.9929-6.017-4.4464 0-7.6129 2.2342-10.007 6.017 3.3457 2.6554 6.6814 3.983 10.007 3.983 3.3257-9.24e-5 6.6567-1.3277 9.9929-3.983z"
            fill="#F2F2F2"
        />
    </Icon>
);
