import { NextRouter } from "next/router";

export const getQueryString = (router: NextRouter) => {
    const url = router.asPath;
    if (url && url.includes("?")) {
        return url.split("?")[1];
    }
    return null;
};

export const isValidAbsoluteUrl = (url: string) => {
    try {
        const urlObj = new URL(url);
        return urlObj.protocol === "https:" || urlObj.protocol === "http:";
    } catch (e) {
        return false;
    }
};
