// eslint-disable-next-line no-restricted-imports
import { Button as ButtonChakra, ButtonProps as ButtonPropsChakra, forwardRef } from "@chakra-ui/react";
import ButtonTheme from "src/theme/components/button";

type ISize = "xs" | "sm" | "md" | "lg";

/* eslint-disable @typescript-eslint/naming-convention */
export interface ButtonProps extends Omit<ButtonPropsChakra, "size"> {
    size?: ISize | ISize[];
}

export const Button = forwardRef(({ size, ...buttonProps }: ButtonProps, ref) => {
    if (size === undefined) {
        return <ButtonChakra ref={ref} {...buttonProps} />;
    }
    if (typeof size === "string") {
        return <ButtonChakra ref={ref} size={size} {...buttonProps} />;
    }

    // Render responsive Button
    const h: number[] = [];
    const minW: number[] = [];
    const fontSize: string[] = [];
    const px: number[] = [];

    size.forEach(s => {
        const btnThemeSizeProps = ButtonTheme.sizes[s];

        h.push(btnThemeSizeProps.h);
        minW.push(btnThemeSizeProps.minW);
        fontSize.push(btnThemeSizeProps.fontSize);
        px.push(btnThemeSizeProps.px);
    });

    return <ButtonChakra ref={ref} {...{ h, minW, fontSize, px }} {...buttonProps} />;
});
