import { forwardRef, useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { MotionBoxProps } from "src/components/base";
import { IPlacement } from "src/components/common/typeahead/typeahead.types";
import { GlobalNavSearchUi } from "./global-nav-search-ui";
import { GlobalTypeaheadWrapper } from "./global-typeahead-wrapper";

interface IProps extends MotionBoxProps {
    navHeights: number[];
    onSearchClose: () => void;
    searchPlaceholderText?: string;
    placement?: IPlacement;
}

export const GlobalNavSearch = forwardRef(({ navHeights, onSearchClose, searchPlaceholderText, placement = "top", ...otherProps }: IProps, ref) => {
    const { t } = useTranslation("common");
    const placeholderKey = useBreakpointValue(["typeahead-placeholder-mobile", "typeahead-placeholder"]) ?? "typeahead-placeholder";

    return (
        <GlobalNavSearchUi navHeights={navHeights} onSearchClose={onSearchClose} ref={ref} {...otherProps}>
            <GlobalTypeaheadWrapper placement={placement} placeholderText={searchPlaceholderText ?? t(placeholderKey)} enableAutoFocus />
        </GlobalNavSearchUi>
    );
});
