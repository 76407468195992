import { proxy, useSnapshot } from "valtio";

export interface IDisclaimerOptions {
    country: string;
    direct: boolean;
}

// This export should only be used in app.tsx in order to transform the User object without listening on changes
export const disclaimerStore = proxy({
    showDisclaimer: false,
    options: null as IDisclaimerOptions | null,

    closeDisclaimer() {
        disclaimerStore.showDisclaimer = false;
    },

    tryChangeCountry(country: string, direct: boolean) {
        disclaimerStore.showDisclaimer = true;
        disclaimerStore.options = {
            country,
            direct,
        };
    },
});

export function useDisclaimerStore() {
    return useSnapshot(disclaimerStore);
}
