import { useEffect } from "react";
import { Stack, VStack, Box, Text } from "@chakra-ui/react";
import { useCloseStickyBannerMutation } from "src/api/client/close-sticky-banner.api";
import { useGenericActivityFromElysiumMutation } from "src/api/client/generic-activity-from-elysium.api";
import { EnumActivityType } from "src/api/client/generic-activity.api";
import { Button } from "src/components/base/button";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { CloseButton } from "src/components/common/buttons/close-button";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Banner } from "src/components/layout/banners/banner";
import { useBannerAction } from "src/components/layout/banners/use-banner-action";
import { IStickyBanner } from "src/types/banners";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";
import { STICKY_BANNER_TEST_ID } from "./sticky-banner.test-ids";

enum EnumClickType {
    CLOSE = "close",
    CTA = "cta",
}

interface IStickyBannerProps extends IStickyBanner {
    onCloseBanner: () => void;
}

export const StickyBanner = ({ nodeId, image, headline, description, button, onCloseBanner }: IStickyBannerProps) => {
    const closeStickyBannerMutation = useCloseStickyBannerMutation();
    const { handleAction } = useBannerAction(closeStickyBannerMutation);
    const genericActivity = useGenericActivityFromElysiumMutation();

    useEffect(() => {
        genericActivity({
            activityTypeId: EnumActivityType.StickyMessageBannerSeen,
            parameter: JSON.stringify({ nodeId }),
            referrer: window.location.href,
        });
    }, [genericActivity, nodeId]);

    const closeBanner = () => {
        onCloseBanner();
        handleAction();
    };

    const onCtaButtonClick = () => {
        closeBanner();
        genericActivity({
            activityTypeId: EnumActivityType.StickyMessageBannerClicked,
            parameter: JSON.stringify({ nodeId, clickType: EnumClickType.CTA }),
            referrer: window.location.href,
        });
    };

    const onCloseButtonClick = () => {
        closeBanner();
        genericActivity({
            activityTypeId: EnumActivityType.StickyMessageBannerClicked,
            parameter: JSON.stringify({ nodeId, clickType: EnumClickType.CLOSE }),
            referrer: window.location.href,
        });
    };

    return (
        <Banner px={0} py={0} data-test-id={STICKY_BANNER_TEST_ID}>
            <Stack direction={["column", "row"]} spacing={0} height={["auto", "260px", "216px", "199px"]} position="relative">
                <Box
                    width={["100%", "293px", "324px", "299px"]}
                    flexShrink={0}
                    overflow="hidden"
                    borderTopLeftRadius="8px"
                    borderBottomLeftRadius={[0, "8px"]}
                    borderTopRightRadius={["8px", 0]}
                >
                    <ResponsiveImage
                        height="100%"
                        width="100%"
                        objectFit="cover"
                        srcSet={[
                            { queryParams: { w: 767 } },
                            { queryParams: { h: 260, w: 293 } },
                            { queryParams: { h: 216, w: 324 } },
                            { queryParams: { h: 199, w: 299 } },
                        ]}
                        baseUrl={image.url}
                        alt={image?.alt}
                        loading="eager"
                    />
                </Box>
                <VStack w={["100%", "auto"]} p={[2, 3]} pr={[2, 9, 8, 9]} spacing={2} alignItems="flex-start" justifyContent="space-between">
                    <VStack alignItems="flex-start" spacing={1}>
                        <Text fontWeight="bold">{headline}</Text>
                        <SimpleRichText fontSize="sm" wordBreak="break-word" mt={1} text={description} />
                    </VStack>
                    <Button
                        as="a"
                        href={button.url}
                        target={button.target}
                        colorScheme="gray"
                        w={["100%", "auto"]}
                        size="sm"
                        flexShrink={0}
                        onClick={onCtaButtonClick}
                        onAuxClick={triggerCallbackOnMiddleClickEvent(() => onCtaButtonClick())}
                    >
                        {button.name}
                    </Button>
                </VStack>
                <CloseButton
                    iconBoxSize={2}
                    position="absolute"
                    right="16px"
                    top="16px"
                    borderRadius="50%"
                    height={5}
                    minW={5}
                    color="gray.800"
                    bg="white"
                    _hover={{ bg: "gray.50" }}
                    _focus={{ bg: "gray.50" }}
                    onClick={onCloseButtonClick}
                />
            </Stack>
        </Banner>
    );
};
