import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconSuccessOutline: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" transform="translate(2 2)">
            <path d="m20 10c0 5.5229-4.4771 10-10 10-5.5229 0-10-4.4771-10-10 0-5.5229 4.4771-10 10-10 5.5229 0 10 4.4771 10 10" />
            <path
                d="m10 0c5.5229 0 10 4.4771 10 10 0 5.5229-4.4771 10-10 10-5.5229 0-10-4.4771-10-10 0-5.5229 4.4771-10 10-10zm0 2c-4.4183 0-8 3.5817-8 8 0 4.4183 3.5817 8 8 8 4.4183 0 8-3.5817 8-8 0-4.4183-3.5817-8-8-8z"
                fill="currentColor"
                fillRule="nonzero"
            />
            <path
                d="m14.307 6.2795c0.39795-0.38296 1.031-0.3708 1.414 0.027144 0.3535 0.36734 0.37034 0.935 0.057837 1.3214l-0.084982 0.092593-7.274 7c-0.35749 0.34402-0.90697 0.3704-1.2939 0.079191l-0.093141-0.079418-2.726-2.625c-0.39782-0.38309-0.40977-1.0161-0.026682-1.414 0.35362-0.36722 0.92023-0.40565 1.3182-0.10808l0.09576 0.081396 2.0324 1.9563 6.5806-6.3315z"
                fill="currentColor"
                fillRule="nonzero"
            />
        </g>
    </Icon>
);
