import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconGermany: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g transform="translate(2 3)">
            <path
                d="m1e-4 17.003c0 0.551 0.40545 0.997 0.90636 0.997h18.187c0.50091 0 0.90636-0.446 0.90636-0.997v-5.003h-20v5.003z"
                fill="#FFCE00"
            />
            <path d="m19.094 0h-18.187c-0.50091 0-0.90636 0.446-0.90636 0.997v5.003h20v-5.003c0-0.551-0.40545-0.997-0.90636-0.997" fill="#000" />
            <polygon points="0 12 20 12 20 6 0 6" fill="#D00" />
        </g>
    </Icon>
);
