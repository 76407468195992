import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconCheckCircle: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g transform="translate(2 2)">
            <path d="m20 10c0 5.5229-4.4771 10-10 10-5.5229 0-10-4.4771-10-10 0-5.5229 4.4771-10 10-10 5.5229 0 10 4.4771 10 10" fill="inherit" />
            <polyline points="15 7 7.726 14 5 11.375" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
    </Icon>
);
