const baseStyle = {
    mb: 1,
    color: "gray.800",
    fontWeight: "bold",
    fontSize: "md",
    lineHeight: "base",
    _disabled: {
        opacity: 1,
    },
};

export default {
    baseStyle,
};
