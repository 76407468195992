import { getColor, StyleFunctionProps } from "@chakra-ui/theme-tools";

const baseStyle = {
    field: {
        width: "100%",
        minWidth: 0,
        outline: 0,
        position: "relative",
        appearance: "none",
        transitionProperty: "common",
        transitionDuration: "normal",
    },
};

const size = {
    sm: {
        fontSize: "md",
        lineHeight: "base",
        px: 2,
        h: 5,
        borderRadius: "8px",
    },

    md: {
        fontSize: "md",
        lineHeight: "base",
        px: 2,
        h: 6,
        borderRadius: "8px",
    },

    lg: {
        fontSize: "md",
        lineHeight: "base",
        px: 2,
        h: 7,
        borderRadius: "8px",
    },
};

const sizes = {
    lg: {
        field: size.lg,
        addon: size.lg,
    },
    md: {
        field: size.md,
        addon: size.md,
    },
    sm: {
        field: size.sm,
        addon: size.sm,
    },
};

function variantOutline({ theme }: StyleFunctionProps) {
    const hoverBorderColor = "gray.800";
    const errorBorderColor = "red.700";

    return {
        field: {
            border: "1px",
            color: "gray.800",
            borderColor: "gray.400",
            _hover: {
                borderColor: hoverBorderColor,
            },
            _focus: {
                borderColor: hoverBorderColor,
                boxShadow: `0 0 0 1px ${getColor(theme, hoverBorderColor)}`,
            },
            _placeholder: {
                color: "gray.500",
            },
            _disabled: {
                opacity: 1,
                bg: "gray.50",
                _hover: {
                    borderColor: "gray.400",
                },
            },
            _invalid: {
                borderColor: errorBorderColor,
                bg: "blush.50",
                boxShadow: "none",
                _focus: {
                    borderColor: errorBorderColor,
                    boxShadow: `0 0 0 1px ${getColor(theme, errorBorderColor)}`,
                },
            },
        },
    };
}

const variants = {
    outline: variantOutline,
};

export default {
    baseStyle,
    sizes,
    variants,
};
