import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconYoutube: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M21.5820455 7.20034091C21.3520455 6.33420455 20.6744318 5.65215909 19.8138636 5.42068182 18.2542045 5 12 5 12 5 12 5 5.74579545 5 4.18613636 5.42068182 3.32568182 5.65215909 2.64795455 6.33420455 2.41795455 7.20034091 2 8.77011364 2 12.0454545 2 12.0454545 2 12.0454545 2 15.3206818 2.41795455 16.8906818 2.64795455 17.7567045 3.32568182 18.43875 4.18613636 18.6703409 5.74579545 19.0909091 12 19.0909091 12 19.0909091c0 0 6.2542045.0 7.813863600000001-.4205682000000017C20.6744318 18.43875 21.3520455 17.7567045 21.5820455 16.8906818 22 15.3206818 22 12.0454545 22 12.0454545 22 12.0454545 22 8.77011364 21.5820455 7.20034091"
            />
            <path fill="#fffffe" d="M9.955 15.019 15.182 12.046 9.955 9.072V15.019" />
        </g>
    </Icon>
);
