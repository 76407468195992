import { API_RESEND_REGISTRATION_MAIL_URL } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";

interface IResponse {
    success: boolean;
}

export const useResendRegistrationEmailMutation = () => {
    return useMutation<IResponse>(API_RESEND_REGISTRATION_MAIL_URL);
};
