import { useEffect } from "react";

interface IProps {
    isDisabled?: boolean;
    onFirstInteraction: () => void;
}

/**
 * This hook can be used to listen for the first user interaction on the page.
 *
 * We use it to e.g. determine when to show our disclaimer modal.
 */
export const useFirstInteraction = ({ isDisabled, onFirstInteraction }: IProps) => {
    useEffect(() => {
        if (isDisabled) {
            return;
        }

        const cleanupEventListener = () => {
            document.removeEventListener("mousemove", handleFirstInteraction);
            document.removeEventListener("scroll", handleFirstInteraction);
            document.removeEventListener("touchstart", handleFirstInteraction);
            document.removeEventListener("keydown", handleFirstInteraction);
        };

        const handleFirstInteraction = () => {
            onFirstInteraction();

            cleanupEventListener();
        };

        document.addEventListener("mousemove", handleFirstInteraction);
        document.addEventListener("scroll", handleFirstInteraction);
        document.addEventListener("touchstart", handleFirstInteraction);
        document.addEventListener("keydown", handleFirstInteraction);

        return cleanupEventListener;
    }, [isDisabled, onFirstInteraction]);
};
