import { useEffect } from "react";
import { useCustomActivityMutation } from "src/api/client/custom-activity.api";
import { isIsin } from "../utils/is-isin";

let lastCopiedIsin: string | null = null;

export function useCopyIsinTracking() {
    const createCustomActivity = useCustomActivityMutation();

    useEffect(() => {
        function handleCopyEvent() {
            const selection = window.getSelection()?.toString().trim();
            if (selection && isIsin(selection)) {
                if (lastCopiedIsin !== null && lastCopiedIsin === selection) {
                    return;
                }

                createCustomActivity({ activity: "CopiedIsin", value: selection });
                lastCopiedIsin = selection;
            }
        }

        document.addEventListener("copy", handleCopyEvent);

        return () => {
            document.removeEventListener("copy", handleCopyEvent);
        };
    }, [createCustomActivity]);
}
