import { API_NEWS_TICKER_URL } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";
import { IUser } from "src/types/common";

export interface INewsTickerItem {
    url: string;
    title: string;
    trader?: Pick<IUser, "firstName" | "lastName" | "isDeleted" | "nickName" | "id">;
    interaction?: string;
}

export type TNewsTickerResponse = INewsTickerItem[];

export const useNewsTickerQuery = () => useQuery<TNewsTickerResponse>(API_NEWS_TICKER_URL);
