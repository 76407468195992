import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconSwitzerland: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g transform="translate(2 3)">
            <path
                d="m19.094 18h-18.187c-0.50091 0-0.90636-0.446-0.90636-0.997v-16.006c0-0.551 0.40545-0.997 0.90636-0.997h18.187c0.50091 0 0.90636 0.446 0.90636 0.997v16.006c0 0.551-0.40545 0.997-0.90636 0.997"
                fill="red"
            />
            <line x1="10" x2="10" y1="4" y2="14" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line x1="5" x2="15" y1="9" y2="9" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
        </g>
    </Icon>
);
