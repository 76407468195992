import { useExtendedSearchUrl } from "src/components/common/typeahead/hooks/use-extended-search-url";
import { useNavigateOnSelect } from "src/components/common/typeahead/hooks/use-navigate-on-select";
import { useQueryWithSearchResults } from "src/components/common/typeahead/hooks/use-query-with-search-results";
import { ITypeaheadWrapper, Typeahead } from "src/components/common/typeahead/typeahead";
import { GlobalSearchResults } from "./global-nav-search-results";

export const GlobalTypeaheadWrapper = ({ onFocus, placement, placeholderText: searchPlaceholderText, enableAutoFocus }: ITypeaheadWrapper) => {
    const { query, setQuery, results, combinedResults, onClear } = useQueryWithSearchResults();
    const { handleSelect } = useNavigateOnSelect(results);
    const { getExtendedSearchUrl } = useExtendedSearchUrl();

    const handleSubmit = () => {
        // TODO: WIKI-24611
        window.location.href = getExtendedSearchUrl(query);
    };

    return (
        <Typeahead
            placeholderText={searchPlaceholderText}
            enableAutoFocus={enableAutoFocus}
            query={query}
            setQuery={setQuery}
            onFocus={onFocus}
            onClear={onClear}
            onSubmit={handleSubmit}
            combinedResults={combinedResults}
            onSelect={handleSelect}
        >
            {({ isOpen, selectedIndex }) =>
                isOpen && results && <GlobalSearchResults placement={placement} results={results} selectedIndex={selectedIndex} query={query} />
            }
        </Typeahead>
    );
};
