import { useRouter } from "next/router";
import { createConsentChangedUrl as createConsentUpdatedUrl } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { useRouterLocale } from "src/utils/router/use-router-locale";

export const useConsentChangedMutation = () => {
    const { language, country } = useRouterLocale();
    const router = useRouter();
    const { s_id } = router.query;
    const queryParams = s_id ? new URLSearchParams({ s_id: s_id as string }) : undefined;

    const { mutatePromise: consentUpdated } = useMutation<void, void, void>(createConsentUpdatedUrl(language, country), {
        queryParams,
    });

    return consentUpdated;
};
