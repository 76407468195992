import { useTranslation } from "next-i18next";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { IconAvatar } from "src/components/icons";
import { useGetUserName } from "src/hooks/use-get-user-name";
import { avatarSizeMap } from "src/theme/components/avatar";
import { IUser } from "src/types/common";
import { getAvatarColor } from "src/utils/avatar-util";
import { getImageBaseUrl } from "src/utils/responsive-image-util";
import { InitialsAvatar } from "./initials-avatar";
import { IWfAvatarProps, WfAvatar } from "./wf-avatar";

const TRADER_AVATAR_ICON_TEST_ID = "trader-avatar-icon";

export type IAvatarSize = keyof typeof avatarSizeMap;

export type ITraderAvatarUser = Pick<IUser, "id" | "isDeleted" | "nickName" | "firstName" | "lastName">;

interface ITraderAvatarProps extends Omit<IWfAvatarProps, "size"> {
    size: IAvatarSize | IAvatarSize[];
    trader: ITraderAvatarUser;
    href?: string;
}

export const getTraderAvatarUrl = (traderId: string) => `${getImageBaseUrl()}images/top-trader/${traderId}_profile.png?auto=format`;

const getAvatarIcon = (trader: ITraderAvatarUser, size: IAvatarSize | IAvatarSize[]) => {
    const avatarColor = getAvatarColor(trader.id);

    if (!trader.isDeleted && trader.firstName && trader.lastName) {
        return <InitialsAvatar size={size} firstName={trader.firstName} lastName={trader.lastName} bg={avatarColor} />;
    }

    return <IconAvatar data-test-id={TRADER_AVATAR_ICON_TEST_ID} color={avatarColor} boxSize="100%" aria-hidden focusable="false" />;
};

export const TraderAvatar = ({ trader, size, href, loading = "lazy", ...avatarProps }: ITraderAvatarProps) => {
    const { t } = useTranslation("common");
    const getUserName = useGetUserName();
    const displayName = getUserName(trader);
    const traderName = trader.isDeleted ? t("user-deleted") : t("user-avatar-of-x", { name: displayName });

    const baseProps: IWfAvatarProps = {
        boxSize: typeof size === "string" ? avatarSizeMap[size] : size.map(value => avatarSizeMap[value]),
        bg: "transparent",
        src: trader.isDeleted ? undefined : getTraderAvatarUrl(trader.id),
        icon: getAvatarIcon(trader, size),
        loading,
        name: traderName,
        ...avatarProps,
    };

    if (href && !trader.isDeleted) {
        return <WfAvatar as={WfLinkUnstyled} href={href} {...baseProps} aria-label={t("go-to-profile-of-x", { name: displayName })} />;
    }

    return <WfAvatar {...baseProps} />;
};
