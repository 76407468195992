import { memo } from "react";
import Head from "next/head";
import { IHead } from "src/types/layout";
import { PAGE_OG_TYPE } from "./constants";

const getOgImageMetaTags = (chartImageUrl?: string, customImageUrl?: string | null) => {
    if (chartImageUrl) {
        return (
            <>
                <meta property="og:image" content={chartImageUrl} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </>
        );
    }

    if (customImageUrl) {
        return (
            <>
                <meta property="og:image" content={customImageUrl} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </>
        );
    }

    return (
        <>
            <meta property="og:image" content="/Content/Images/logo_wikifolio-200x200.png" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
        </>
    );
};

export const WfHead = (props: IHead) => {
    const ogTitleContent = props.shortDescription ?? props.ogTitle;
    const {
        ogType = PAGE_OG_TYPE.website,
        title,
        description,
        chartImageUrl,
        customImageUrl,
        canonicalUrl,
        alternativeUrl,
        version,
        noIndex,
        serverName,
        traceId,
    } = props;

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="description" content={description} />
                {ogTitleContent ? <meta property="og:title" content={ogTitleContent} /> : null}
                <meta property="og:description" content={description} />
                <meta property="og:type" content={ogType} />
                <meta name="twitter:card" content="summary_large_image" />
                {getOgImageMetaTags(chartImageUrl, customImageUrl)}
                {canonicalUrl ? <meta property="og:url" content={canonicalUrl} /> : null}
                {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
                {alternativeUrl
                    ? Object.entries(alternativeUrl).map(([hrefLang, href]) => (
                          <link key={hrefLang} rel="alternate" href={href} hrefLang={hrefLang} />
                      ))
                    : null}
                <meta name="version" content={version} />
                <link rel="shortcut icon" href={`/favicon.ico?v=${version}`} />
                {noIndex ? <meta name="robots" content="noindex, follow" /> : null}
                {serverName ? <meta name="wf:server" content={serverName} /> : null}
                {traceId ? <meta name="wf:traceid" content={traceId} /> : null}
            </Head>
        </>
    );
};

export const WfHeadMemo = memo(WfHead);
