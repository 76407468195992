import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconChat: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(2 2)" fill="currentColor" fillRule="nonzero">
                <path d="m10 0c-5.523 0-10 4.477-10 10l0.006564 0.36309c0.065528 1.8102 0.61998 3.5382 1.5873 5.0371l0.12717 0.18978-0.79117 3.1676-0.020793 0.10589c-0.10528 0.73566 0.62185 1.3429 1.342 1.0729l3.0229-1.1343 0.20325 0.10804c1.3932 0.71099 2.9318 1.09 4.5228 1.09 5.523 0 10-4.477 10-10 0-5.523-4.477-10-10-10zm0 2c4.4184 0 8 3.5816 8 8 0 4.4184-3.5816 8-8 8-1.4667 0-2.8765-0.40276-4.1221-1.1541l-0.11546-0.05978c-0.23693-0.10374-0.50678-0.11227-0.75211-0.020282l-1.6793 0.62815 0.44187-1.7642c0.072974-0.29204 0.01051-0.60142-0.17006-0.84227-1.0349-1.3805-1.6028-3.0412-1.6028-4.7876 0-4.4184 3.5816-8 8-8z" />
                <path d="m11 7c0.55228 0 1 0.44772 1 1 0 0.51284-0.38604 0.93551-0.88338 0.99327l-0.11662 0.0067277h-5c-0.55228 0-1-0.44772-1-1 0-0.51284 0.38604-0.93551 0.88338-0.99327l0.11662-0.0067277h5z" />
                <path d="m14 11c0.55228 0 1 0.44772 1 1 0 0.51284-0.38604 0.93551-0.88338 0.99327l-0.11662 0.0067277h-8c-0.55228 0-1-0.44772-1-1 0-0.51284 0.38604-0.93551 0.88338-0.99327l0.11662-0.0067277h8z" />
            </g>
        </g>
    </Icon>
);
