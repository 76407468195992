import {
    // eslint-disable-next-line no-restricted-imports
    Link,
    LinkProps,
} from "@chakra-ui/react";
import { ButtonProps } from "src/components/base/button";

type Merge<P, T> = Omit<P, keyof T> & T;
type IButtonLinkProps = Merge<ButtonProps, LinkProps>;

export const ButtonLink = (props: IButtonLinkProps) => <Link as="button" {...props} />;
