import { ChangeEvent, ChangeEventHandler } from "react";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalProps, Skeleton, Stack, useBoolean } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { IAcceptDisclaimerRequest, IAcceptDisclaimerResponse, useAcceptDisclaimerMutation } from "src/api/client/accept-disclaimer.api";
import { IDisclaimerResponse, useDisclaimerQuery } from "src/api/client/disclaimer.api";
import { Span } from "src/components/base";
import { Button } from "src/components/base/button";
import { CountryFlag } from "src/components/base/country-flag";
import { WfSelect } from "src/components/base/wf-select";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { useDisclaimerStore } from "src/stores/disclaimer-store";
import { useRouterLocale } from "src/utils/router/use-router-locale";
import { getQueryString } from "src/utils/url-util";
import {
    DISCLAIMER_MODAL_ACCEPT_SUGGESTED_COUNTRY_TEST_ID,
    DISCLAIMER_MODAL_ACCEPT_TEST_ID,
    DISCLAIMER_MODAL_CANCEL_TEST_ID,
    DISCLAIMER_MODAL_COUNTRY_SELECT_TEST_ID,
} from "./disclaimer-modal.test-ids";

interface IDisclaimerModalProps extends Omit<ModalProps, "children"> {}

interface IUseDisclaimerModalArgs extends Pick<ModalProps, "isOpen" | "onClose"> {}

interface IUseDisclaimerModalResult {
    data?: IDisclaimerResponse;
    isLoading?: boolean;
    isButtonLoading?: boolean;
    showModal: boolean;
    onCountryChange?: ChangeEventHandler<HTMLSelectElement>;
    onClickAccept?: () => void;
    onClickAcceptCountry?: () => void;
    onClickCancel?: () => void;
}

const useDisclaimerModal = ({ ...modalProps }: IUseDisclaimerModalArgs): IUseDisclaimerModalResult => {
    const disclaimerStore = useDisclaimerStore();
    const { country } = useRouterLocale();
    const { t } = useTranslation("common");
    const toast = useWfToast();
    const router = useRouter();

    const [isButtonLoading, setButtonLoading] = useBoolean();

    const { data, isLoading, isError } = useDisclaimerQuery(modalProps.isOpen, disclaimerStore.options!, {
        onError: () => {
            toast({ status: EnumToastStatus.Error, title: t("general-error") });
            disclaimerStore.closeDisclaimer();
        },
    });

    const acceptDisclaimer = useAcceptDisclaimerMutation({
        onSuccess: (data: IAcceptDisclaimerResponse, params: IAcceptDisclaimerRequest) => {
            const selectedCountry = params.country;
            if (country !== selectedCountry) {
                if (data) {
                    const queryString = getQueryString(router);
                    window.location.href = data.newUrl + (queryString ? "?" + queryString : "");
                }
            }
        },
        onError: () => {
            toast({ status: EnumToastStatus.Error, title: t("general-error") });
            setButtonLoading.off();
        },
    });

    const onCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const country = e.target.value;
        if (!country) {
            return;
        }

        const { direct } = disclaimerStore.options!;
        disclaimerStore.tryChangeCountry(country, direct);
    };

    const onClickAccept = () => {
        const selectedCountry = disclaimerStore.options!.country;
        acceptDisclaimer({
            country: selectedCountry,
            url: location.pathname,
            storeCookie: true,
        });
        if (country === selectedCountry) {
            modalProps.onClose();
        } else {
            setButtonLoading.on();
        }
    };

    const onClickAcceptCountry = () => {
        const selectedCountry = data?.countrySuggestion.countryKey;
        acceptDisclaimer({
            country: selectedCountry!,
            url: location.pathname,
            storeCookie: false,
        });
        setButtonLoading.on();
    };

    const onClickCancel = () => {
        modalProps.onClose();
    };

    if (!modalProps.isOpen) {
        return {
            showModal: false,
        };
    }

    if (isError) {
        return {
            showModal: false,
        };
    }

    return {
        data,
        isLoading,
        isButtonLoading,
        showModal: true,
        onCountryChange,
        onClickAccept,
        onClickAcceptCountry,
        onClickCancel,
    };
};

export const DisclaimerModal = ({ ...modalProps }: IDisclaimerModalProps) => {
    const disclaimerModalProps = useDisclaimerModal({ ...modalProps });
    if (!disclaimerModalProps?.showModal) {
        return null;
    }

    return <DisclaimerModalContent {...disclaimerModalProps} {...modalProps} />;
};

interface IDisclaimerModalContentProps extends Omit<ModalProps, "children">, Omit<IUseDisclaimerModalResult, "showModal"> {}

export const DisclaimerModalContent = ({
    data,
    isLoading,
    isButtonLoading,
    onCountryChange,
    onClickAccept,
    onClickAcceptCountry,
    onClickCancel,
    ...modalProps
}: IDisclaimerModalContentProps) => {
    const { t } = useTranslation("common");

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false} autoFocus={false} {...modalProps}>
            <ModalOverlay />
            <ModalContent maxW={["full", "750px", "750px", "708px"]}>
                <ModalHeader pt={[8, 5]}>
                    <Span mr={1}>{data?.headline}</Span>{" "}
                    {data ? <CountryFlag aria-hidden={true} countryKey={data?.countryKey} boxSize="24px" mt={-0.5} /> : null}
                </ModalHeader>
                <ModalBody>
                    {!isLoading ? (
                        data?.showCountrySelect && (
                            <WfSelect
                                data-test-id={DISCLAIMER_MODAL_COUNTRY_SELECT_TEST_ID}
                                onChange={onCountryChange}
                                mb={3}
                                placeholder={t("change-country")}
                                w={["full", "fit-content"]}
                            >
                                {data?.countries.map(country => (
                                    <option key={country.value} value={country.value}>
                                        {country.label}
                                    </option>
                                ))}
                            </WfSelect>
                        )
                    ) : (
                        <Skeleton height={6} width="200px" maxW="100%" mb={3} />
                    )}
                    {!isLoading ? (
                        data && <SimpleRichText fontSize="xs" text={data?.content} />
                    ) : (
                        <>
                            <Skeleton height={9} width="100%" />
                            <Skeleton height={3} width="50%" />
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Stack direction={["column", "row-reverse"]} w={["100%", null]} spacing={2}>
                        <Button
                            isDisabled={isLoading}
                            isLoading={isButtonLoading}
                            onClick={onClickAccept}
                            data-test-id={DISCLAIMER_MODAL_ACCEPT_TEST_ID}
                        >
                            {data?.acceptText || t("accept")}
                        </Button>
                        {data?.showCancelButton && (
                            <Button
                                isDisabled={isButtonLoading}
                                variant="outline"
                                onClick={onClickCancel}
                                data-test-id={DISCLAIMER_MODAL_CANCEL_TEST_ID}
                            >
                                {data?.cancelText || t("cancel")}
                            </Button>
                        )}
                        {data?.countrySuggestion?.showSuggestion && (
                            <Button
                                isLoading={isButtonLoading}
                                variant="outline"
                                onClick={onClickAcceptCountry}
                                data-test-id={DISCLAIMER_MODAL_ACCEPT_SUGGESTED_COUNTRY_TEST_ID}
                            >
                                {data?.countrySuggestion.buttonText}
                            </Button>
                        )}
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
