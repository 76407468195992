import { PropsWithChildren } from "react";
import { Box, List, ListItem } from "@chakra-ui/react";
import { IWfLinkProps, WfLink } from "src/components/base/wf-link";

interface ISubMenuContainerProps {
    align: "left" | "right";
}
export const SubMenuContainer = ({ align, children }: PropsWithChildren<ISubMenuContainerProps>) => {
    return (
        <Box pos="absolute" bottom={0} {...{ [align]: 0 }}>
            <List
                bg="white"
                pos="absolute"
                py={1}
                {...{ [align]: 0 }}
                opacity={0}
                boxShadow="0 8px 8px 0 rgba(0,0,0,.09)"
                pointerEvents="none"
                transition="all 0.2s"
                borderBottomRadius="8px"
                borderBottomColor="transparent"
                role="menu"
            >
                {children}
            </List>
        </Box>
    );
};

interface ISubMenuLinkProps extends IWfLinkProps {
    isActive: boolean;
}

export const SubMenuLink = ({ isActive, ...linkProps }: ISubMenuLinkProps) => {
    return (
        <ListItem role="none">
            <WfLink
                className="gtm-nav-menu__link"
                variant="unstyled"
                display="flex"
                alignItems="center"
                role="menuitem"
                h={6}
                px={3}
                color={isActive ? "green.500" : undefined}
                whiteSpace="nowrap"
                transition="all 0.2s"
                _hover={{ bg: "gray.50" }}
                _active={{ bg: "gray.100" }}
                {...linkProps}
            />
        </ListItem>
    );
};
