import { API_LOGIN_URL } from "src/consts/urls";
import { IMutationOptions, useMutation } from "src/hooks/react-query/use-mutation";

interface IResponse {
    returnUrl?: string;
}

interface IRequest {
    email: string;
    password: string;
    keepLoggedIn: boolean;
    returnUrl?: string | null;
    returnPageNodeId?: number | null;
}

interface IError {
    [fieldName: string]: string;
}

export const useLoginMutation = (options: IMutationOptions<IResponse, IError, IRequest>) => {
    const { mutate: login } = useMutation(API_LOGIN_URL, options);
    return login;
};
