import { Stack } from "@chakra-ui/react";
import { useResendRegistrationEmailMutation } from "src/api/client/resend-registration-email.api";
import { FullSizeFlexCentered, SpanBox } from "src/components/base";
import { Button } from "src/components/base/button";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { IconMail, IconSuccessOutline } from "src/components/icons";
import { Banner } from "src/components/layout/banners/banner";
import { useBannerAction, EnumActionState } from "src/components/layout/banners/use-banner-action";
import { IVerifyEmailBanner } from "src/types/banners";

const VERIFY_EMAIL_BANNER_TEST_ID = "verify-email-banner";

interface IVerifyEmailBannerProps extends IVerifyEmailBanner {}

export const VerifyEmailBanner = ({ noticeText, buttonText, buttonSuccessText }: IVerifyEmailBannerProps) => {
    const resendRegistrationEmailMutation = useResendRegistrationEmailMutation();
    const { actionState, handleAction } = useBannerAction(resendRegistrationEmailMutation);

    const leftIcon = actionState === EnumActionState.READY ? <IconMail boxSize={3} /> : <IconSuccessOutline boxSize={3} color="green.600" />;

    return (
        <Banner data-test-id={VERIFY_EMAIL_BANNER_TEST_ID}>
            <Stack direction={["column", "row"]} spacing={[1, 3]} alignItems={["flex-start", "center"]} justifyContent="space-between">
                <SimpleRichText fontSize="sm" wordBreak="break-word" text={noticeText} />
                <Button
                    isLoading={actionState === EnumActionState.SENDING}
                    isDisabled={actionState === EnumActionState.SENT}
                    leftIcon={leftIcon}
                    variant="outline"
                    size="sm"
                    position="relative"
                    flexShrink={0}
                    onClick={handleAction}
                    w={["100%", "auto"]}
                >
                    <SpanBox color={actionState === EnumActionState.SENT ? "transparent" : undefined}>{buttonText}</SpanBox>
                    {actionState === EnumActionState.SENT && <FullSizeFlexCentered color="green.600">{buttonSuccessText}</FullSizeFlexCentered>}
                </Button>
            </Stack>
        </Banner>
    );
};
