import { memo } from "react";
import { VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IconChat, IconClose, IconMail, IconPhone } from "src/components/icons";
import { ServiceButtons } from "src/types/layout";
import { JumpToTopButton } from "./jump-to-top-button";
import { ServiceButton, useServiceButton } from "./service-button";
import { ServiceLink } from "./service-link";
import { useHelpscoutChat } from "./use-helpscout-chat";

export interface IServiceButtonsProps {
    serviceButtonsLabels?: ServiceButtons;
    showServiceButtons: boolean;
}

// Help Scout chat window has 1050 z-index and we want the buttons to be behind it
const SIDE_BUTTONS_Z_INDEX = 1049;

const SideButtons = ({ serviceButtonsLabels, showServiceButtons }: IServiceButtonsProps) => {
    const { t } = useTranslation("common");

    const { state: mailState, ref: mailRef } = useServiceButton();
    const { state: phoneState, ref: phoneRef } = useServiceButton();

    const { state: chatDisclosure, ref: chatRef } = useServiceButton();

    // this hook is taking care of rendering the chat depending on the state
    useHelpscoutChat(chatDisclosure, showServiceButtons);

    return (
        <VStack pos="fixed" align="flex-end" right={[3, 4]} bottom={[3, 4]} zIndex={SIDE_BUTTONS_Z_INDEX}>
            <JumpToTopButton isEnabled={!chatDisclosure.isOpen} />
            {showServiceButtons && serviceButtonsLabels && (
                <>
                    <ServiceButton
                        display={["none", "none", "inline-flex"]}
                        aria-label={t("send-email")}
                        opacity={chatDisclosure.isOpen ? 0 : 1}
                        ref={mailRef}
                        onClick={mailState.onToggle}
                    >
                        {mailState.isOpen && <ServiceLink href={"mailto:" + serviceButtonsLabels.email}>{serviceButtonsLabels.email}</ServiceLink>}
                        {mailState.isOpen ? <IconClose boxSize={3} mr={-0.5} /> : <IconMail boxSize={3} m={-2} />}
                    </ServiceButton>

                    <ServiceButton
                        display={["none", "none", "inline-flex"]}
                        aria-label={t("call-support")}
                        opacity={chatDisclosure.isOpen ? 0 : 1}
                        ref={phoneRef}
                        onClick={phoneState.onToggle}
                    >
                        {phoneState.isOpen && (
                            <ServiceLink className="gtm-phone" href={"tel:" + serviceButtonsLabels.phoneNumberTrimmed}>
                                {serviceButtonsLabels.phoneNumber}
                            </ServiceLink>
                        )}
                        {phoneState.isOpen ? <IconClose boxSize={3} mr={-0.5} /> : <IconPhone boxSize={3} m={-2} />}
                    </ServiceButton>

                    <ServiceButton aria-label={t("open-chat")} ref={chatRef} onClick={chatDisclosure.onToggle}>
                        {chatDisclosure.isOpen ? <IconClose boxSize={3} m={-2} /> : <IconChat boxSize={3} m={-2} />}
                    </ServiceButton>
                </>
            )}
        </VStack>
    );
};

export default memo(SideButtons);
