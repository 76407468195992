/*

Custom adaption on tabs:

The Original src:
https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/tabs.ts

*/

type IDict = Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

function baseStyleTab() {
    return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 6,
        px: 3,
        _focusVisible: {
            boxShadow: "focusVisible",
        },
    };
}

const baseStyle = () => ({
    tab: baseStyleTab(),
});

function variantLine(props: IDict) {
    const { colorScheme: c, orientation } = props;
    const isVertical = orientation === "vertical";
    const borderProp = isVertical ? "borderLeft" : "borderBottom";
    const marginProp = isVertical ? "ml" : "mb";

    return {
        tablist: {
            [borderProp]: "1px solid",
            borderColor: "gray.300",
        },
        tab: {
            [borderProp]: "3px solid",
            borderColor: "transparent",
            [marginProp]: "0px",
            _selected: {
                fontWeight: 700,
                color: "gray.800",
                borderColor: `${c}.500`,
            },
            _hover: {
                bg: "blackAlpha.100",
            },
            _active: {
                bg: "blackAlpha.300",
            },
        },
    };
}

function variantFull() {
    return {
        tablist: {
            display: "flex",
            alignItems: "center",
            backgroundColor: "gray.100",
            height: 5,
            borderRadius: "6px",
            padding: 0.5,
        },
        tab: {
            height: 4,
            borderRadius: "4px",
            width: "full",
            fontSize: "sm",
            fontWeight: "bold",
            color: "gray.600",
            padding: 0,
            _selected: {
                backgroundColor: "white",
                color: "gray.800",
            },
        },
    };
}

const variants = {
    line: variantLine,
    full: variantFull,
};

const defaultProps = {
    colorScheme: "green",
};

export default {
    baseStyle,
    variants,
    defaultProps,
};
