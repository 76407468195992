import { Box, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";
import { HashLink } from "src/components/base/hash-link";
import { ThirdPartyAuthButtons, EnumPlacement } from "src/components/common/auth";
import { getGlobals } from "src/consts/globals";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import { LOGIN_OVERVIEW_BUTTONS_TEST_ID, LOGIN_OVERVIEW_EMAIL_BUTTON_TEST_ID, LOGIN_OVERVIEW_REGISTER_LINK_TEST_ID } from "./login-overview.test-ids";

export const LoginOverview = () => {
    const { t } = useTranslation("common");
    const { buildRegisterModalLink } = useLinkBuilder();
    const { showEmailLoginForm, setShowEmailLoginForm } = useAuthModalStore();
    const loginProviders = getGlobals().loginProviders;

    if (showEmailLoginForm) {
        return null;
    }

    return (
        <>
            <Stack spacing={2} px={[0, 4, 5]} pb={[4, 5]} data-test-id={LOGIN_OVERVIEW_BUTTONS_TEST_ID}>
                <Button variant="outline" onClick={() => setShowEmailLoginForm(true)} data-test-id={LOGIN_OVERVIEW_EMAIL_BUTTON_TEST_ID}>
                    {t("login-with-email")}
                </Button>
                {loginProviders?.length && (
                    <ThirdPartyAuthButtons placement={EnumPlacement.LoginOverview} loginProviders={loginProviders} isLoginLabel />
                )}
            </Stack>
            <Box textAlign="center" fontSize="sm">
                <Text mb={1}>{t("no-user-yet")}</Text>
                <HashLink href={buildRegisterModalLink()} data-test-id={LOGIN_OVERVIEW_REGISTER_LINK_TEST_ID}>
                    {t("register-for-free")}
                </HashLink>
            </Box>
        </>
    );
};
