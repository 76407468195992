import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { IGlobal } from "src/types/layout";
import { initGtm } from "src/utils/gtm-utils";

export function useGtm(global: IGlobal | null, data: unknown) {
    // Dev-Note: needs to be refactored when switching to client-side rendering
    useEffect(() => {
        if (global && data) {
            TagManager.initialize(initGtm(global.gtmData));
        }
    }, [data, global]);
}
