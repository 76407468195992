import { PropsWithChildren } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { CONTAINER_PX, CONTAINER_W } from "src/components/layout/container";

export interface IBannerProps extends BoxProps {}

export const Banner = ({ children, ...boxProps }: PropsWithChildren<IBannerProps>) => {
    const [, ...width] = CONTAINER_W;
    const [, ...px] = CONTAINER_PX;

    return (
        <Box w={["auto", ...width]} px={[2, ...px]} py={[2, 3]} mx={[3, "auto"]} my="2" boxShadow="sm" rounded={8} {...boxProps}>
            {children}
        </Box>
    );
};
