import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { IconFacebook, IconGoogle, IconApple } from "src/components/icons";
import { createThirdPartyAuthUrl } from "src/consts/urls";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import { IGlobal, EnumProviders } from "src/types/layout";

/** Where in the app is used ThirdPartyAuthButtons, used for tracking */
export enum EnumPlacement {
    HeaderForHome = "header-for-home",
    RegisterModal = "register-modal",
    RegisterModule = "register-module",
    LoginOverview = "login-overview",
}

export interface IThirdPartyAuthButtonsProps {
    loginProviders: IGlobal["loginProviders"];
    placement: EnumPlacement;
    isLoginLabel?: boolean;
    width?: string;
}

export interface IProviderConfigValues {
    icon: JSX.Element;
    labelLogin: string;
    labelRegister: string;
    width?: string;
}

export const providerConfig: Record<EnumProviders, IProviderConfigValues> = {
    [EnumProviders.Facebook]: {
        icon: <IconFacebook boxSize={3} />,
        labelLogin: "login-with-fb",
        labelRegister: "register-with-fb",
    },
    [EnumProviders.Google]: {
        icon: <IconGoogle boxSize={3} />,
        labelLogin: "login-with-google",
        labelRegister: "register-with-google",
    },
    [EnumProviders.Apple]: {
        icon: <IconApple boxSize={3} />,
        labelLogin: "login-with-apple",
        labelRegister: "register-with-apple",
    },
};

export const ThirdPartyAuthButtons = ({ isLoginLabel = false, width, placement, loginProviders }: IThirdPartyAuthButtonsProps) => {
    const { t } = useTranslation("common");
    const { returnUrl } = useAuthModalStore();

    return (
        <>
            {loginProviders.map(loginProvider => {
                if (!providerConfig[loginProvider]) {
                    return null;
                }

                const { icon, labelLogin, labelRegister } = providerConfig[loginProvider];

                return (
                    <Button
                        width={width}
                        href={createThirdPartyAuthUrl(loginProvider, returnUrl)}
                        key={loginProvider}
                        as={WfLinkUnstyled}
                        variant="outline"
                        leftIcon={icon}
                        className="gtm-register"
                        data-gtm-action={`${placement}-${loginProvider.toLowerCase()}`}
                    >
                        {isLoginLabel ? t(labelLogin) : t(labelRegister)}
                    </Button>
                );
            })}
        </>
    );
};
