import { MouseEvent } from "react";

export const eventStopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
};

interface IScrollOptions {
    top?: number;
    left?: number;
}

export const scrollToTheTop = ({ top = 0, left = 0 }: IScrollOptions = {}) => {
    window.scrollTo({
        top,
        left,
        behavior: "smooth",
    });
};
