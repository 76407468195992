import { ReactNode } from "react";
import { Box, Stack, forwardRef } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { MotionBox, MotionBoxProps } from "src/components/base";
import { Button } from "src/components/base/button";
import { NAV_SEARCH_TEST_ID } from "./global-nav-search-ui.test-ids";

interface IProps extends MotionBoxProps {
    navHeights: number[];
    onSearchClose: () => void;
    searchPlaceholderText?: string;
    children: ReactNode;
}

export const GlobalNavSearchUi = forwardRef(({ navHeights, onSearchClose, children, ...otherProps }: IProps, ref) => {
    const { t } = useTranslation("common");

    return (
        <MotionBox
            position="fixed"
            top={0}
            right={0}
            left={[0, 0, "214px", "204px"]}
            height={["100vh", "100vh", navHeights[2], navHeights[3]]}
            bg="white"
            zIndex="overlay"
            p={[3, 5, 1]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            data-test-id={NAV_SEARCH_TEST_ID}
            {...otherProps}
        >
            <Stack
                ref={ref}
                h="100%"
                direction={["column", "column", "row-reverse"]}
                align={["flex-end", "flex-end", "flex-start"]}
                justify={["initial", "initial", "space-between"]}
                spacing={[2, 2, 8]}
            >
                <Button
                    alignSelf={["flex-end", "flex-end", "center", "center"]}
                    variant="ghost"
                    size="sm"
                    mr={[-2, -2, 3]}
                    mt={[-2, -2, 0]}
                    onClick={onSearchClose}
                >
                    {t("close")}
                </Button>
                <Box pt={1} w={["100%", "100%", "720px", "820px"]}>
                    {children}
                </Box>
            </Stack>
        </MotionBox>
    );
});
