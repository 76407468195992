import { MouseEvent } from "react";
import { Badge, Flex, FlexProps, IconProps, List, ListItem } from "@chakra-ui/react";
import { useRouter } from "next/dist/client/router";
import { useTranslation } from "next-i18next";
import { SpanBox } from "src/components/base";
import { IconCaret } from "src/components/icons";
import { CLASS_NAV_ICON_CARET, CLASS_NAV_UNDERLINE } from "src/consts/dom";
import { INav, INavLink } from "src/types/layout";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";
import { MenuLink } from "./menu-link";
import { SubMenuContainer, SubMenuLink } from "./sub-menu";
import { useDesktopNav } from "./use-desktop-menu";
import { useNavigationTracking } from "./use-navigation-tracking";

interface IActiveIndicatorProps {
    item: INavLink;
    currentPath: string;
}

const ActiveIndicator = ({ item, currentPath }: IActiveIndicatorProps) => {
    const isSelfOrChildActive = () => {
        if (item.link.url === currentPath) {
            return true;
        }

        return item.children?.some(child => child.link.url === currentPath);
    };

    return (
        <SpanBox
            pos="absolute"
            bottom={0}
            w="full"
            h="2px"
            rounded="1.5px"
            bg="current"
            opacity={isSelfOrChildActive() ? 1 : 0}
            transition="opacity 0.2s"
            className={CLASS_NAV_UNDERLINE}
        />
    );
};

interface IProps extends FlexProps {
    nav: INav;
    isSearchOpen: boolean;
}

export const MenuDesktop = ({ nav, isSearchOpen, ...flexProps }: IProps) => {
    const { t } = useTranslation("common");
    const path = useRouter().asPath;
    const { sx, handleSubMenuItemClick } = useDesktopNav();
    const { trackMainNavClick, trackSubNavClick } = useNavigationTracking();

    const onSubMenuLinkClicked = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>, url: string) => {
        trackSubNavClick(url);
        handleSubMenuItemClick(event);
    };

    if (isSearchOpen) {
        return null;
    }

    return (
        <Flex as="nav" flexGrow={1} {...flexProps}>
            <List display="flex" role="menubar">
                {nav.main.map(item => (
                    <ListItem key={item.link.url} pos="relative" role="none" sx={sx}>
                        <MenuLink
                            onClick={() => trackMainNavClick(item.link.url)}
                            onAuxClick={triggerCallbackOnMiddleClickEvent(() => trackMainNavClick(item.link.url))}
                            target={item.link.target}
                            href={item.link.url}
                            hasChildElements={item.children.length > 0}
                        >
                            {item.link.name}
                            <ActiveIndicator item={item} currentPath={path} />
                        </MenuLink>
                        {item.children.length > 0 && (
                            <SubMenuContainer align="left">
                                {item.children.map(({ link, isNew }) => (
                                    <SubMenuLink
                                        key={link.url}
                                        href={link.url}
                                        target={link.target}
                                        isActive={link.url === path}
                                        onClick={event => {
                                            onSubMenuLinkClicked(event, link.url);
                                        }}
                                        onAuxClick={triggerCallbackOnMiddleClickEvent(event => onSubMenuLinkClicked(event, link.url))}
                                    >
                                        {link.name}
                                        {isNew && (
                                            <Badge colorScheme="green" size="sm" ml={1}>
                                                {t("new")}
                                            </Badge>
                                        )}
                                    </SubMenuLink>
                                ))}
                            </SubMenuContainer>
                        )}
                    </ListItem>
                ))}
            </List>
        </Flex>
    );
};

export const NavIconCaret = (props: Omit<IconProps, "css" | "children">) => (
    <IconCaret boxSize={2} transition="transform 0.2s" className={CLASS_NAV_ICON_CARET} {...props} />
);
