import { MouseEvent as ReactMouseEvent, useEffect, useState } from "react";
import { CLASS_NAV_ICON_CARET, CLASS_NAV_UNDERLINE } from "src/consts/dom";

const VISIBLE_SUB_MENU = {
    "&:focus-within ul, &:hover ul": {
        opacity: 1,
        pointerEvents: "auto",
    },
    [`&:hover .${CLASS_NAV_ICON_CARET}`]: {
        transform: "rotate(180deg)",
    },
    [`&:hover .${CLASS_NAV_UNDERLINE}`]: {
        opacity: 1,
    },
};

export const useDesktopNav = () => {
    const [isOpenable, setOpenable] = useState(true);

    useEffect(() => {
        if (!isOpenable) {
            setTimeout(() => {
                setOpenable(true);
            }, 200);
        }
    }, [isOpenable]);

    const handleSubMenuItemClick = (event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
        event.currentTarget.blur();
        setOpenable(false);
    };

    return {
        sx: isOpenable ? VISIBLE_SUB_MENU : undefined,
        handleSubMenuItemClick,
    };
};
