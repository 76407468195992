// eslint-disable-next-line no-restricted-imports
import { SystemStyleObject } from "@chakra-ui/system";

const baseStyle: SystemStyleObject = {
    zIndex: "skipLink",
    fontSize: "xl",
    fontWeight: "semibold",
    borderRadius: "unset",
    borderBottomRightRadius: "8px",
    color: "white",
    _focus: {
        top: "unset",
        left: "unset",
        padding: [4, 4],
        bgColor: "gray.800",
    },
};

export default { baseStyle };
