import { memo } from "react";
import { Box, useBoolean } from "@chakra-ui/react";
import { IBanners } from "src/types/layout";
import { DoubleOptInBanner } from "./double-opt-in-banner";
import { LtsuBanner } from "./ltsu-banner";
import { StickyBanner } from "./sticky-banner";
import { VerifyEmailBanner } from "./verify-email-banner";

interface IBannerSectionProps {
    banners: IBanners;
}

const BannerSection = ({ banners }: IBannerSectionProps) => {
    const { stickyBanner, verifyEmailBanner, doubleOptInBanner, ltsuBanner } = banners;
    const [isStickyBannerVisible, setStickyBannerVisibility] = useBoolean(Boolean(stickyBanner));

    const isAnyBannerVisible = isStickyBannerVisible || verifyEmailBanner || doubleOptInBanner || ltsuBanner;
    if (!isAnyBannerVisible) {
        return null;
    }

    return (
        <Box pt={1}>
            {isStickyBannerVisible && stickyBanner && (
                <StickyBanner
                    onCloseBanner={setStickyBannerVisibility.off}
                    nodeId={stickyBanner?.nodeId}
                    image={stickyBanner.image}
                    headline={stickyBanner.headline}
                    description={stickyBanner.description}
                    button={stickyBanner.button}
                />
            )}
            {verifyEmailBanner && (
                <VerifyEmailBanner
                    noticeText={verifyEmailBanner.noticeText}
                    buttonText={verifyEmailBanner.buttonText}
                    buttonSuccessText={verifyEmailBanner?.buttonSuccessText}
                />
            )}
            {doubleOptInBanner && <DoubleOptInBanner emailSettingsPageUrl={doubleOptInBanner.emailSettingsPage} />}
            {ltsuBanner && <LtsuBanner noticeText={ltsuBanner.noticeText} link={ltsuBanner.link} />}
        </Box>
    );
};

export const BannerSectionMemo = memo(BannerSection);
