import cleanObjectForSerialization from "clean-yaml-object";
import pino from "pino";
// Added the cleanObjectForSerialization dependency here so that it is not excluded from the final build using the output file tracing feature.
import { isServerSide } from "./common-util";

const WRITING_TO_ELASTIC_ERROR_MESSAGE = "An error occurred writing to elastic: ";

let logger = pino({});

interface ICustomConsole extends Console {
    _error: (message?: string, ...args: unknown[]) => void;
}

if (isServerSide()) {
    const elasticEnabled = process.env.LOGGING_ELASTIC_ENABLED === "true";
    if (elasticEnabled) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const pinoElastic = require("pino-elasticsearch");
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const ecsFormat = require("@elastic/ecs-pino-format");
        const streamToElastic = pinoElastic({
            index: process.env.LOGGING_ELASTIC_INDEX,
            consistency: "one",
            node: process.env.LOGGING_ELASTIC_HOST?.split(",")[0],
            "es-version": 7,
            "flush-interval": 10000, // logs are being sent every 10 seconds
        });

        // Dev-Note: In case the logs can't be inserted into elastic (mapping missmatch for example), log
        // to console. See https://github.com/pinojs/pino-elasticsearch#troubleshooting
        streamToElastic.on("insertError", (error: Error & { document: Record<string, unknown> }) => {
            const documentThatFailed = error.document;
            // Dev-Note: In setup-logging.ts we monkey patch the original `console.log`. Using this one would
            // lead to an infinite loop. Use the original one instead, which has been stored with an "_" prefix.
            if ("_error" in console) {
                (console as ICustomConsole)._error(WRITING_TO_ELASTIC_ERROR_MESSAGE, documentThatFailed, error.message);
            } else {
                // eslint-disable-next-line no-console
                console.error(WRITING_TO_ELASTIC_ERROR_MESSAGE, documentThatFailed, error.message);
            }
        });
        streamToElastic.on("error", (error: Error) => {
            // Dev-Note: In setup-logging.ts we monkey patch the original `console.log`. Using this one would
            // lead to an infinite loop. Use the original one instead, which has been stored with an "_" prefix.
            if ("_error" in console) {
                (console as ICustomConsole)._error(WRITING_TO_ELASTIC_ERROR_MESSAGE, error.message);
            } else {
                // eslint-disable-next-line no-console
                console.error(WRITING_TO_ELASTIC_ERROR_MESSAGE, error.message);
            }
        });

        const pinoOptions = ecsFormat({ apmIntegration: false });

        // Dev-Note: we reconfigure some of the ecsFormat settings to be compliant with other fields in the wikifolio elastic index
        pinoOptions.formatters.level = function (label: string) {
            return { level: label.charAt(0).toUpperCase() + label.slice(1) };
        };
        pinoOptions.formatters.bindings = (bindings: unknown) => {
            const ecsBindings = ecsFormat({ apmIntegration: false }).formatters.bindings(bindings);
            ecsBindings.container = { id: ecsBindings.host.hostname };
            delete ecsBindings.host;
            ecsBindings.processId = ecsBindings.process.pid;
            delete ecsBindings.process;
            return { ...ecsBindings, hostName: process.env.HOST_NAME, processName: "NextJS" };
        };
        pinoOptions.serializers = {
            err: pino.stdSerializers.err,
        };

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { multistream } = require("pino-multi-stream");

        logger = pino(pinoOptions, multistream([{ stream: process.stdout }, { stream: streamToElastic }]));

        const minLevel = process.env.LOGGING_ELASTIC_MINLEVEL || "trace";
        logger.level = minLevel.toLowerCase();

        logger = logger.child({ logger: "pino" });
    }
}

const cleanObjectForLogging = (object: unknown) => cleanObjectForSerialization(object);

export { logger, cleanObjectForLogging };
