import { Dispatch, SetStateAction, useState } from "react";
import { useBoolean } from "@chakra-ui/react";

export type ISetOpen = {
    on: () => void;
    off: () => void;
};

export type ITypeaheadState = {
    isOpen: boolean;
    selectedIndex: number | null;
    setOpen: ISetOpen;
    setSelectedIndex: Dispatch<SetStateAction<number | null>>;
};
export const useTypeaheadState = (): ITypeaheadState => {
    const [isOpen, setOpen] = useBoolean(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    return {
        isOpen,
        selectedIndex,
        setOpen,
        setSelectedIndex,
    };
};
