import { memo } from "react";
import { AuthModal } from "src/components/modals/auth-modal/auth-modal";
import { useAuthModal } from "src/components/modals/auth-modal/hooks/use-auth-modal";
import { DisclaimerModal } from "src/components/modals/disclaimer-modal/disclaimer-modal";
import { useToastQueryErrors, useToastCookieErrors } from "src/hooks/toast-errors";
import { useDisclaimerStore } from "src/stores/disclaimer-store";
import { useUserStore } from "src/stores/user-store";

/**
 * This component is rendered on every page,
 * reads the url params and renders an overlay component based on url params
 */
const Overlay = () => {
    const { isLoggedIn } = useUserStore();
    const { shouldOpenLoginModal, shouldOpenRegisterModal, shouldOpenRegisterSsoModal, closeAuthModal } = useAuthModal();
    const disclaimerStore = useDisclaimerStore();
    useToastQueryErrors();
    useToastCookieErrors();
    const isAuthModalOpen = !isLoggedIn && [shouldOpenLoginModal, shouldOpenRegisterModal, shouldOpenRegisterSsoModal].some(Boolean);

    return (
        <>
            <AuthModal
                isOpen={isAuthModalOpen}
                isLoginModalOpen={shouldOpenLoginModal}
                isRegisterSsoModalOpen={shouldOpenRegisterSsoModal}
                onClose={closeAuthModal}
            />

            <DisclaimerModal
                isOpen={disclaimerStore.showDisclaimer}
                onClose={() => {
                    disclaimerStore.closeDisclaimer();
                }}
            />
        </>
    );
};

export const OverlayContainer = memo(Overlay);
