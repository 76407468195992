import { Badge, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ISearchWikifolio } from "src/api/client/search-autocomplete.api";
import { Span } from "src/components/base";
import { Button } from "src/components/base/button";
import { WfLink } from "src/components/base/wf-link";
import { Highlight } from "src/components/common/highlight";
import { ColorKpi } from "src/components/common/ranking/color-kpi";
import { IconWikifolio } from "src/components/icons";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { IAutoCompleteTrader } from "src/types/common";
import { EnumUserStatus } from "src/types/common-enum";
import { getTrackingUrlParam } from "src/utils/search-tracking-util";
import { getFullName } from "src/utils/user-name-util";
import { IPlacement } from "../typeahead.types";

const NAV_WIKIFOLIO_SEARCH_ITEM_TEST_ID = "nav-wikifolio-search-item";

interface IWikifolioSearchItem {
    isSelected?: boolean;
    query?: string;
    placement: IPlacement;
}

const useGetAutoCompleteTraderName = () => {
    const { t } = useTranslation("common");

    return (trader: IAutoCompleteTrader, separator = " · ") => {
        if (trader.userStatus === EnumUserStatus.DeletionRequested || trader.userStatus === EnumUserStatus.Deleted) {
            return t("user-deleted");
        }

        const fullName = getFullName(trader);

        return fullName ? `${fullName}${separator}${trader.nickName}` : trader.nickName;
    };
};

export const WikifolioSearchItem = ({
    resourceIdentifier,
    shortDescription,
    performance,
    trader,
    isSelected = false,
    query,
    placement,
}: Omit<IWikifolioSearchItem & ISearchWikifolio, "id">) => {
    // TODO: after implementing https://wikifolio.atlassian.net/browse/WIKI-26219 please use `formatPerformance` instead of `formatPercentPerformance`
    const { formatPercentPerformance } = useNumberFormatter();
    const { buildWikifolioLink } = useLinkBuilder();
    const getAutoCompleteTraderName = useGetAutoCompleteTraderName();

    return (
        <WfLink
            px={[1, 3, 3, 3]}
            variant="unstyled"
            display="flex"
            href={buildWikifolioLink(resourceIdentifier, getTrackingUrlParam({ placement, query }))}
            py={2}
            backgroundColor={isSelected ? "gray.50" : undefined}
            _hover={{ bg: "gray.50" }}
            data-test-id={NAV_WIKIFOLIO_SEARCH_ITEM_TEST_ID}
        >
            <IconWikifolio boxSize={2} color="gray.200" mt={0.5} mr={1} />
            <Stack as="span" flexGrow={1} direction={["column", "row"]} align="baseline" spacing={[0.5, 1]} overflow="hidden">
                <Span maxW="100%" noOfLines={1} wordBreak="break-all">
                    <Highlight source={shortDescription} target={query} />
                </Span>
                <Span borderLeft={["none", "1px"]} pl={[0, 1]} fontSize="xs" maxW="100%" noOfLines={1} wordBreak="break-all">
                    <Highlight source={getAutoCompleteTraderName(trader)} target={query} />
                </Span>
            </Stack>
            <ColorKpi as="span" ml={1}>
                {formatPercentPerformance(performance, 1)}
            </ColorKpi>
        </WfLink>
    );
};

interface IWikifolioSearchItemButton extends IWikifolioSearchItem, ISearchWikifolio {
    onClick: () => void;
}

export const WikifolioSearchItemButton = ({
    shortDescription,
    trader,
    isSelected = false,
    query,
    onClick,
}: Omit<IWikifolioSearchItemButton, "placement" | "id">) => {
    const { t } = useTranslation("common");
    const getAutoCompleteTraderName = useGetAutoCompleteTraderName();

    return (
        <Button
            pr={[2, 3]}
            pl={[2, 3]}
            pt={[4, 3]}
            pb={[4, 3]}
            w="100%"
            borderRadius="0"
            variant="unstyled"
            display="flex"
            backgroundColor={isSelected ? "gray.50" : undefined}
            _hover={{ bg: "gray.50" }}
            className="gtm-chart-analyze"
            onClick={onClick}
        >
            <IconWikifolio boxSize={2} color="gray.200" mt={[-2, 0.5]} mr={1} />
            <Stack as="span" flexGrow={1} direction={["column", "row"]} align="baseline" spacing={[0.5, 1]} overflow="hidden">
                <Span maxW="100%" noOfLines={1} wordBreak="break-all">
                    <Highlight source={shortDescription} target={query} />
                </Span>
                <Span borderLeft={["none", "1px"]} pl={[0, 1]} fontSize="xs" maxW="100%" fontWeight="normal" noOfLines={1} wordBreak="break-all">
                    <Highlight source={getAutoCompleteTraderName(trader)} target={query} />
                </Span>
            </Stack>
            <Badge pr={2} pl={2} variant="outline" colorScheme="green">
                {t("wikifolio")}
            </Badge>
        </Button>
    );
};
