import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconInstagram: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12.544 3.006l3.163.054c.958.044 1.612.196 2.184.418a4.41 4.41 0 0 1 1.594 1.038 4.41 4.41 0 0 1 1.038 1.594c.222.572.375 1.226.418 2.184l.053 2.69v2.04l-.053 2.69c-.044.958-.196 1.612-.418 2.184a4.41 4.41 0 0 1-1.038 1.594 4.41 4.41 0 0 1-1.594 1.038c-.572.222-1.226.375-2.184.418l-2.69.053h-2.04l-2.69-.053c-.958-.044-1.612-.196-2.184-.418A4.41 4.41 0 0 1 4.51 19.49a4.41 4.41 0 0 1-1.038-1.594c-.222-.572-.375-1.226-.418-2.184L3 12.55v-1.094l.054-3.163c.044-.958.196-1.612.418-2.184A4.41 4.41 0 0 1 4.51 4.516a4.41 4.41 0 0 1 1.594-1.038c.572-.222 1.226-.375 2.184-.418l3.163-.054zm-.01 1.62H11.46l-3.098.052c-.877.04-1.354.187-1.67.3-.42.163-.72.358-1.034.673s-.5.615-.673 1.034c-.123.317-.27.793-.3 1.67l-.052 3.098v1.075l.052 3.098c.04.877.187 1.354.3 1.67.163.42.358.72.673 1.035s.615.5 1.034.673c.317.123.793.27 1.67.3l2.857.052h1.556l2.857-.052c.877-.04 1.354-.187 1.67-.3.42-.163.72-.358 1.035-.673s.5-.615.673-1.035c.123-.317.27-.793.3-1.67l.052-2.857v-1.556l-.052-2.857c-.04-.877-.187-1.354-.3-1.67-.163-.42-.358-.72-.673-1.034s-.615-.5-1.035-.673c-.317-.123-.793-.27-1.67-.3l-3.098-.052zm-.537 2.756a4.62 4.62 0 1 1 0 9.24 4.62 4.62 0 1 1 0-9.24zm0 1.62a3 3 0 0 0-2.999 2.999 3 3 0 0 0 2.999 2.999 3 3 0 0 0 2.999-2.999 3 3 0 0 0-2.999-2.999zM16.8 6.12a1.08 1.08 0 0 1 1.08 1.08 1.08 1.08 0 0 1-1.08 1.08 1.08 1.08 0 0 1-1.08-1.08 1.08 1.08 0 0 1 1.08-1.08z"
            />
        </g>
    </Icon>
);
