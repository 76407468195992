import { ParsedUrlQuery } from "querystring";

const LOCALES: { [key: string]: ILocale } = {
    de_de: {
        language: "de",
        country: "de",
    },
    de_at: {
        language: "de",
        country: "at",
    },
    de_ch: {
        language: "de",
        country: "ch",
    },
    en_int: {
        language: "en",
        country: "int",
    },
};
type ILocaleKey = keyof typeof LOCALES;

export const isValidLocale = ({ language, country }: ParsedUrlQuery) =>
    typeof language === "string" &&
    typeof country === "string" &&
    Object.keys(LOCALES).some(key => {
        const locale = LOCALES[key as ILocaleKey];
        return locale.language === language.toLowerCase() && locale.country === country.toLowerCase();
    });

/**
 * Converts a locale of format de-at to { country: de, language: at }
 * @param locale string with format <country>-<language>
 * @returns Locale object with country and language properties
 */
export const extractLocale = (locale: string): ILocale => {
    const [langKey, countryKey] = locale.split("-");
    const key = `${langKey}_${countryKey}` as ILocaleKey;
    return { ...LOCALES[key] };
};

const DEFAULT_LOCALE = { ...LOCALES.en_int };

// default static paths for simple static sites including all possible locales
export const DEFAULT_STATIC_PATHS = {
    paths: (Object.keys(LOCALES) as ILocaleKey[]).map(key => {
        const locale = LOCALES[key];
        return { params: locale };
    }),
    fallback: false,
};

export const getDefaultLocale = (): ILocale => {
    return { ...DEFAULT_LOCALE };
};

export const LANGUAGE = { DE: "de", EN: "en" } as const;
export type ILanguage = (typeof LANGUAGE)[keyof typeof LANGUAGE];
export type ICountry = "de" | "at" | "ch" | "int";

export interface ILocale {
    language: ILanguage;
    country: ICountry;
}

export const COUNTRY_PARAMETER = "country";
export const COUNTRY_INT = "int";
