import { UseMutationOptions } from "react-query";
import { API_CHANGE_CURRENCY_MODE_URL } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { IErrorResponse } from "./types";

interface IResponse {
    success: boolean;
}

export const useChangeCurrencyMode = (options: UseMutationOptions<IResponse, IErrorResponse, unknown>) => {
    const { mutate: changeCurrency } = useMutation<IResponse, IErrorResponse>(API_CHANGE_CURRENCY_MODE_URL, options);

    return changeCurrency;
};
