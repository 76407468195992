import {
    // eslint-disable-next-line no-restricted-imports
    Link,
    LinkProps,
    forwardRef,
} from "@chakra-ui/react";

export interface IWfLinkProps extends LinkProps {
    href: string;
}

export interface IWfLinkVariantProps extends Omit<IWfLinkProps, "variant"> {}

export const WfLink = forwardRef<IWfLinkProps, "a">(({ children, href, ...linkProps }, ref) => {
    return (
        <Link ref={ref} href={href} {...linkProps}>
            {children}
        </Link>
    );
});

export const WfLinkUnstyled = forwardRef<IWfLinkVariantProps, "a">(({ children, href, ...linkProps }, ref) => {
    return (
        <Link ref={ref} href={href} {...linkProps} variant="unstyled">
            {children}
        </Link>
    );
});

export const WfLinkGhost = forwardRef<IWfLinkVariantProps, "a">(({ children, href, ...linkProps }, ref) => {
    return (
        <Link ref={ref} href={href} {...linkProps} variant="ghost">
            {children}
        </Link>
    );
});
