import { useEffect } from "react";
import { NextRouter } from "next/router";

export const useAuthModalRegularLinksTrigger = (router: NextRouter) => {
    useEffect(() => {
        const handleLoginRegisterClick = (e: Event) => {
            e.preventDefault();
            // DEV-Note: we call NextJs router explicitly because regular link clicks are not registered by NextJs router
            router.push((e.target as HTMLAnchorElement).href, undefined, { shallow: true });
        };

        const allLoginLinks = document.querySelectorAll("a[href*='#login']");
        const allRegisterLinks = document.querySelectorAll("a[href*='#register']");

        allLoginLinks.forEach(loginLink => loginLink.addEventListener("click", handleLoginRegisterClick));
        allRegisterLinks.forEach(registerLink => registerLink.addEventListener("click", handleLoginRegisterClick));

        return () => {
            allLoginLinks.forEach(loginLink => loginLink.removeEventListener("click", handleLoginRegisterClick));
            allRegisterLinks.forEach(registerLink => registerLink.removeEventListener("click", handleLoginRegisterClick));
        };
    }, [router]);
};
