import { ErrorPageCode, IHead } from "src/types/layout";

export class UnexpectedStatusCodeError extends Error {
    constructor(statusCode: number) {
        const message = `Unexpected statusCode "${statusCode}" provided by backend`;
        super(message);
    }
}

export const getErrorPageCode = (statusCode: number): ErrorPageCode | null => {
    switch (statusCode) {
        case 200:
            return null;
        case 400:
        case 404:
            return 404;
        case 500:
            return 500;
        default:
            throw new UnexpectedStatusCodeError(statusCode);
    }
};

export const getErrorPageHeadData = (statusCode: number): IHead => ({
    title: `${statusCode === 404 ? "Not found" : "Server error"} | wikifolio.com`,
    description: statusCode === 404 ? "Page not found" : "Internal server error",
    noIndex: true,
    version: "1.0.0.0",
    rssFeedUrl: "/de/de/blog/rss",
    openSearchUrl: "/umbraco/surface/opensearch/getxml",
    serverName: "PROD",
    traceId: "",
    canonicalUrl: "",
    alternativeUrl: {},
    customImageUrl: null,
});
