import { StyleFunctionProps } from "@chakra-ui/theme-tools";

const parts = ["container", "control", "label", "icon"];

const baseStyleControl = ({ colorScheme }: StyleFunctionProps) => {
    const color = colorScheme === "gray" ? "gray.800" : `${colorScheme}.600`;

    return {
        w: "100%",
        transition: "all 250ms",
        border: "1px solid",
        borderColor: "gray.400",
        color: "white",

        _checked: {
            bg: color,
            borderColor: color,
            color: "white",

            _disabled: {
                bg: "gray.100",
                color: "gray.200",
            },
            _focus: {
                borderColor: "gray.400",
            },
        },
        _disabled: {
            bg: "gray.100",
            borderColor: "gray.100",
            cursor: "not-allowed",
        },
        _focusVisible: {
            boxShadow: "focusVisible",
        },
        _focus: {
            borderColor: "gray.800",
        },
    };
};

const baseStyleLabel = {
    cursor: "pointer",
    userSelect: "none",
    m: 0,
    pl: 1,

    _disabled: {
        color: "gray.300",
        cursor: "not-allowed",
    },
};

const baseStyleContainer = {
    _hover: {
        "> .chakra-checkbox__control": {
            borderColor: "gray.800",
        },
        "> input:checked + .chakra-checkbox__control": {
            borderColor: "gray.400",
        },
        "> input:disabled + .chakra-checkbox__control": {
            borderColor: "gray.100",
        },
    },
};

const baseStyle = (props: StyleFunctionProps) => ({
    container: baseStyleContainer,
    control: baseStyleControl(props),
    label: baseStyleLabel,
});

const sizes = {
    sm: {
        control: { h: 2, w: 2, borderRadius: "2px" },
        label: { fontSize: "sm" },
        icon: { fontSize: "10px" },
    },
    md: {
        control: { w: 3, h: 3, borderRadius: "4px" },
        label: { fontSize: "md" },
        icon: { fontSize: "15px" },
    },
};

const defaultProps = {
    size: "md",
    colorScheme: "gray",
};

export default {
    parts,
    baseStyle,
    sizes,
    defaultProps,
};
