import { useState } from "react";
import { useTranslation } from "next-i18next";
import { IResendDoubleOptInEmailMutation } from "src/api/client/resend-double-opt-in-email.api";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";

export enum EnumActionState {
    READY,
    SENDING,
    SENT,
}

export const useBannerAction = (actionMutation: IResendDoubleOptInEmailMutation) => {
    const { t } = useTranslation("common");
    const [actionState, setActionState] = useState<EnumActionState>(EnumActionState.READY);
    const toast = useWfToast();

    const showFailedStatus = () => {
        toast({ status: EnumToastStatus.Error, title: t("general-error") });
        setActionState(EnumActionState.READY);
    };

    const handleAction = () => {
        setActionState(EnumActionState.SENDING);
        actionMutation.mutate(
            {},
            {
                onSuccess: () => {
                    setTimeout(() => {
                        setActionState(EnumActionState.SENT);
                    }, 2000);
                    setTimeout(() => {
                        setActionState(EnumActionState.READY);
                    }, 4000);
                },
                onError: showFailedStatus,
            }
        );
    };

    return {
        actionState,
        handleAction,
    };
};
