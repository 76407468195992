import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import Input from "./input";

const baseStyle = {
    ...Input.baseStyle.field,
    paddingY: 1,
    minHeight: 10,
    lineHeight: "short",
    verticalAlign: "top",
};

const variants = {
    outline: (props: StyleFunctionProps) => Input.variants.outline(props).field,
};

const sizes = {
    sm: Input.sizes.sm.field,
    md: Input.sizes.md.field,
    lg: Input.sizes.lg.field,
};

const defaultProps = {
    size: "md",
    variant: "outline",
};

export default {
    baseStyle,
    sizes,
    variants,
    defaultProps,
};
