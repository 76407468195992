import { useEffect } from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { COOKIE_ERROR } from "src/consts/cookies";
import { getGlobals } from "src/consts/globals";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { EnumCookieErrorTypes } from "./error-enums";

export const useToastCookieErrors = () => {
    const error = Cookies.get(COOKIE_ERROR);
    const globals = getGlobals();
    const passwordResetLink = globals?.routes.forgotPassword;
    const { t } = useTranslation("common");
    const router = useRouter();
    const toast = useWfToast();

    useEffect(() => {
        if (error) {
            toast({
                status: EnumToastStatus.Error,
                title: error === EnumCookieErrorTypes.PasswordLinkExpired ? t("password-link-expired") : t("general-error"),
                duration: 10000,
                buttonText: error === EnumCookieErrorTypes.PasswordLinkExpired ? t("continue") : undefined,
                onCloseCallback: () => router.push(passwordResetLink),
            });
            Cookies.remove(COOKIE_ERROR);
        }
    }, [error, passwordResetLink, router, t, toast]);
};
