import { Box, BoxProps } from "@chakra-ui/react";
import { getPerformanceColor } from "src/utils/color-util";

interface IColorKpi extends BoxProps {
    children: string;
}
export const ColorKpi = ({ children, ...boxProps }: IColorKpi) => (
    <Box fontWeight="bold" color={getPerformanceColor(children)} {...boxProps}>
        {children}
    </Box>
);
