export default {
    baseStyle: {
        text: {
            mt: 1,
            color: "red.700",
            fontSize: "xs",
            lineHeight: "base",
        },
    },
};
