import { ChangeEvent, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useChangeCurrencyMode } from "src/api/client/change-currency-mode";
import { WfSelect } from "src/components/base/wf-select";
import { getGlobals } from "src/consts/globals";
import { IFooter } from "src/types/layout";
import { FOOTER_SELECT_CURRENCY_TEST_ID } from "./currency-selector.test-ids";

interface ICurrencySelectorProps {
    currencies: IFooter["currencies"];
}

export const CurrencySelector = ({ currencies }: ICurrencySelectorProps) => {
    const router = useRouter();
    const [selectedCurrency, setSelectedCurrency] = useState<string>(() => getGlobals().currency);
    const { t } = useTranslation("common");

    const changeCurrency = useChangeCurrencyMode({
        onSuccess: router.reload,
        onError: router.reload,
    });

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setSelectedCurrency(value);
        changeCurrency({ currency: value });
    };

    return (
        <WfSelect
            size="sm"
            fontSize="sm"
            fontWeight="semibold"
            borderColor="gray.800"
            bg="transparent"
            value={selectedCurrency}
            onChange={onChange}
            data-test-id={FOOTER_SELECT_CURRENCY_TEST_ID}
            aria-label={t("labels.select-currency")}
        >
            {currencies.map(currency => (
                <option key={currency.value} value={currency.value}>
                    {currency.label}
                </option>
            ))}
        </WfSelect>
    );
};
