import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconCogWheel: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <rect width="24" height="24" />
            <g transform="translate(3 3)" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path d="m6.3 8.9996c0-1.4913 1.2087-2.7 2.7-2.7s2.7 1.2087 2.7 2.7-1.2087 2.7-2.7 2.7-2.7-1.2087-2.7-2.7z" />
                <path d="m18 9.8991v-1.7991c0-0.4968-0.4032-0.9-0.9009-0.9h-2.0637c-0.1242-0.4185-0.2889-0.8199-0.4932-1.197l1.458-1.458c0.3519-0.351 0.3519-0.9216 0-1.2735l-1.2726-1.2717c-0.351-0.3519-0.9216-0.3519-1.2735 0l-1.4571 1.4571c-0.3771-0.2043-0.7785-0.3681-1.197-0.4932v-2.0637c0-0.4968-0.4032-0.9-0.9009-0.9h-1.7982c-0.4977 0-0.9009 0.4032-0.9009 0.9v2.0637c-0.4185 0.1251-0.8199 0.2889-1.197 0.4932l-1.4571-1.4571c-0.3519-0.3519-0.9225-0.3519-1.2735 0l-1.2726 1.2717c-0.3519 0.3519-0.3519 0.9225 0 1.2735l1.458 1.458c-0.2043 0.3771-0.369 0.7785-0.4932 1.197h-2.0637c-0.4977 0-0.9009 0.4032-0.9009 0.9v1.7991c0 0.4977 0.4032 0.9009 0.9009 0.9009h2.0637c0.1242 0.4185 0.2889 0.8199 0.4932 1.197l-1.458 1.4571c-0.3519 0.3519-0.3519 0.9216 0 1.2735l1.2726 1.2717c0.351 0.3519 0.9216 0.3519 1.2735 0l1.4571-1.4571c0.3771 0.2043 0.7785 0.3681 1.197 0.4932v2.0637c0 0.4977 0.4032 0.9009 0.9009 0.9009h1.7982c0.4977 0 0.9009-0.4032 0.9009-0.9009v-2.0637c0.4185-0.1251 0.8199-0.2889 1.197-0.4932l1.4571 1.4571c0.3519 0.3519 0.9225 0.3519 1.2735 0l1.2726-1.2717c0.3519-0.3519 0.3519-0.9216 0-1.2735l-1.458-1.4571c0.2043-0.3771 0.369-0.7785 0.4932-1.197h2.0637c0.4977 0 0.9009-0.4032 0.9009-0.9009z" />
            </g>
        </g>
    </Icon>
);
