import { useMemo } from "react";
import { useRouter } from "next/router";
import { TFunction } from "next-i18next";
import { ILanguage } from "src/consts/locale";
import {
    formatMoney,
    formatNumber,
    formatNumberWithSign,
    formatOrdinals,
    formatPercent,
    formatPercentPerformance,
    formatPerformance,
    formatVolatility,
    IFractionDigits,
} from "./number-formatter";

export interface INumberFormatter {
    formatNumber: (value: number, fractionDigits?: IFractionDigits) => string;
    formatPerformance: (value: number, fractionDigits?: IFractionDigits) => string;
    formatPercent: (value: number, fractionDigits?: IFractionDigits) => string;
    formatPercentPerformance: (value: number, fractionDigits: IFractionDigits) => string;
    formatMoney: (value: number, t: TFunction) => string;
    formatOrdinals: (value: number) => string;
    formatNumberWithSign: (value: number, fractionDigits?: IFractionDigits) => string;
    formatVolatility: (value: number, fractionDigits?: IFractionDigits) => string;
}

export const useNumberFormatter = (): INumberFormatter => {
    const router = useRouter();
    const language = router.locale as ILanguage;

    return useMemo(
        () => ({
            formatNumber: (value, fractionDigits = 0) => formatNumber({ value, fractionDigits, language }),
            formatPerformance: (value, fractionDigits = 0) => formatPerformance({ value, fractionDigits, language }),
            formatPercent: (value, fractionDigits = 0) => formatPercent({ value, fractionDigits, language }),
            formatPercentPerformance: (value, fractionDigits = 0) => formatPercentPerformance({ value, fractionDigits, language }),
            formatMoney: (value, t) => formatMoney({ value, t, language }),
            formatOrdinals: value => formatOrdinals({ value, language }),
            formatNumberWithSign: (value, fractionDigits = 0) => formatNumberWithSign({ value, fractionDigits, language }),
            formatVolatility: (value, fractionDigits = 0) => formatVolatility({ value, fractionDigits, language }),
        }),
        [language]
    );
};
