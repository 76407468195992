import { useCallback } from "react";
import { isValidLocale } from "src/consts/locale";
import { useDisclaimerStore } from "src/stores/disclaimer-store";
import { isServerSide } from "src/utils/common-util";
import { useRouterLocale } from "src/utils/router/use-router-locale";
import { useCookieBotConsentReady } from "./cookie-bot/use-cookie-bot-consent-ready";
import { useFirstInteraction } from "./use-first-interaction";

export const useDisclaimerCheck = (disclaimer?: string | null) => {
    const { tryChangeCountry } = useDisclaimerStore();
    const isCookieBotConsentReady = useCookieBotConsentReady();

    let countryFromPathName = undefined;

    const { country, language } = useRouterLocale();

    // TODO: WIKI-32459
    // Workaround for an old issue where the Next.js Link click triggers rehydration
    // with incorrect country and locale, which is later replaced in next/router.

    if (!isValidLocale({ language, country }) && !isServerSide()) {
        countryFromPathName = window.location.pathname.split("/").filter(Boolean)[1];
    }

    const handleOnFirstInteraction = useCallback(() => tryChangeCountry(country, true), [tryChangeCountry, country]);

    useFirstInteraction({
        isDisabled: country === disclaimer || countryFromPathName === disclaimer || !isCookieBotConsentReady, // Disclaimer for current country already has been accepted, no need to display to disclaimer again.
        onFirstInteraction: handleOnFirstInteraction,
    });
};
