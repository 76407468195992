import { API_ONE_CLICK_NEWSLETTER_SUBSCRIPTION } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";

interface IResponse {
    isSuccess: boolean;
}

export function useOneClickEmailSubscribtion() {
    const { mutatePromise } = useMutation<IResponse, void, void>(API_ONE_CLICK_NEWSLETTER_SUBSCRIPTION, { method: "POST" });
    return mutatePromise;
}
