import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import Checkbox from "./checkbox";

const parts = ["container", "control", "label"];

function baseStyleControl(props: StyleFunctionProps) {
    const { control } = Checkbox.baseStyle(props);

    return {
        ...control,
        bg: "white",
        borderRadius: "full",
        cursor: "pointer",
        _checked: {
            ...control["_checked"],
            _before: {
                content: `""`,
                display: "inline-block",
                pos: "relative",
                borderRadius: "50%",
                bg: "currentColor",
            },
        },
    };
}
const baseStyleContainer = {
    _hover: {
        "> .chakra-radio__control": {
            borderColor: "gray.800",
        },
        "> input:checked + .chakra-radio__control": {
            borderColor: "gray.400",
        },
        "> input:disabled + .chakra-radio__control": {
            borderColor: "gray.100",
        },
    },
};

const baseStyle = (props: StyleFunctionProps) => ({
    container: baseStyleContainer,
    label: Checkbox.baseStyle(props).label,
    control: baseStyleControl(props),
});

const sizes = {
    sm: {
        control: {
            boxSize: 2,
            _checked: {
                _before: {
                    boxSize: "6px",
                },
            },
        },
        label: { fontSize: "sm" },
    },
    md: {
        control: {
            boxSize: 3,
            _checked: {
                _before: {
                    boxSize: "10px",
                },
            },
        },
        label: { fontSize: "md" },
    },
};

const defaultProps = {
    size: "md",
    colorScheme: "gray",
};

export default {
    parts,
    baseStyle,
    sizes,
    defaultProps,
};
