import { useEffect } from "react";
import Router from "next/router";
import { useTranslation } from "next-i18next";
import { useGenericActivityFromElysiumMutation } from "src/api/client/generic-activity-from-elysium.api";
import { EnumActivityType } from "src/api/client/generic-activity.api";
import { useOneClickEmailSubscribtion } from "src/api/client/one-click-newsletter-sub.api";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import { IUser } from "src/types/common";

export const useEmailNewsletterLink = (user?: IUser) => {
    const subscribeToNewsletter = useOneClickEmailSubscribtion();
    const toast = useWfToast();
    const { buildRegisterModalLink } = useLinkBuilder();
    const { t } = useTranslation("common");
    const { setShowEmailRegisterForm, setIsNewsletterCheckboxEnabled } = useAuthModalStore();
    const newsletterSubscriptionLinkActivity = useGenericActivityFromElysiumMutation();

    useEffect(() => {
        const emailLinks = document.querySelectorAll(`a[href*='#newsletter-opt-in-link']`);
        const listenerFn = async (evt: Event) => {
            evt.preventDefault();

            newsletterSubscriptionLinkActivity({
                activityTypeId: EnumActivityType.NewsletterSubscriptionLinkClicked,
                parameter: null,
                referrer: document.URL ?? null,
            });

            if (!user) {
                setShowEmailRegisterForm(true);
                setIsNewsletterCheckboxEnabled(true);
                Router.push(buildRegisterModalLink());
            } else {
                try {
                    await subscribeToNewsletter();
                    toast({ status: EnumToastStatus.Success, title: t("newsletter-opt-in-success") });
                } catch (err) {
                    toast({
                        status: EnumToastStatus.Error,
                        title: t("newsletter-opt-in-error"),
                    });
                }
            }
        };

        emailLinks?.forEach(link => {
            link.addEventListener("click", listenerFn);
        });

        return () =>
            emailLinks?.forEach(link => {
                link.removeEventListener("click", listenerFn);
            });
    });
};
