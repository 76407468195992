import { createCustomMarketActivityUrl } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { useRouterLocale } from "src/utils/router/use-router-locale";

interface IRequest {
    activity: string;
    value: string;
}

/**
 * sends a post request to umbraco to trigger the creation of a new marketo activity
 */
export const useCustomActivityMutation = () => {
    const { language, country } = useRouterLocale();

    const { mutatePromise } = useMutation<void, void, IRequest>(createCustomMarketActivityUrl(language, country));
    return mutatePromise;
};
