import { MouseEvent } from "react";
import { SpanBox } from "src/components/base";
import { IWfLinkProps, WfLink } from "src/components/base/wf-link";

interface IMenuLinkProps extends IWfLinkProps {
    hasChildElements: boolean;
}

export const MenuLink = ({ children, hasChildElements, href, target, ...props }: IMenuLinkProps) => {
    const menuItemProps = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        h: 10,
        px: 2,
        role: "menuitem",
        cursor: hasChildElements ? "default" : "pointer",
        onClick: (event: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => event.currentTarget.blur(),
        onAuxClick: (event: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => event.currentTarget.blur(),
        pos: "relative",
    } as const;
    const menuLinkProps = { href, target, className: "gtm-nav-menu__link", variant: "unstyled" };
    return hasChildElements ? (
        <SpanBox {...menuItemProps}>{children}</SpanBox>
    ) : (
        <WfLink {...menuLinkProps} {...menuItemProps} {...props}>
            {children}
        </WfLink>
    );
};
