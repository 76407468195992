import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Box, Flex, keyframes, Text } from "@chakra-ui/react";
import { useGenericActivityFromElysiumMutation } from "src/api/client/generic-activity-from-elysium.api";
import { EnumActivityType } from "src/api/client/generic-activity.api";
import { WfLink } from "src/components/base/wf-link";
import { IconAvatar } from "src/components/icons";
import { useNewsTicker } from "src/components/layout/news-ticker/use-news-ticker";

const animationFrames = keyframes`
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(-100%);
    }
`;

const MAX_ARTICLE_NAME_WIDTH = ["32ch", "50ch", "81ch"];
const ANIMATION_SPEED_DIVIDER = 55;
const ANIMATION_WIDTH_ADJUSTMENT = 1000;

const calculateAnimationTime = (ref?: MutableRefObject<HTMLDivElement | null> | null) => {
    if (ref?.current) {
        return (ref.current.getBoundingClientRect().width + ANIMATION_WIDTH_ADJUSTMENT) / ANIMATION_SPEED_DIVIDER;
    }

    return 0;
};

export const NewsTicker = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { newsReactions, setHasShownAllItems } = useNewsTicker();
    const [animationTime, setAnimationTime] = useState(calculateAnimationTime());
    const genericActivity = useGenericActivityFromElysiumMutation();

    const onClick = (traderId?: string, url?: string) => () => {
        genericActivity({
            activityTypeId: EnumActivityType.NavigationClicked,
            parameter: JSON.stringify({
                type: "ticker",
                traderId,
                targetUrl: url,
            }),
            referrer: window.location.href,
        });
    };

    useEffect(() => {
        setAnimationTime(calculateAnimationTime(ref));
    }, [newsReactions]);

    useEffect(() => {
        const textRef = ref;
        const handleAnimationIteration = () => {
            setHasShownAllItems(true);
        };

        textRef.current?.addEventListener("animationiteration", handleAnimationIteration);

        return () => {
            textRef.current?.removeEventListener("animationiteration", handleAnimationIteration);
        };
    }, [setHasShownAllItems]);

    return (
        <Flex
            role="group"
            alignItems="center"
            zIndex="sticky"
            width="100%"
            height={4}
            position="fixed"
            top={0}
            left={0}
            backgroundColor="green.100"
            data-test-id="news-ticker"
            aria-hidden
        >
            <Box
                ref={ref}
                _groupHover={{ animationPlayState: "paused" }}
                fontSize="sm"
                display="inline-flex"
                whiteSpace="nowrap"
                gap="10vw"
                animation={newsReactions && animationTime ? `${animationFrames} ${animationTime}s linear 1s infinite normal backwards` : undefined}
                visibility={newsReactions && animationTime ? "visible" : "hidden"}
            >
                {newsReactions?.map((currentItem, index) => (
                    <Flex key={index} alignItems="center" gap={1}>
                        <Text>
                            „
                            <WfLink
                                tabIndex={-1}
                                display="inline-block"
                                verticalAlign="bottom"
                                maxWidth={MAX_ARTICLE_NAME_WIDTH}
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                onClick={onClick(currentItem?.trader?.id, currentItem.url)}
                                href={currentItem.url}
                            >
                                {currentItem.title}
                            </WfLink>
                            “
                        </Text>
                        {currentItem.trader && currentItem.interaction ? (
                            <Flex alignItems="center" gap={0.5}>
                                <IconAvatar />
                                <Text>
                                    {currentItem.trader.firstName} {currentItem.trader.lastName}
                                </Text>
                                <Text>{currentItem.interaction}</Text>
                            </Flex>
                        ) : null}
                    </Flex>
                ))}
            </Box>
        </Flex>
    );
};
