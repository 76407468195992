export const getPerformanceColor = (valueFormatted: string) => {
    if (valueFormatted.startsWith("+")) {
        return "green.600";
    }

    if (valueFormatted.startsWith("-")) {
        return "red.600";
    }

    return undefined; // initial color
};
