// Modified version of the original "Avatar" component from chakra-ui (https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/avatar).
// Notable differences:
//  - "initials" logic has been removed -> icon will always be used in fallback case
//  - directly set width/height on image/svg
//
// The problem with the original component was, that it always showed the initials if you provided a "name" prop. However,
// the "name" prop was necessary, so that we could have a useful "alt"-text on the image.

import * as React from "react";
import {
    forwardRef,
    HTMLChakraProps,
    SystemProps,
    SystemStyleObject,
    useStyles,
    chakra,
    ThemingProps,
    omitThemingProps,
    useMultiStyleConfig,
    StylesProvider,
    useImage,
    ImageProps,
} from "@chakra-ui/react";
import { TIconComponent } from "src/components/icons";

interface IWfAvatarOptions {
    /**
     * The name of the person in the avatar.
     *
     * - If `src` has loaded, the name will be used as the `alt` attribute of the `img`
     * - If `src` is not loaded, the name will be used to create the initials
     */
    name?: string;
    /**
     * If `true`, the `Avatar` will show a border around it.
     *
     * Best for a group of avatars
     */
    showBorder?: boolean;
    /**
     * The badge in the bottom right corner of the avatar.
     */
    children?: React.ReactNode;
    /**
     * The image url of the `Avatar`
     */
    src?: string;
    /**
     * List of sources to use for different screen resolutions
     */
    srcSet?: string;
    /**
     * Defines loading strategy
     */
    loading?: "eager" | "lazy";
    /**
     * The border color of the avatar
     * @type SystemProps["borderColor"]
     */
    borderColor?: SystemProps["borderColor"];
    /**
     * Function called when image failed to load
     */
    onError?: () => void;
    /**
     * The default avatar used as fallback when `name`, and `src`
     * is not specified.
     * @type React.ReactElement
     */
    icon?: React.ReactElement;
    alt?: string;
}

export interface IWfAvatarBadgeProps extends HTMLChakraProps<"div"> {}

/**
 * AvatarBadge used to show extra badge to the top-right
 * or bottom-right corner of an avatar.
 */
export const AvatarBadge = forwardRef<IWfAvatarBadgeProps, "div">((props, ref) => {
    const styles = useStyles();

    const badgeStyles: SystemStyleObject = {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        insetEnd: "0",
        bottom: "0",
        ...styles.badge,
    };

    return <chakra.div ref={ref} {...props} className={"chakra-avatar__badge"} __css={badgeStyles} />;
});

/**
 * Fallback avatar react component.
 * This should be a generic svg used to represent an avatar
 */
const DefaultIcon: TIconComponent = props => (
    <chakra.svg viewBox="0 0 128 128" color="#fff" width="100%" height="100%" className="chakra-avatar__svg" {...props}>
        <path
            fill="currentColor"
            d="M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z"
        />
        <path
            fill="currentColor"
            d="M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24"
        />
    </chakra.svg>
);

export const baseStyle: SystemStyleObject = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "medium",
    position: "relative",
    flexShrink: 0,
};

export interface IWfAvatarProps extends Omit<HTMLChakraProps<"span">, "onError">, IWfAvatarOptions, ThemingProps<"Avatar"> {
    /**
     * If `true`, opt out of the avatar's `fallback` logic and
     * renders the `img` at all times.
     */
    ignoreFallback?: boolean;
}

/**
 * Avatar component that renders an user avatar with
 * support for fallback avatar and name-only avatars
 */
export const WfAvatar = forwardRef<IWfAvatarProps, "span">((props, ref) => {
    const styles = useMultiStyleConfig("Avatar", props);

    const {
        src,
        name,
        showBorder,
        borderRadius = "full",
        onError,
        icon = <DefaultIcon />,
        loading,
        children,
        borderColor,
        ignoreFallback,
        ...rest
    } = omitThemingProps(props);

    const avatarStyles: SystemStyleObject = {
        borderRadius,
        borderWidth: showBorder ? "2px" : undefined,
        ...baseStyle,
        ...styles.container,
    };

    if (borderColor) {
        avatarStyles.borderColor = borderColor;
    }

    return (
        <chakra.span ref={ref} {...rest} className="chakra-avatar" __css={avatarStyles}>
            <StylesProvider value={styles}>
                <WfAvatarImage
                    src={src}
                    loading={loading}
                    onError={onError}
                    name={name}
                    icon={icon}
                    borderRadius={borderRadius}
                    ignoreFallback={ignoreFallback}
                    boxSize={props.boxSize}
                />
                {children}
            </StylesProvider>
        </chakra.span>
    );
});

interface IWfAvatarImageProps
    extends Omit<ImageProps, "borderRadius" | "icon" | "name" | "boxSize">,
        Pick<IWfAvatarProps, "borderRadius" | "icon" | "name" | "boxSize"> {}

const WfAvatarImage: React.FC<IWfAvatarImageProps> = props => {
    const styles = useMultiStyleConfig("Avatar", { size: 8 });
    const { src, onError, name, borderRadius, loading, icon = <DefaultIcon />, ignoreFallback } = props;

    /**
     * use the image hook to only show the image when it has loaded
     */
    const status = useImage({ src, onError, ignoreFallback });

    const hasLoaded = status === "loaded";

    /**
     * Fallback avatar applies under 2 conditions:
     * - If `src` was passed and the image has not loaded or failed to load
     * - If `src` wasn't passed
     *
     * In this case, we'll show either the default avatar
     */
    const showFallback = !src || !hasLoaded;

    if (showFallback) {
        return React.cloneElement(icon, {
            role: "img",
            "aria-label": name,
            ...styles.image,
        });
    }

    /**
     * If `src` was passed and the image has loaded, we'll show it
     */
    return (
        <chakra.img
            src={src}
            alt={name}
            className="chakra-avatar__img"
            loading={loading}
            __css={{
                objectFit: "cover",
                borderRadius,
                ...styles.image,
            }}
        />
    );
};
