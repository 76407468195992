import { DefaultOptions } from "react-query";
import { MINUTE_IN_MS } from "src/consts/time";

/**
 * The default configuration for our React Query hooks.
 *
 * For more information on why we decided to go with this config, @see https://wikifolio.atlassian.net/wiki/spaces/PDW/pages/3568926741/React+Query#Default-Options
 */
export const defaultQueryClientOptions: DefaultOptions = {
    queries: {
        staleTime: MINUTE_IN_MS * 5,
    },
};
