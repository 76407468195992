import {
    // eslint-disable-next-line no-restricted-imports
    useQuery as _useQuery,
    // eslint-disable-next-line no-restricted-imports
    useQueries as _useQueries,
    useQueryClient,
    UseQueryOptions,
} from "react-query";
import { MAX_ATTEMPTS, RETRY_DELAY_MS } from "src/consts/retry";
import { buildQueryUrl } from "src/utils/query-util";
import { useRouterLocale } from "src/utils/router/use-router-locale";

const getQueryFn = (url: string) => async () => {
    const response = await fetch(url.toLowerCase(), {
        headers: {
            Accept: "application/json",
        },
    } as RequestInit);

    const responseValue = await response.json();
    if (!response.ok) {
        throw responseValue;
    }

    return responseValue;
};

interface IQueryOptions<Data, Error> extends UseQueryOptions<Data, Error> {
    queryParams?: URLSearchParams;
}

/**
 * @param _url this should be the path of the API Endpoint *without* any query params
 * @param options additional react-query options and the possibility to pass query params as URLSearchParams
 */
export const useQuery = <Data = unknown, Error = unknown>(_url: string, options?: IQueryOptions<Data, Error>) => {
    const { language, country } = useRouterLocale();

    const url = buildQueryUrl({ language, country }, _url, options?.queryParams);

    const queryClient = useQueryClient();
    const result = _useQuery<Data, Error>({
        queryKey: url,
        queryFn: getQueryFn(url),
        ...{ retry: MAX_ATTEMPTS, retryDelay: RETRY_DELAY_MS },
        ...options,
    });
    return {
        ...result,
        setQueryData: (data: Data) => {
            queryClient.setQueryData(url, data);
        },
        invalidate: () => {
            queryClient.invalidateQueries(url);
        },
    };
};

interface IUseQueriesProps<Data, Error> {
    url: string;
    options: IQueryOptions<Data, Error>;
}
export const useQueries = <Data, Error>(queries: IUseQueriesProps<Data, Error>[]) => {
    const { language, country } = useRouterLocale();

    const getOptions = (query: IUseQueriesProps<Data, Error>): UseQueryOptions<Data, Error> => {
        const url = buildQueryUrl({ language, country }, query.url, query.options?.queryParams);
        return {
            queryKey: url,
            queryFn: getQueryFn(url),
            ...{ retry: MAX_ATTEMPTS, retryDelay: RETRY_DELAY_MS },
            ...query.options,
        };
    };

    return _useQueries(queries.map(getOptions));
};
