/**
 * DOM element ids
 */
export const ID_NAV_MOBILE = "id-nav-mobile";

/**
 * DOM element data-class attribute
 */
export const CLASS_NAV_ICON_CARET = "nav-icon-caret";

/**
 * Navigation menu underline indicator class
 */
export const CLASS_NAV_UNDERLINE = "nav-underline-indicator";
