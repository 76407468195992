import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconWikifolioLogo: TIconComponent = props => (
    <Icon viewBox="0 0 447 80" {...props}>
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <polygon
                    id="Path"
                    points="70.19 1.0439 58.065 58.173 48.593 22.301 31.922 22.301 22.449 58.173 18.282 39.004 0 39.004 12.03 78.671 32.206 78.671 40.257 46.785 48.309 78.671 69.432 78.671 88.377 1.0439"
                    fill="#95C11F"
                />
                <rect id="Rectangle" x="94.534" y="22.301" width="17.903" height="56.37" fill="#333" />
                <polygon
                    id="Path"
                    points="159.99 48.019 179.6 78.671 158.57 78.671 142.84 52.384 142.84 78.671 124.94 78.671 124.94 1.0439 142.84 1.0439 142.84 44.982 158.09 22.301 178.08 22.301"
                    fill="#333"
                />
                <rect id="Rectangle" x="188.12" y="22.301" width="17.903" height="56.37" fill="#333" />
                <g id="c" transform="translate(217.2 .85409)" fill="#333">
                    <path
                        id="Path"
                        d="m23.776 21.447h8.8093v14.52h-8.8093v41.851h-17.903v-41.851h-5.8729v-14.52h5.8729v-1.6133c0-14.804 10.514-19.834 22.26-19.834 2.1786 0 3.41 0.094899 5.494 0.1898v14.994c-0.66306-0.094899-1.6103-0.1898-2.9364-0.1898-4.6414 0-7.0095 1.8031-7.0095 5.2195v1.2337h0.094723z"
                    />
                    <path
                        id="b"
                        d="m68.485 63.393c7.3884 0 12.977-5.7888 12.977-13.855 0-8.2562-5.6834-13.76-12.977-13.76-7.3884 0-12.693 5.694-12.693 13.95 0.094723 8.2562 5.3992 13.665 12.693 13.665m0-43.369c18.092 0 30.88 12.432 30.88 29.514 0 16.987-13.072 29.609-30.88 29.609-17.903 0-30.596-12.432-30.596-29.419 0.094724-16.987 13.261-29.703 30.596-29.703"
                    />
                </g>
                <rect id="d" x="325.38" y="1.0439" width="17.903" height="77.628" fill="#333" />
                <g transform="translate(95.387)" fill="#333">
                    <path d="m320.73 64.247c7.3884 0 12.977-5.7888 12.977-13.855 0-8.2562-5.6834-13.76-12.977-13.76-7.3884 0-12.693 5.694-12.693 13.95 0 8.2562 5.3992 13.665 12.693 13.665m0-43.369c18.092 0 30.88 12.432 30.88 29.514 0 16.987-13.072 29.609-30.88 29.609-17.903 0-30.596-12.432-30.596-29.419s13.261-29.703 30.596-29.703" />
                    <path
                        id="Path"
                        d="m0 7.9715c0-4.6501 3.5048-7.9715 8.1462-7.9715 4.6414 0 8.2409 3.3215 8.2409 7.9715 0 4.5552-3.5995 7.8766-8.2409 7.8766-4.7362 0-8.1462-3.3215-8.1462-7.8766"
                    />
                    <path
                        id="a"
                        d="m93.492 7.9715c0-4.6501 3.5048-7.9715 8.1462-7.9715 4.6414 0 8.2409 3.3215 8.2409 7.9715 0 4.5552-3.5995 7.8766-8.2409 7.8766-4.6414 0-8.1462-3.3215-8.1462-7.8766"
                    />
                </g>
                <rect x="356.54" y="22.301" width="17.903" height="56.37" fill="#333" />
                <path
                    d="m357.3 7.9715c0-4.6501 3.5048-7.9715 8.1462-7.9715 4.6414 0 8.2409 3.3215 8.2409 7.9715 0 4.5552-3.5995 7.8766-8.2409 7.8766-4.6414 0-8.1462-3.3215-8.1462-7.8766"
                    fill="#333"
                />
            </g>
        </g>
    </Icon>
);
