export const THEME_COLORS = {
    green: {
        50: "#f4f9e9",
        100: "#eaf3d2",
        200: "#d5e6a5",
        300: "#c0da79",
        400: "#aacd4c",
        500: "#95c11f",
        600: "#78a600",
        700: "#5f8e00",
        800: "#3a6a00",
        900: "#003f00",
    },
    gray: {
        50: "#f2f2f2",
        100: "#e6e6e6",
        200: "#bfbfbf",
        300: "#b3b3b3",
        400: "#999999",
        500: "#808080",
        600: "#666666",
        700: "#4d4d4d",
        800: "#333333",
        900: "#1a1a1a",
    },
    pear: {
        50: "#fbfdec",
        100: "#f8fbda",
        200: "#f0f7b5",
        300: "#e9f390",
        400: "#e2ef6b",
        500: "#dcea43",
        600: "#c3d430",
        700: "#9eb100",
        800: "#7a8e00",
        900: "#4a6100",
    },
    yellow: {
        50: "#fffbe6",
        100: "#fff6cc",
        200: "#ffed99",
        300: "#ffe566",
        400: "#ffdc33",
        500: "#ffd300",
        600: "#e9c100",
        700: "#c29e00",
        800: "#967800",
        900: "#664f00",
    },
    orange: {
        50: "#fff4e6",
        100: "#ffeacc",
        200: "#ffd499",
        300: "#ffc082",
        400: "#ffa933",
        500: "#ff9400",
        600: "#ea8500",
        700: "#ce6d00",
        800: "#aa4f00",
        900: "#742300",
    },
    blush: {
        50: "#fff1ef",
        100: "#ffe3e0",
        200: "#ffc8c1",
        300: "#ffaca2",
        400: "#ff9183",
        500: "#ff7564",
        600: "#e96454",
        700: "#c54338",
        800: "#a32320",
        900: "#740000",
    },
    red: {
        50: "#ffe6e6",
        100: "#ffcccc",
        200: "#ff9999",
        300: "#ff6666",
        400: "#ff3333",
        500: "#ff0000",
        600: "#e20000",
        700: "#ba0000",
        800: "#930000",
        900: "#5e0005",
    },
    lilac: {
        50: "#f6f2fb",
        100: "#eee5f6",
        200: "#dccbee",
        300: "#cbb1e5",
        400: "#b998dd",
        500: "#a87ed4",
        600: "#8c65ba",
        700: "#654191",
        800: "#4c2a77",
        900: "#2e0f59",
    },
    blue: {
        50: "#f0f7ff",
        100: "#e1eeff",
        200: "#c3ddff",
        300: "#a5ccff",
        400: "#87bbff",
        500: "#69aaff",
        600: "#418de0",
        700: "#006ab8",
        800: "#00448d",
        900: "#0d2567",
    },
    sky: {
        50: "#f0fcff",
        100: "#e1f8ff",
        200: "#c3f1ff",
        300: "#a5ebff",
        400: "#87e4ff",
        500: "#69ddff",
        600: "#46c2e5",
        700: "#0099bb",
        800: "#006b8b",
        900: "#004765",
    },
    teal: {
        50: "#f0fbf5",
        100: "#e1f8eb",
        200: "#c3f1d6",
        300: "#a5eac2",
        400: "#87e3ad",
        500: "#69dc99",
        600: "#45bd7d",
        700: "#179c5f",
        800: "#006d35",
        900: "#004511",
    },
};
