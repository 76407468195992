import { Box, BoxProps, forwardRef } from "@chakra-ui/react";
import { useSimpleRichText } from "src/components/common/rich-text/use-simple-rich-text";

// xl is the biggest font-size for text-content
type IFontSizeKeys = "xs" | "sm" | "md" | "lg" | "xl";
export type ISimpleRichTextFontSize = IFontSizeKeys | IFontSizeKeys[];

export interface ISimpleRichTextProps extends Omit<BoxProps, "fontSize"> {
    text: string;
    fontSize?: ISimpleRichTextFontSize;
}

export const SimpleRichText = forwardRef<ISimpleRichTextProps, typeof Box>(({ text, fontSize, ...boxProps }: ISimpleRichTextProps, ref) => {
    const sx = useSimpleRichText(fontSize);

    // Dev-Note: uncomment when we want to use Next.js agnostic links
    // useNextLink(ref);

    return <Box ref={ref} fontSize={fontSize} sx={sx} dangerouslySetInnerHTML={{ __html: text }} {...boxProps} />;
});
