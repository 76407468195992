import { getColor, StyleFunctionProps } from "@chakra-ui/theme-tools";

const baseStyle = {
    textTransform: "none",
    fontWeight: "semibold",
};

interface ISolidColor {
    bg: string;
    color: string;
}
const solidColorMap: { [key: string]: ISolidColor } = {
    gray: {
        bg: "gray.800",
        color: "white",
    },
    green: {
        bg: "green.600",
        color: "white",
    },
    blue: {
        bg: "blue.600",
        color: "white",
    },
    lilac: {
        bg: "lilac.600",
        color: "white",
    },
    orange: {
        bg: "orange.600",
        color: "white",
    },
};

function variantSolid({ colorScheme }: StyleFunctionProps) {
    const { bg, color } = solidColorMap[colorScheme] || {};

    return {
        bg,
        color,
    };
}

interface IOutlineColor {
    borderColor: string;
    color: string;
}
const outlineColorMap: { [key: string]: IOutlineColor } = {
    gray: {
        borderColor: "gray.400",
        color: "gray.800",
    },
    green: {
        borderColor: "green.600",
        color: "green.600",
    },
};

function variantOutline(props: StyleFunctionProps) {
    const { colorScheme: c, theme } = props;

    const { borderColor, color } = outlineColorMap[c] || {};

    return {
        color,
        boxShadow: `inset 0 0 0px 1px ${getColor(theme, borderColor)}`,
    };
}

const variants = {
    solid: variantSolid,
    outline: variantOutline,
};

const sizes = {
    md: {
        fontSize: "sm",
        lineHeight: "shorter",
        // Dev-Note: `p: 1` overrides the `px` applied on the component usage!
        px: 1,
        py: 1,
        borderRadius: "16px",
    },
    sm: {
        fontSize: "sm",
        lineHeight: "shorter",
        px: 1,
        py: 0.5,
        borderRadius: "12px",
    },
    xs: {
        fontSize: "2xs",
        lineHeight: "base",
        px: 1,
        pt: "1px",
        borderRadius: "16px",
    },
};

const defaultProps = {
    variant: "outline",
    colorScheme: "gray",
    size: "md",
};

export default {
    baseStyle,
    variants,
    defaultProps,
    sizes,
};
