import { css, StyleObjectOrFn } from "@chakra-ui/react";
import { Global } from "@emotion/react";

interface IProps {
    sx: StyleObjectOrFn;
}

/**
 * With this component you can define global styles and still leverage ChakraUI props.
 */
export const WfGlobalStyle = ({ sx }: IProps) => (
    <Global
        styles={theme => {
            const styles = css(sx)(theme);
            return styles;
        }}
    />
);
