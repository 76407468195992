import { isValidLocale } from "src/consts/locale";

/**
 * Due to the fact that we have a specific folder structure and next js with i18n enabled,
 * FE depending on routing revalidate FE by calling getServerSideProps with /_next/data/*.json and at this point the url
 * is broken this happens only when language and country are the same.
 * */
export const interceptBrokenNextDataLinks = () => {
    // The potential fix will be rework app with `app` folder: https://github.com/vercel/next.js/tree/canary/examples/app-dir-i18n-routing
    // https://github.com/vercel/next.js/discussions/38414
    const { fetch: originalFetch } = window;
    const nextDataRequestRegex = /^\/_next\/data\/.*\.json/;
    window.fetch = async (...args) => {
        const [urlToFetch, options] = args;
        const url = String(urlToFetch);

        if (nextDataRequestRegex.test(url)) {
            const [language, country] = window.location.pathname.split("/").filter(Boolean);

            const languageAndCountry = `/${language}/${country}/`;

            if (isValidLocale({ language, country }) && !url.includes(languageAndCountry)) {
                const index = url.indexOf(`/${country}/`);
                const startingPath = url.slice(0, index);
                const endingPath = url.slice(index);

                const newUrl = `${startingPath}/${language}/${country}${endingPath}`;

                return originalFetch(newUrl, options);
            }
        }
        return originalFetch(...args);
    };
};
