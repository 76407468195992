import { ReactNode, useCallback } from "react";
import {
    Alert,
    AlertProps,
    AlertTitle,
    IconButton,
    Flex,
    // eslint-disable-next-line no-restricted-imports
    useToast,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";
import { IconCheckCircle, IconClose, IconInfo } from "src/components/icons";

export enum EnumToastStatus {
    Info = "info",
    Success = "success",
    Error = "error",
    Warning = "warning",
}

interface IBaseToast {
    status: AlertProps["status"];
    title: ReactNode;
    alertProp?: AlertProps;
}

interface IToastIconProps {
    status: AlertProps["status"];
}

const ToastIcon = ({ status }: IToastIconProps) => {
    switch (status) {
        case EnumToastStatus.Info:
            return <IconInfo boxSize={3} fill="white" color="gray.800" />;
        case EnumToastStatus.Success:
            return <IconCheckCircle boxSize={3} fill="white" color="green.600" />;
        case EnumToastStatus.Error:
        case EnumToastStatus.Warning:
            return (
                <IconInfo
                    transform="rotate(180deg)"
                    boxSize={3}
                    fill="white"
                    color={`${status === EnumToastStatus.Warning ? "orange" : "red"}.600`}
                />
            );
        default:
            return null;
    }
};

interface IToastProps extends IBaseToast {
    onClose: () => void;
    ariaLabel: string;
    buttonText?: string;
    onCloseCallback?(): void;
}
const Toast = ({ title, status, onClose, ariaLabel, buttonText, onCloseCallback }: IToastProps) => {
    const handleClose = () => {
        onClose();
        onCloseCallback?.();
    };
    return (
        <Alert
            status={status}
            rounded="8px"
            maxWidth="none"
            flexDirection={[buttonText ? "column" : "row", "row"]}
            alignItems="center"
            justifyContent="space-between"
        >
            <Flex direction="row">
                <ToastIcon status={status} />
                <AlertTitle ml={1} mr={2}>
                    {title}
                </AlertTitle>
            </Flex>

            {buttonText ? (
                <Button onClick={handleClose} size="xs" colorScheme="whiteAlpha" width={["100%", "auto"]} marginTop={["11px", 0]}>
                    {buttonText}
                </Button>
            ) : (
                <IconButton
                    icon={<IconClose boxSize={2} />}
                    aria-label={ariaLabel}
                    variant="ghost"
                    colorScheme="whiteAlpha"
                    size="xs"
                    my={-0.5}
                    mr={-0.5}
                    color="white"
                    onClick={handleClose}
                />
            )}
        </Alert>
    );
};

interface IToastOption extends IBaseToast {
    duration?: number;
    buttonText?: string;
    onCloseCallback?(): void;
}

export const useWfToast = () => {
    const toast = useToast();
    const { t } = useTranslation("common");

    return useCallback(
        ({ duration = 3000, ...toastProps }: IToastOption) => {
            const ref = toast({
                position: "top",
                duration,
                render: () => <Toast {...toastProps} ariaLabel={t("close")} onClose={() => ref && toast.close(ref)} />,
            });

            return ref;
        },
        [t, toast]
    );
};
