import { useCallback, useEffect, useRef } from "react";
import { useBoolean } from "@chakra-ui/react";

export function useCookieBotConsentUpdated() {
    const [isUpdated, setIsUpdated] = useBoolean(false);
    const displayRef = useRef(false);
    const handleInitDialog = () => {
        displayRef.current = true;
    };

    const handleClosedDialog = useCallback(() => {
        if (displayRef.current) {
            setIsUpdated.on();
            displayRef.current = false;
        }
    }, [setIsUpdated]);

    const resetIsUpdated = (): void => {
        setIsUpdated.off();
    };

    useEffect(() => {
        window.addEventListener("CookiebotOnDialogInit", handleInitDialog);
        window.addEventListener("CookiebotOnAccept", handleClosedDialog);
        window.addEventListener("CookiebotOnDecline", handleClosedDialog);

        return () => {
            window.removeEventListener("CookiebotOnDialogInit", handleInitDialog);
            window.removeEventListener("CookiebotOnAccept", handleClosedDialog);
            window.removeEventListener("CookiebotOnDecline", handleClosedDialog);
        };
    }, [handleClosedDialog]);

    return { isUpdated, resetIsUpdated };
}
