import { useRouter } from "next/router";
import { ICountry, ILanguage } from "src/consts/locale";

export const useRouterLocale = () => {
    const router = useRouter();

    return {
        country: router.query.country as ICountry,
        language: router.locale as ILanguage,
    };
};
