import { MotionLink, MotionLinkProps } from "../../base";
import { WfLinkUnstyled } from "../../base/wf-link";

export const ServiceLink = (props: MotionLinkProps) => (
    <MotionLink
        as={WfLinkUnstyled}
        color="white"
        borderColor="white"
        fontSize="xs"
        fontWeight="semibold"
        mr={2}
        _hover={{ borderBottom: "1px" }}
        initial={{ width: 0 }}
        animate={{ width: "auto" }}
        exit={{ width: 0 }}
        overflow="hidden"
        {...props}
    />
);
