import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconError: TIconComponent = props => (
    <Icon viewBox="0 0 405 335" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M334.673 169.039c0 83.348-67.57 150.917-150.923 150.917S32.826 252.387 32.826 169.039 100.398 18.122 183.75 18.122 334.673 85.69 334.673 169.039"
                fill="#fff"
            />
            <path
                d="M173.298 234.725l-57.512-50.936c-3.267-2.894-3.571-7.888-.676-11.155h0c2.892-3.267 7.887-3.569 11.154-.676l57.514 50.936c3.267 2.894 3.569 7.888.676 11.155h0c-2.894 3.267-7.888 3.57-11.156.676"
                fill="#d5e6a5"
            />
            <path
                d="M113.238 170.976l-.204.238c-3.596 4.346-3.107 10.724 1.094 14.446l57.512 50.936c4.3 3.809 10.874 3.411 14.684-.89l.205-.239c3.594-4.346 3.106-10.724-1.095-14.445l-57.514-50.936c-4.301-3.808-10.875-3.41-14.683.891zm11.368 2.853l57.513 50.935c2.181 1.932 2.435 5.246.584 7.484l-.149.174c-1.951 2.202-5.365 2.409-7.598.43l-57.512-50.936c-2.182-1.933-2.436-5.246-.584-7.484l.15-.174c1.95-2.201 5.364-2.408 7.598-.43z"
                fill="#333"
                fillRule="nonzero"
            />
            <path
                d="M106.403 269.893l-17.449-15.454c-11.473-10.16-12.536-27.694-2.374-39.167l28.465-32.139 58.991 52.247-28.465 32.139c-10.16 11.471-27.697 12.534-39.168 2.374"
                fill="#d5e6a5"
            />
            <path
                d="M113.098 181.409l-28.465 32.139c-11.114 12.548-9.952 31.727 2.597 42.839l17.448 15.454c12.547 11.112 31.727 9.95 42.84-2.596l28.465-32.139a2.6 2.6 0 0 0-.223-3.672l-58.991-52.247a2.6 2.6 0 0 0-3.672.223zm2.17 5.396l55.095 48.797-26.74 30.192c-9.208 10.395-25.101 11.359-35.497 2.151l-17.449-15.454c-10.397-9.207-11.361-25.098-2.151-35.495l26.741-30.192z"
                fill="#333"
                fillRule="nonzero"
            />
            <path
                d="M91.073 276.395l-6.721-5.953c-4.405-3.902-4.814-10.633-.912-15.038l2.903-3.277 22.672 20.078-2.903 3.277c-3.902 4.404-10.634 4.814-15.038.912"
                fill="#d5e6a5"
            />
            <path
                d="M84.47 250.469l-2.903 3.277c-4.817 5.438-4.313 13.749 1.126 18.567l6.722 5.953c5.438 4.817 13.75 4.312 18.567-1.126l2.903-3.277a2.5 2.5 0 0 0-.214-3.529l-22.672-20.078a2.5 2.5 0 0 0-3.529.214zm2.086 5.187l18.927 16.763-1.244 1.406c-2.987 3.371-8.138 3.684-11.509.698l-6.722-5.953c-3.371-2.986-3.684-8.138-.698-11.508l1.246-1.406z"
                fill="#333"
                fillRule="nonzero"
            />
            <use xlinkHref="#B" fill="#d5e6a5" />
            <path
                d="M156.916 148.792l-24.973 28.196a2.5 2.5 0 0 0 .214 3.529l11.998 10.626a2.5 2.5 0 0 0 3.529-.214l24.973-28.194c3.85-4.348 3.447-10.993-.901-14.842l-.273-.234c-4.359-3.606-10.807-3.112-14.567 1.133zm11.557 2.872l.178.165c2.08 2.014 2.228 5.373.263 7.592l-23.315 26.322-8.255-7.311 23.315-26.324c1.972-2.225 5.354-2.484 7.637-.595l.177.152z"
                fill="#333"
                fillRule="nonzero"
            />
            <use xlinkHref="#B" x="30.413" y="26.936" fill="#d5e6a5" />
            <path
                d="M187.329 175.727l-24.973 28.196a2.5 2.5 0 0 0 .214 3.529l11.998 10.626a2.5 2.5 0 0 0 3.529-.214l24.973-28.194c3.85-4.348 3.447-10.993-.901-14.842l-.273-.234c-4.359-3.606-10.807-3.112-14.567 1.133zm11.38 2.72l.177.152.178.165c2.08 2.014 2.228 5.373.263 7.592l-23.316 26.322-8.255-7.312 23.315-26.324c1.972-2.225 5.354-2.484 7.637-.595z"
                fill="#333"
                fillRule="nonzero"
            />
            <path d="M98.854 222.246l21.033 18.629" fill="#c3f1d6" />
            <use xlinkHref="#C" fill="#333" fillRule="nonzero" />
            <path d="M130.404 250.188l6.761 5.988" fill="#c3f1d6" />
            <use xlinkHref="#D" fill="#333" fillRule="nonzero" />
            <path d="M91.006 231.107l21.033 18.629" fill="#c3f1d6" />
            <use xlinkHref="#C" x="-7.848" y="8.861" fill="#333" fillRule="nonzero" />
            <path d="M122.556 259.049l6.761 5.988" fill="#c3f1d6" />
            <use xlinkHref="#D" x="-7.848" y="8.861" fill="#333" fillRule="nonzero" />
            <path
                d="M198.596 98.63l56.284 70.409a7.9 7.9 0 0 1-1.238 11.105h0a7.9 7.9 0 0 1-11.105-1.238l-56.286-70.407c-2.724-3.409-2.171-8.382 1.238-11.107h0c3.409-2.726 8.383-2.171 11.107 1.238"
                fill="#d5e6a5"
            />
            <path
                d="M185.927 95.44l-.241.199c-4.285 3.667-4.892 10.036-1.388 14.421l56.286 70.408a10.4 10.4 0 0 0 14.619 1.63l.241-.199c4.285-3.668 4.893-10.038 1.388-14.42L200.549 97.07c-3.587-4.488-10.134-5.218-14.621-1.63zm10.715 4.751l56.284 70.409a5.4 5.4 0 0 1-.702 7.471l-.177.146c-2.298 1.837-5.697 1.458-7.559-.872l-56.286-70.408c-1.819-2.277-1.504-5.585.702-7.473l.177-.146c2.298-1.837 5.698-1.458 7.561.872z"
                fill="#333"
                fillRule="nonzero"
            />
            <path
                d="M271.134 76.176l21.628 27.055c9.567 11.968 7.621 29.428-4.349 38.997l-33.536 26.807L198.6 98.636l33.536-26.807c11.971-9.568 29.43-7.623 38.998 4.347"
                fill="#d5e6a5"
            />
            <path
                d="M230.575 69.876l-33.536 26.807a2.5 2.5 0 0 0-.392 3.514l56.277 70.399a2.5 2.5 0 0 0 3.514.392l33.536-26.807c13.049-10.43 15.171-29.463 4.741-42.511l-21.628-27.055c-10.431-13.048-29.463-15.17-42.512-4.739zm60.235 34.916l.258.328c8.411 10.879 6.567 26.536-4.215 35.155l-31.583 25.245-53.156-66.493 31.584-25.245c10.892-8.707 26.778-6.936 35.485 3.955l21.628 27.055z"
                fill="#333"
                fillRule="nonzero"
            />
            <path
                d="M290.885 76.811l5.606 7.014a10.65 10.65 0 0 1-1.67 14.972l-3.42 2.735-18.91-23.656 3.421-2.735c4.595-3.673 11.299-2.925 14.972 1.67"
                fill="#d5e6a5"
            />
            <path
                d="M274.352 73.188l-3.421 2.735a2.5 2.5 0 0 0-.392 3.514l18.91 23.656a2.5 2.5 0 0 0 3.514.391l3.42-2.735c5.675-4.534 6.598-12.812 2.061-18.486l-5.606-7.014c-4.535-5.674-12.812-6.597-18.486-2.062zm20.187 12.198l.168.217a8.15 8.15 0 0 1-1.446 11.24l-1.468 1.173-15.788-19.75 1.469-1.173c3.517-2.811 8.648-2.239 11.459 1.278l5.606 7.014z"
                fill="#333"
                fillRule="nonzero"
            />
            <path d="M276.268 131.709l-20.065-25.1" fill="#c3f1d6" />
            <use xlinkHref="#E" fill="#333" fillRule="nonzero" />
            <path d="M246.171 94.059l-6.451-8.069" fill="#c3f1d6" />
            <use xlinkHref="#F" fill="#333" fillRule="nonzero" />
            <path d="M285.515 124.318l-20.065-25.1" fill="#c3f1d6" />
            <use xlinkHref="#E" x="9.247" y="-7.391" fill="#333" fillRule="nonzero" />
            <path d="M255.417 86.668l-6.451-8.069" fill="#c3f1d6" />
            <g fill="#333" fillRule="nonzero">
                <use xlinkHref="#F" x="9.246" y="-7.391" />
                <use xlinkHref="#G" />
                <path d="M123.592 144.761a2.5 2.5 0 0 1 .164 4.995l-.164.005h-7.297a2.5 2.5 0 0 1-.164-4.995l.164-.005h7.297zm37.626-44.922a2.5 2.5 0 0 1 2.495 2.336l.005.164v7.297a2.5 2.5 0 0 1-4.995.164l-.005-.164v-7.297a2.5 2.5 0 0 1 2.5-2.5z" />
                <use xlinkHref="#G" x="93.392" y="93.388" />
                <path d="M258.258 196.874a2.5 2.5 0 0 1 .164 4.995l-.164.005h-7.297a2.5 2.5 0 0 1-.164-4.995l.164-.005h7.297zM213.334 234.5a2.5 2.5 0 0 1 2.495 2.336l.005.164v7.297a2.5 2.5 0 0 1-4.995.164l-.005-.164V237a2.5 2.5 0 0 1 2.5-2.5zM88.916 271.148a2.5 2.5 0 0 1 .079 3.535l-1.516 1.578-1.507 1.553-1.498 1.528-1.489 1.504-1.48 1.479-.737.73-1.466 1.442-1.457 1.418-1.448 1.393-.721.688-1.434 1.357-1.425 1.333-1.416 1.309-1.407 1.285-1.398 1.262-1.389 1.238-.691.61-1.376 1.203-.684.593-1.362 1.168-1.353 1.145-1.344 1.121-1.335 1.099-1.326 1.076-1.317 1.053-1.308 1.03-1.299 1.008-1.29.985-1.281.963-3.789 2.755-1.245.874-.619.429-1.232.842-1.223.82-.608.402-1.209.788-1.2.766-1.191.745-2.939 1.77-1.16.671-1.151.651-3.399 1.828-1.115.568-.554.277c-6.257 3.099-11.75 5.047-16.482 6.053-3.255.692-5.759.866-7.51.734l-.28-.025-.204-.026a2.5 2.5 0 0 1 .581-4.963l.278.028c1.147.086 2.904-.019 5.28-.474l.815-.165c4.313-.917 9.411-2.725 15.303-5.643l.536-.267 1.079-.55 1.088-.57.548-.293 1.102-.601a125.2 125.2 0 0 0 3.361-1.925l1.139-.683 1.148-.704 1.157-.725.582-.37 1.171-.757 1.18-.778.594-.397 1.194-.81 1.204-.832 1.213-.853 1.222-.875 1.231-.897 1.241-.919 1.25-.941 1.259-.963.633-.49 1.273-.997 1.282-1.019.645-.518 1.296-1.053.652-.535 1.31-1.087 1.319-1.11.663-.564 1.333-1.144 1.343-1.168 1.352-1.191.679-.604 1.366-1.226.686-.622 1.38-1.261 1.389-1.285 1.398-1.309.703-.663 1.412-1.345 1.422-1.369 1.431-1.393 1.44-1.417 1.45-1.441 1.459-1.466 1.468-1.49 1.477-1.515.742-.767 1.491-1.552a2.5 2.5 0 0 1 3.535-.079zM340.731 48.601c19.902-7.84 40.865-10.523 64.101-7.409a2.5 2.5 0 1 1-.664 4.956c-22.381-3-42.485-.427-61.604 7.105-15.761 6.209-30.245 15.374-47.314 29.017a2.5 2.5 0 1 1-3.122-3.906l1.857-1.475c16.632-13.122 31.028-22.097 46.746-28.289z" />
                <path d="M198.596 5.168a2.5 2.5 0 0 1 2.749-2.224c5.282.557 10.513 1.363 15.683 2.414a2.5 2.5 0 1 1-.996 4.9 161.51 161.51 0 0 0-15.212-2.341 2.5 2.5 0 0 1-2.224-2.749zM182.485 2a2.5 2.5 0 0 1 .035 5C93.323 7.623 21.522 80.434 22.145 169.627c.212 30.167 8.696 59.051 24.292 84.103 29.509 47.397 81.475 76.663 138.344 76.265 71.751-.502 133.969-48.147 153.867-115.952a2.5 2.5 0 0 1 4.798 1.408c-20.515 69.909-84.658 119.027-158.63 119.544-58.627.41-112.205-29.764-142.624-78.623-16.08-25.829-24.829-55.614-25.047-86.711C16.502 77.707 90.527 2.643 182.485 2zm1.207 28.947c21.837 0 42.951 5.095 62.017 14.738a2.5 2.5 0 0 1-2.257 4.462c-18.369-9.291-38.712-14.2-59.76-14.2-19.122 0-37.669 4.05-54.704 11.775a2.5 2.5 0 1 1-2.065-4.554c17.68-8.018 36.931-12.221 56.769-12.221zM113.7 50.055a2.5 2.5 0 1 1 2.548 4.302c-5.203 3.081-10.187 6.513-14.919 10.268a2.5 2.5 0 1 1-3.109-3.916c4.909-3.897 10.082-7.458 15.48-10.654zm86.952 249.967a2.5 2.5 0 1 1 .632 4.96c-5.806.74-11.678 1.114-17.593 1.114a138.95 138.95 0 0 1-12.49-.56 2.5 2.5 0 1 1 .449-4.98 134.14 134.14 0 0 0 12.041.54c5.703 0 11.364-.36 16.961-1.074zm118.119-134a2.5 2.5 0 0 1 2.5 2.5c0 24.502-6.416 48.074-18.425 68.819-17.785 30.725-46.882 53.593-81.092 63.422a2.5 2.5 0 1 1-1.381-4.806c32.962-9.47 61.005-31.51 78.145-61.121 11.571-19.989 17.752-42.699 17.752-66.315a2.5 2.5 0 0 1 2.5-2.5z" />
            </g>
            <path
                d="M346.571 187.865c-.598 5.091-1.429 10.111-2.483 15.048"
                stroke="#333"
                strokeWidth="5.202"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M328.051 133.1l2.962-11.856c.349-1.413-.513-2.842-1.927-3.191a2.64 2.64 0 0 0-.629-.076h-4.179l11.15-11.152-1.68 13.46c-.18 1.443.845 2.761 2.287 2.941a2.97 2.97 0 0 0 .326.019h1.545l-9.855 9.855zm16.216-15.123h-4.92l2.263-18.117c.182-1.444-.843-2.761-2.286-2.943a2.64 2.64 0 0 0-2.194.753l-21.078 21.079a2.63 2.63 0 0 0 0 3.725c.494.496 1.165.773 1.864.772h7.165l-4.451 17.803c-.352 1.412.505 2.842 1.918 3.196.897.224 1.847-.038 2.501-.692l21.08-21.079c1.028-1.028 1.028-2.696-.002-3.726-.492-.492-1.164-.77-1.861-.77h0zM59.991 171.219l2.962-11.856c.349-1.413-.513-2.842-1.927-3.191a2.73 2.73 0 0 0-.629-.078h-4.179l11.15-11.15-1.68 13.46c-.18 1.443.845 2.761 2.287 2.941a2.97 2.97 0 0 0 .326.019h1.545l-9.855 9.855zm16.216-15.124h-4.92l2.263-18.116c.182-1.444-.843-2.761-2.286-2.943a2.64 2.64 0 0 0-2.194.753l-21.078 21.079a2.63 2.63 0 0 0 0 3.725c.494.496 1.165.773 1.864.772h7.165l-4.451 17.803c-.352 1.412.505 2.842 1.918 3.196.897.224 1.847-.038 2.501-.692l21.08-21.079c1.028-1.028 1.028-2.696-.002-3.726-.492-.492-1.164-.77-1.861-.772h0z"
                fill="#333"
            />
        </g>
        <defs>
            <path
                id="B"
                d="M145.813 189.271l-11.998-10.626 24.973-28.196c2.934-3.312 7.999-3.619 11.312-.685h0c3.314 2.934 3.621 7.999.687 11.313l-24.973 28.194z"
            />
            <path
                id="C"
                d="M96.983 220.588a2.5 2.5 0 0 1 3.391-.328l.138.114 21.033 18.629a2.5 2.5 0 0 1-3.177 3.857l-.138-.114-21.033-18.629a2.5 2.5 0 0 1-.214-3.529z"
            />
            <path
                id="D"
                d="M128.532 248.531a2.5 2.5 0 0 1 3.391-.328l.138.114 6.761 5.988a2.5 2.5 0 0 1-3.177 3.857l-.138-.114-6.761-5.988a2.5 2.5 0 0 1-.214-3.529z"
            />
            <path
                id="E"
                d="M254.642 104.656a2.5 2.5 0 0 1 3.397.256l.116.136 20.065 25.1a2.5 2.5 0 0 1-3.789 3.258l-.116-.136-20.065-25.1a2.5 2.5 0 0 1 .392-3.514z"
            />
            <path
                id="F"
                d="M238.159 84.037a2.5 2.5 0 0 1 3.397.256l.116.136 6.451 8.069a2.5 2.5 0 0 1-3.789 3.258l-.116-.136-6.451-8.069a2.5 2.5 0 0 1 .391-3.514z"
            />
            <path
                id="G"
                d="M136.233 122.276a2.5 2.5 0 0 1 3.405-.122l.131.122 5.159 5.159a2.5 2.5 0 0 1-3.405 3.657l-.131-.122-5.159-5.159a2.5 2.5 0 0 1 0-3.536z"
            />
        </defs>
    </Icon>
);
