import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconAustria: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g transform="translate(2 3)">
            <path d="m0 17.003c0 0.551 0.406 0.997 0.906 0.997h18.188c0.5 0 0.906-0.446 0.906-0.997v-5.003h-20v5.003z" fill="red" />
            <path d="m19.094 0h-18.187c-0.5 0-0.907 0.446-0.907 0.997v5.003h20v-5.003c0-0.551-0.406-0.997-0.906-0.997" fill="red" />
            <polygon points="0 12 20 12 20 6 0 6" fill="#FFF" />
        </g>
    </Icon>
);
