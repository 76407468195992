import { forwardRef, Icon } from "@chakra-ui/react";
import { TIconComponent, IIconProps } from "./icon-component.types";

export const IconInfo: TIconComponent = forwardRef<IIconProps, typeof Icon>((props, ref) => (
    <Icon viewBox="0 0 24 24" ref={ref} {...props}>
        <g transform="translate(1.5 1.5)">
            <path
                d="m21 10.5c0 5.7986-4.7011 10.5-10.499 10.5-5.7997 0-10.501-4.7014-10.501-10.5 0-5.7986 4.7011-10.5 10.501-10.5 5.7982 0 10.499 4.7014 10.499 10.5"
                fill="inherit"
            />
            <rect x="9" y="9" width="3" height="7.5" rx="1.5" fill="currentColor" />
            <path
                d="m9 6.0005c0-0.82922 0.67191-1.5005 1.4995-1.5005 0.82862 0 1.5005 0.67132 1.5005 1.5005 0 0.82814-0.67191 1.4995-1.5005 1.4995-0.82755 0-1.4995-0.67132-1.4995-1.4995"
                fill="currentColor"
            />
        </g>
    </Icon>
));
