import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconSearch: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g transform="translate(2 2)" fill="currentColor" fillRule="nonzero">
            <path d="m8.2 0c-4.5285 0-8.2 3.6715-8.2 8.2 0 4.5285 3.6715 8.2 8.2 8.2 4.5285 0 8.2-3.6715 8.2-8.2 0-4.5285-3.6715-8.2-8.2-8.2zm0 2c3.4239 0 6.2 2.7761 6.2 6.2 0 3.4239-2.7761 6.2-6.2 6.2-3.4239 0-6.2-2.7761-6.2-6.2 0-3.4239 2.7761-6.2 6.2-6.2z" />
            <path d="m12.893 12.893c0.36048-0.36048 0.92772-0.38821 1.32-0.083189l0.094207 0.083189 5.4 5.4c0.39052 0.39052 0.39052 1.0237 0 1.4142-0.36048 0.36048-0.92772 0.38821-1.32 0.083189l-0.094207-0.083189-5.4-5.4c-0.39052-0.39052-0.39052-1.0237 0-1.4142z" />
        </g>
    </Icon>
);
