import { useState } from "react";
import { getCombinedResults } from "../utils";
import { ISearchResultsMutationVariables, useSearchResults } from "./use-search-results";

export const useQueryWithSearchResults = (mutationVariables?: ISearchResultsMutationVariables) => {
    const [query, setQuery] = useState("");

    const { results, flushResults } = useSearchResults({ queryValue: query, mutationVariables });

    const combinedResults = getCombinedResults(results);

    const onClear = () => {
        flushResults();
        setQuery("");
    };

    return {
        query,
        setQuery,
        onClear,
        results,
        combinedResults,
    };
};
