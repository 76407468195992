import { UseMutationOptions } from "react-query";
import { API_REGISTER_URL } from "src/consts/urls";
import { useMutation } from "../../hooks/react-query/use-mutation";

interface IRequest {
    email: string;
    password: string;
    agbs: boolean;
    newsletter: boolean;
    sendPromotion: boolean;
    promotionId?: string;
    welcomePageNodeId: number | null;
    platform: string;
}

interface IError {
    [fieldName: string]: string;
}

interface IRegisterResponse {
    returnUrl: string;
}

export const useRegisterMutation = (options: UseMutationOptions<IRegisterResponse, IError, IRequest>) => {
    const { mutate: registerUser } = useMutation<IRegisterResponse, IError, IRequest>(API_REGISTER_URL, options);
    return registerUser;
};
