import { UseQueryOptions } from "react-query";
import { API_DISCLAIMER_URL } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";
import { IDisclaimerOptions } from "src/stores/disclaimer-store";
import { ILabelValue } from "src/types/common";
import { EnumCountryKey } from "src/types/common-enum";

export interface IDisclaimerResponse {
    countries: ILabelValue[];
    headline: string;
    content: string;
    cancelText: string;
    acceptText: string;
    showCancelButton: boolean;
    showCountrySelect: boolean;
    countryName: string;
    countryKey: EnumCountryKey;
    countrySuggestion: {
        buttonText: string;
        countryKey: string;
        showSuggestion: boolean;
    };
}

export function useDisclaimerQuery(isModalOpen: boolean, options: IDisclaimerOptions, queryOptions: UseQueryOptions<IDisclaimerResponse>) {
    return useQuery<IDisclaimerResponse>(API_DISCLAIMER_URL, {
        queryParams: new URLSearchParams({
            selectedCountry: options?.country,
            directHit: options?.direct?.toString(),
        }),
        ...queryOptions,
        enabled: isModalOpen,
        // Dev-Note: once the disclaimer is opened, we don't want to refetch it again and therefore store it in the cache forever
        cacheTime: Infinity,
        staleTime: Infinity,
    });
}
