import { useEffect } from "react";
import { useConsentChangedMutation as useConsentUpdatedMutation } from "src/api/client/set-visitor-cookie.api";
import { useCookieBotConsentUpdated } from "src/hooks/cookie-bot/use-cookie-bot-consent-updated";

export function useConsentUpdated() {
    const { isUpdated, resetIsUpdated } = useCookieBotConsentUpdated();
    const consentUpdated = useConsentUpdatedMutation();

    useEffect(() => {
        if (isUpdated) {
            consentUpdated();
            resetIsUpdated();
        }
    }, [isUpdated, resetIsUpdated, consentUpdated]);
}
