import { useTranslation } from "next-i18next";
import { IUser } from "src/types/common";
import { getFullNameOrNickName } from "src/utils/user-name-util";

export const useGetUserName = () => {
    const { t } = useTranslation("common");

    return (user: Pick<IUser, "firstName" | "lastName" | "nickName" | "isDeleted">) =>
        user.isDeleted ? t("user-deleted") : getFullNameOrNickName(user);
};
