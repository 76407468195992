import { proxy, useSnapshot } from "valtio";
import { IUser } from "src/types/common";

const userStore = proxy({
    user: null as IUser | null,

    get isLoggedIn() {
        return Boolean(this.user);
    },
    setUser(user: IUser | null) {
        userStore.user = user;
    },

    isCurrentUser(user: IUser) {
        return user.id === userStore.user?.id;
    },
});

export const setUser = (user: IUser | null) => {
    userStore.setUser(user);
};

export function useUserStore() {
    return useSnapshot(userStore);
}
