import { ReactElement, ReactNode } from "react";

const defaultHighlight = (stringToMark: string, key: string) => <b key={key}>{stringToMark}</b>;

// Needed if the target includes ambiguous characters that are valid regex operators.
const escapeRegex = (target: string) => target.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");

export interface IHighlight {
    /** Given text to highlight */
    source: string;

    /** Text to find in source to highlight */
    target?: string;
}

/**
 * React component that transform a string in a JSX component,
 * wrapping text in source with bold by given target.
 */
export const Highlight = ({ source, target }: IHighlight): ReactElement | null => {
    const result: Array<ReactNode> = [];

    if (!source) {
        return null;
    }

    if (!target) {
        return <> {source} </>;
    }

    const regex = new RegExp(escapeRegex(target.trim()), "gi");

    let lastOffset = 0;

    source.replace(regex, (val: string, offset: number): string => {
        // Push both the last part of the string, and the new part with the highlight
        result.push(
            source.substr(lastOffset, offset - lastOffset),
            // Replace the string with JSX or anything.
            defaultHighlight(val, String(offset))
        );
        lastOffset = offset + val.length;
        return "";
    });

    // Push the last non-highlighted string
    result.push(source.substr(lastOffset));
    return <> {result} </>;
};
