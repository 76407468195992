import { useCallback } from "react";
import TagManager from "react-gtm-module";
import {
    // eslint-disable-next-line no-restricted-imports
    useMutation as _useMutation,
    UseMutationOptions,
} from "react-query";
import { buildQueryUrl } from "src/utils/query-util";
import { useRouterLocale } from "src/utils/router/use-router-locale";

export interface IMutationOptions<Data, Error, Variables> extends Omit<UseMutationOptions<Data, Error, Variables>, "mutationKey" | "mutationFn"> {
    method?: "POST" | "PUT" | "DELETE" | "PATCH";
    queryParams?: URLSearchParams;
    accept?: "text/csv";
}

/**
 * @param _url this should be the path of the API Endpoint *without* any query params
 * @param options additional react-query mutation options and the possibility to pass query params as URLSearchParams
 */
export const useMutation = <Data = unknown, Error = unknown, Variables = unknown>(
    _url: string,
    options?: IMutationOptions<Data, Error, Variables>
) => {
    const { language, country } = useRouterLocale();

    const url = buildQueryUrl({ language, country }, _url, options?.queryParams);

    const result = _useMutation<Data, Error, Variables>({
        mutationKey: url,
        mutationFn: async variables => {
            const method = options?.method || "POST";

            const response = await fetch(url, {
                method,
                headers: {
                    Accept: options?.accept || "application/json",
                    "Content-Type": "application/json",
                },
                body: variables ? JSON.stringify(variables) : undefined,
            });

            if (options?.accept === "text/csv") {
                if (!response.ok) {
                    const responseValue = await response.json();
                    throw responseValue;
                }
                const filename = getFilenameFromHeaders(response.headers);
                const blob = await response.blob();
                downloadFile(blob, filename);
                return;
            } else {
                const contentLength = Number(response.headers.get("Content-Length"));
                if (contentLength > 0) {
                    const responseValue = await response.json();
                    if ((responseValue?.hasOwnProperty("isSuccess") && !responseValue.isSuccess) || !response.ok) {
                        throw responseValue;
                    }

                    return responseValue;
                }
            }

            if (!response.ok) {
                throw new Error();
            }
        },
        ...options,
    });

    const { mutate } = result;
    const mutatePromise = useCallback(
        async (variables: Variables, options?: IMutationOptions<Data, Error, Variables>) => {
            return new Promise<Data>((resolve, reject) => {
                mutate(variables, {
                    ...options,
                    onSuccess: data => {
                        resolve(data);
                    },
                    onError: error => {
                        reject(error);
                    },
                });
            });
        },
        [mutate]
    );

    return { ...result, mutatePromise };
};

const getFilenameFromHeaders = (headers: Headers) => {
    const contentDisposition = headers.get("Content-Disposition");
    const filenameMatch = contentDisposition?.match(/filename=["']?([^'"\s]+(?:\s[^'"\s]+)*)["']?/);
    if (filenameMatch && filenameMatch.length > 1) {
        const tagManagerArgs = {
            dataLayer: {
                event: "download_event",
                fileName: filenameMatch[1],
            },
        };
        TagManager.dataLayer(tagManagerArgs);
        return filenameMatch[1];
    }
    throw new Error();
};

const downloadFile = (data: Blob, filename: string) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
};
