import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfLink } from "src/components/base/wf-link";
import { ErrorCommon } from "src/components/pages/error/common";

const serviceEmail = "service@wikifolio.com";

interface IError500Props {
    errorCode?: string;
    showLogo?: boolean;
    staticTranslations?: (key: string) => string;
}

export const Error500 = ({ errorCode, showLogo, staticTranslations }: IError500Props) => {
    const { t } = useTranslation("common");

    const activeT = staticTranslations ?? t;

    return (
        <ErrorCommon subHeadline={activeT("sub-headline-500")} showLogo={showLogo} staticTranslations={staticTranslations}>
            {errorCode && (
                <Text mt={[2, 2, 3, 5]} textAlign={["center", "center", "unset"]}>
                    <strong>{activeT("error-code")}: </strong>
                    <Box display="inline-block">{errorCode}</Box>
                </Text>
            )}

            <Text mt={2} fontSize={["lg", "xl"]} textAlign={["center", "center", "unset"]}>
                {activeT("500-error-heading")}
                <br />
                {activeT("500-error-message")}
            </Text>

            <Text mt={2} fontSize={["lg", "xl"]} textAlign={["center", "center", "unset"]}>
                {activeT("immediate-help")} <WfLink href={`mailto:${serviceEmail}`}>{serviceEmail}</WfLink>.
            </Text>
        </ErrorCommon>
    );
};
